import React, { useEffect, useState } from "react";
import {
  getCurrentServer,
  logOutClearDB,
  setIsLoggedIn,
} from "../localDatabase/mainDB";
import { GetHomeScreenData, StatementBydate } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../functions";
import useNotifications from "../hooks/useNotifications";

import langText from "../lang/i18n";
import Sidebar from "../components/Sidebar";
import "./HomeScreen.css";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import {
  AccountProps,
  setAccounts,
  setChosenAcc,
  setUserData,
  setUserToken,
} from "../redux/user-slice";
import { logActivity } from "../hooks/functions";
import BookingDataTable from "../components/BookingDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { FaBell, FaRegBell } from "react-icons/fa";

const HomeScreen = () => {
  const { UserToken, UserData, chosenAcc, allAccounts } = useAppSelector(
    (state) => state.user
  );
  const { SelectedColor } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const server = getCurrentServer();
  const { hasPermissions, askForPermissions, revokePermissions } =
    useNotifications();
  const [bookings, setBookings] = useState<any[]>([]);
  const [shownBookings, setShownBookings] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(20);
  const [showAllAccounts, setShowAllAccounts] = useState(false);

  const loadMore = () => {
    logActivity("loadMore", records, bookings.length);
    setHasMore(false);
    if (records < bookings.length && hasMore) {
      setTimeout(() => {
        setHasMore(true);
      }, 10);
      setRecords(records + 20);
    }
  };

  useEffect(() => {
    logActivity(records);
    setShownBookings(bookings.slice(0, records));
  }, [records]);

  useEffect(() => {
    if (!bookings) return;
    setRecords(20);
    setHasMore(true);
    setShownBookings(bookings.slice(0, 20));
  }, [bookings]);

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
    getData();
  }, []);

  const getData = async () => {
    const response = await fetch(GetHomeScreenData(UserData!.id.toString()), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
        "Web-Client": "Q29taW5nRnJvbVdlYg==",
      },
    });
    logActivity("GetHomeScreenData", response.status);

    if (response.status == 401) {
      logOutClearDB();
      setIsLoggedIn(false);
      navigate("/login");
      return;
    }

    if (!response.ok) return;
    const data = await response.json();
    if (!data || !data.accounts) return;
    logActivity(data);
    const order: Record<string, number> = {
      CHECKING_PERSONAL: 1,
      CHECKING_BUSINESS: 2,
      SAVINGS_PERSONAL: 3,
    };
    data.accounts.sort((a: any, b: any) => {
      return order[a.accountType] - order[b.accountType];
    });

    const filteredOrder: Record<string, number> = {
      ALL: 1,
      EUR: 2,
    };

    data.accounts.sort((a: any, b: any) => {
      return filteredOrder[a.currency] - filteredOrder[b.currency];
    });

    dispatch(setAccounts(data.accounts));

    const foundAccount = data.accounts.find(
      (acc: AccountProps) => acc.id === chosenAcc?.id
    );
    logActivity("foundAccount", foundAccount);
    dispatch(setChosenAcc(foundAccount ?? data.accounts[0]));
  };

  // const getBookings = async (accountId: number) => {
  //   logActivity(
  //     "getting bookings for account",
  //     accountId,
  //     GetBookingsByAccounts()
  //   );
  //   const response = await fetch(GetBookingsByAccounts(), {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + UserToken,
  //     },
  //     body: JSON.stringify({
  //       account: accountId,
  //     }),
  //   });
  //   logActivity("GetBookingsByAccounts", response.status);
  //   if (!response.ok) return;
  //   const bookingsList = await response.json();
  //   logActivity("GetBookingsByAccounts", bookingsList);
  //   setBookings(bookingsList);
  // };
  //
  // const renderTransactionRow = (booking: Booking) => {
  //   let firstname =
  //     booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
  //       ? booking.senderName.includes(" ")
  //         ? booking.senderName?.substr(0, booking.senderName?.indexOf(" "))
  //         : booking.senderName
  //       : booking.recipientName.includes(" ")
  //       ? booking.recipientName?.substr(0, booking.recipientName?.indexOf(" "))
  //       : booking.recipientName;
  //   let lastname =
  //     booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
  //       ? booking.senderName.includes(" ")
  //         ? booking.senderName?.split(" ").pop()
  //         : ""
  //       : booking.recipientName.includes(" ")
  //       ? booking.recipientName?.split(" ").pop()
  //       : "";
  //
  //   let initials =
  //     firstname[0].toUpperCase() + (lastname ? lastname[0].toUpperCase() : "");
  //
  //   const personId =
  //     booking.senderCustomer?.id !== chosenAcc?.accountHolder.id
  //       ? booking.senderCustomer?.id
  //       : booking.recipientCustomer?.id;
  //
  //   return (
  //     <div
  //       key={booking.id}
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         borderBottom: "1px solid #EDEDED",
  //         paddingBottom: "10px",
  //         marginBottom: "10px",
  //       }}
  //       onClick={() =>
  //         navigate("/transaction-details", {
  //           state: {
  //             booking: booking,
  //           },
  //         })
  //       }
  //     >
  //       <div
  //         style={{
  //           width: 50,
  //           height: 50,
  //           borderRadius: 25,
  //           marginRight: 20,
  //           textAlign: "center",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           backgroundColor: "#546BEA",
  //         }}
  //       >
  //         {booking.avatar ? (
  //           <img
  //             src={booking.avatar}
  //             alt=""
  //             style={{ width: "100%", height: "100%", borderRadius: 25 }}
  //           />
  //         ) : (
  //           <div style={{ fontSize: "1.5em", color: "#FFF" }}>{initials}</div>
  //         )}
  //       </div>
  //       <div style={{ display: "flex", flexDirection: "column" }}>
  //         <span
  //           style={{
  //             fontFamily: "Poppins",
  //             fontStyle: "normal",
  //             fontWeight: 100,
  //           }}
  //         >
  //           {firstname + " " + lastname}
  //         </span>
  //         <span style={{ color: "#9CAEB8", fontSize: "16px", fontWeight: 400 }}>
  //           {format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy")}
  //         </span>
  //       </div>
  //       <div
  //         style={{
  //           marginLeft: "auto",
  //           fontWeight: "bold",
  //           color: (
  //             typeof booking.creditDebitIndicator === "string"
  //               ? booking.creditDebitIndicator === "CREDIT"
  //               : booking.creditDebitIndicator.name === "CREDIT"
  //           )
  //             ? "green"
  //             : "gray",
  //         }}
  //       >
  //         {((
  //           typeof booking.creditDebitIndicator === "string"
  //             ? booking.creditDebitIndicator === "CREDIT"
  //             : booking.creditDebitIndicator.name === "CREDIT"
  //         )
  //           ? "+"
  //           : "-") +
  //           formatMoney(booking.amount.value) +
  //           " " +
  //           (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro")}
  //       </div>
  //     </div>
  //   );
  // };

  async function downloadStatement(account: AccountProps) {
    const reqBody = {
      from: "2022-01-01",
      to: moment().add(1, "day").format("YYYY-MM-DD"),
      account: account.id,
    };

    logActivity("sending data", reqBody);

    const response = await fetch(StatementBydate(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify(reqBody),
    });
    logActivity("StatementBydate", response);
    if (!response.ok) return;
    const blob = await response.blob();
    download(blob);
  }

  function download(blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
  return (
    <div>
      <Sidebar />
      <div style={{ marginLeft: "7rem", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              margin: "0px 20px",
              paddingTop: "20px",
            }}
          >
            <span className="welcome-text">
              Mirë se erdhe,
              <span style={{ fontWeight: 200 }}>{" " + UserData!.name}</span>
            </span>
          </div>
          <div
            onClick={() => {
              if (!hasPermissions) {
                askForPermissions();
              } else {
                revokePermissions();
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                color: "white",
                fontSize: 18,
                padding: 5,
                border: "none",
              }}
            >
              {!hasPermissions && "Lejo Njoftimet"}
              {hasPermissions && "Hiq Njoftimet"}
            </div>
            <FaRegBell color="#ffffff" size={20}></FaRegBell>
          </div>

          <div
            style={{
              fontSize: 30,
              gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
              display: "grid",
              height: showAllAccounts ? "auto" : 200,
              overflow: "hidden",
            }}
          >
            {!!allAccounts &&
              allAccounts.map((account) => {
                return (
                  <div
                    key={account.id}
                    className={`account-card`}
                    style={{
                      opacity: account.id !== chosenAcc?.id ? 0.3 : 1,
                    }}
                    onClick={() => {
                      setBookings([]);
                      dispatch(setChosenAcc(account));
                    }}
                  >
                    {/* {account.accountType === "CHECKING_BUSINESS" && (
                      <h2 className="account-card-balance-text">
                        {account.name ?? account.accountHolder.name}
                      </h2>
                    )} */}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="account-card-balance-text">
                        {!!account.name
                          ? account.name
                          : account.accountType === "CHECKING_BUSINESS"
                          ? account.accountHolder.name
                          : "Balanca" +
                            " " +
                            (account.currency === "ALL" ? "Lekë" : "Euro")}
                      </h2>
                      <div
                        style={{
                          height: 40,
                          width: 40,
                          alignSelf: "flex-end",
                        }}
                        onClick={() => navigate("/extract")}
                      >
                        <FontAwesomeIcon
                          //@ts-ignore
                          icon={faFile}
                          color="white"
                        />
                      </div>
                    </div>
                    <h2 className="account-card-amount">
                      {formatMoney(account.balance.amount.value) +
                        " " +
                        (account.currency === "ALL" ? "Lekë" : "Euro")}
                    </h2>
                  </div>
                );
              })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                backgroundColor: "transparent",
                color: "white",
              }}
              onClick={() => setShowAllAccounts(!showAllAccounts)}
            >
              <p style={{ marginRight: 10 }}>Shiko të gjitha</p>
              {!showAllAccounts ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 30px",
            // backgroundColor: "red",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              borderRadius: "25px",
              border: "1px #cccccc",
              margin: "20px",
              marginTop: "-30px",
              backgroundColor: "#FFF",
              padding: "35px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <h2
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#44494B",
                }}
              >
                {langText("transactions")}
              </h2>
            </div>
            <BookingDataTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
