import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user-slice";
import themeReducer from "./theme-slice";
import cardReducer from "./card-slice";
import generalReducer from "./general-slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    card: cardReducer,
    general: generalReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
