import React, { useEffect, useState } from "react";
import Header from "../../components/Header";

import langText from "../../lang/i18n";
import { ServiceProps } from "../../constants/Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useAppSelector } from "../../hooks/reduxHooks";
import { logOutClearDB } from "../../localDatabase/mainDB";

export default function UtilityServiceScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { utility } = location.state;
  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  return (
    <div>
      <Sidebar />
      <div style={{ margin: 20 }}>
        <Header title={utility.short_name} />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        {(utility.services as ServiceProps[]).map(
          (service: ServiceProps, index) => (
            <div
              key={index}
              style={{
                width: "200px",
                height: "200px",
                margin: "10px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px 0 rgba(84, 107, 234, 0.2)",
                backgroundColor: "white",
                padding: "20px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(service.type == "topUp" ? "/top-up" : "/invoices", {
                  state: { utility: utility, service: service },
                });
              }}
            >
              {service.logo && (
                <img
                  src={service.logo}
                  alt={service.name}
                  style={{ height: "70%", marginBottom: 20 }}
                />
              )}
              <span>{langText(service.name)}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
}
