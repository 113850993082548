import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetTwoFactorAuthEndpoint,
  GetUserInfo,
  LoginUrl,
  TwoFactorAuthEndpoint,
} from "../constants/endpoints";
import { getIsLoggedIn } from "../localDatabase/mainDB";
import "./LoginScreen.css";
import logo from "../assets/Pago-Logo.png";
import { useAppDispatch } from "../hooks/reduxHooks";
import { setUserData, setUserToken } from "../redux/user-slice";
import { logActivity } from "../hooks/functions";
import langText from "../lang/i18n";
import { useModal } from "../components/modals/ModalService";
import use2FAuth from "../hooks/use2FAuth";
import { Base64 } from "js-base64";
import { Tooltip } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";

const service_username = "sms@rbcn.al";
const service_password = "11111111";

const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const [mobileNumber, setMobileNumber] = useState("+355");
  const [password, setPassword] = useState("");
  const [authorizationCode, setAuthorizationCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { showAuthModal } = use2FAuth();

  useEffect(() => {
    // check if the user is already logged in
    const isLoggedIn = getIsLoggedIn();
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMobileNumber(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleAuthorizationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuthorizationCode(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const authStatus = await checkAuthStatus();
    // if (authStatus.verified) {
    successAuth();
    // }
  };

  async function successAuth() {
    setLoading(true);
    let username = mobileNumber;
    if (username.startsWith("355")) {
      username = "+" + username;
    } else if (username.startsWith("06")) {
      username = "+355" + username.substring(1);
    }

    const headers = {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Content-Type": "application/json",
      "Web-Client": "Q29taW5nRnJvbVdlYg==",
    };
    logActivity("headers:", headers);

    const response = await fetch(LoginUrl(), {
      method: "POST",
      headers: headers,
      credentials: "same-origin",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    logActivity("Login status: " + response.status);
    setLoading(false);
    if (response.status !== 200) {
      setErrorMessage("Invalid credentials");
      return;
    }
    const data = await response.json();
    logActivity(data);
    const { access_token, id } = data;
    if (!!access_token) {
      dispatch(setUserToken(access_token));

      const userData = await fetch(GetUserInfo(id), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      }).then((response) => {
        return response.json();
      });

      dispatch(setUserData(userData));
      navigate("/");
    }
  }

  async function checkAuthStatus() {
    const body = JSON.stringify({
      token: authorizationCode,
      number: mobileNumber,
    });

    const response = await fetch(TwoFactorAuthEndpoint(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization:
          "Basic " + Base64.btoa(service_username + ":" + service_password),
        "Content-Type": "application/json",
      },
      body: body,
    });

    if (!response.ok) {
      setErrorMessage(langText("error"));
      return;
    }

    const resBody = await response.json();
    return resBody;
  }

  return (
    <div className="login-screen">
      <div className="form-container">
        {/* <h1>Login Screen</h1> */}
        <img
          alt="logo"
          src={logo}
          style={{
            maxWidth: "40%",
            height: "auto",
            marginBottom: "50px",
          }}
        />

        <form onSubmit={handleLogin}>
          <div>
            <label htmlFor="mobileNumber">{langText("phone_number")}:</label>
            <input
              type="tel"
              id="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </div>
          <div>
            <label htmlFor="password">{langText("password")}:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          {/* <div>
            <label htmlFor="authorizationToken">
              {langText("authorization_code")}
            </label>
            <Tooltip
              color="#546bea"
              placement="top"
              trigger={"click"}
              title={langText("login_auth_code_info")}
            >
              <QuestionCircleTwoTone
                twoToneColor="#546bea"
                style={{
                  marginLeft: "5px",
                }}
                rev={{}}
              />
            </Tooltip>

            <input
              type="text"
              maxLength={6}
              id="authorizationToken"
              value={authorizationCode}
              onChange={handleAuthorizationCodeChange}
            />
          </div> */}
          <p id="error-message">{errorMessage}</p>
          <button type="submit">{langText("lg")}</button>
          <div
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              padding: "1em",
            }}
          >
            <a
              onClick={() => {
                navigate("/forgot-password");
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {langText("forgot")}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
