import { useEffect, useState } from "react";
import { GetAccountAccessUrl, GetAccountsUrl } from "../../constants/endpoints";
import langText from "../../lang/i18n";
import { useAppSelector } from "../../hooks/reduxHooks";
import { FaTrash } from "react-icons/fa";

function AccessesAndRoles() {
  const { UserToken, UserData } = useAppSelector((state) => state.user);
  const ACCESS_TYPES = ["SELECT", "FULL_RIGHTS", "READ_RIGHTS", "REPORTS"];
  const [accountData, setAccountData] = useState<[]>();

  const [accessTypeInput, setAccessTypeInput] = useState<string>("");
  const [customerMobile, setCustomerMobile] = useState<string>("");

  const handleCustomerMobileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerMobile(event.target.value);
  };

  function getAccounts() {
    fetch(GetAccountsUrl(UserData?.id), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAccountData(data);
      });
  }

  useEffect(() => {
    getAccounts();
  }, []);

  const updateAccountAccess = (
    account: any,
    accessType: string,
    actionType: string,
    customerMobile: string
  ) => {
    fetch(GetAccountAccessUrl(UserData?.id, account.id.toString()), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        account: account.id,
        mobile: customerMobile, //'+355456789',
        accessRightType: accessType,
        action: actionType,
      }),
    })
      .then((response) => {
        if (response.ok) getAccounts();
        return response.json();
      })
      .then((data) => {})
      .catch((err: any) => {});
  };

  return (
    <div>
      {accountData &&
        accountData.map((account: any) => {
          return (
            <div key={account.id} className="account">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "1rem",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Account ID: {account.id}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Account Holder: {account.accountHolder.name}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                    }}
                  >
                    Currency: {account.currency}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      padding: "3px",
                    }}
                  >
                    {langText("add_access")}
                  </div>
                  <input
                    type="text"
                    value={customerMobile}
                    onChange={(e) => handleCustomerMobileChange(e)}
                    placeholder="+355** *** ****"
                    className="input"
                  />{" "}
                  <select
                    className="access-type"
                    onChange={(e) => {
                      setAccessTypeInput(e.target.value);
                    }}
                  >
                    {ACCESS_TYPES.map((accessType) => {
                      return (
                        <option key={accessType} value={accessType}>
                          {accessType}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    style={{
                      backgroundColor: "#546BEA",
                      border: "none",
                      color: "white",
                      borderRadius: 8,
                      padding: "5px 36px",
                      lineHeight: "24px",
                      width: "100%",
                      marginTop: "2em",
                    }}
                    onClick={() => {
                      updateAccountAccess(
                        account,
                        accessTypeInput,
                        "add",
                        customerMobile
                      );
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              {account.accountAccesss.length > 0 && (
                <div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 100,
                      borderBottom: "1px solid #EDEDED",
                      padding: "5px",
                    }}
                  >
                    {langText("clients_with_access")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "left",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Client</th>
                          <th>Access Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {account.accountAccesss.map((element: any) => {
                          return (
                            <tr id={element.id}>
                              <td>{element.customerMobile}</td>
                              <td>{element.accessRightType}</td>
                              <td>
                                {
                                  <FaTrash
                                    onClick={() => {
                                      updateAccountAccess(
                                        account,
                                        element.accessRightType,
                                        "remove",
                                        element.customerMobile
                                      );
                                    }}
                                  />
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default AccessesAndRoles;
