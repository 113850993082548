import { useEffect, useState } from 'react';
import { hexWithOpacity } from '../../hooks/functions';
import langText from '../../lang/i18n';
import useWebhook from '../../hooks/useWebhook';

function INPView() {
  const [webhookUrl, setWebhookUrl] = useState('');
  const [token, setToken] = useState('');
  const {
    deleteWebhook,
    saveWebhook,
    loading,
    saved,
    error,
  } = useWebhook();

  useEffect(() => {
    if (!saved) return;
    setWebhookUrl(saved.url);
  }, [saved]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          className="display-label"
          style={{ minWidth: 200 }}
        >
          {langText('URL')}
        </div>
        <input
          className="input"
          type="text"
          onChange={(e) => setWebhookUrl(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          className="display-label"
          style={{ minWidth: 200 }}
        >
          {langText('secure_token')}
        </div>
        <input
          className="input"
          type="text"
          onChange={(e) => setToken(e.target.value)}
        />
      </div>

      <div
        style={{
          alignSelf: 'center',
          marginBottom: 10,
          color: hexWithOpacity('#FF0000', 0.7),
        }}
      >
        {error}
      </div>
      <div
        onClick={
          !!saved
            ? () => deleteWebhook()
            : () => saveWebhook(webhookUrl, token)
        }
        style={{
          display: 'flex',
          minWidth: 200,
          flexDirection: 'column',
          backgroundColor: '#546BEA',
          color: '#FFF',
          borderRadius: 10,
          alignItems: 'center',
          padding: '20px 70px',
          alignSelf: 'center',
        }}
      >
        <div className="display-label">
          {langText(!!saved ? 'delete' : 'save')}
        </div>
      </div>
    </div>
  );
}

export default INPView;
