import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import langText from '../../lang/i18n';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

function SupportView() {
  return (
    <div>
      <div className="display-label">
        {langText('support_description')}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          margin: '20px 0',
        }}
      >
        <a
          style={{
            display: 'flex',
            minWidth: 200,
            flexDirection: 'column',
            backgroundColor: '#546BEA',
            color: '#FFF',
            borderRadius: 10,
            alignItems: 'center',
            padding: 30,
          }}
          href={'tel:+355696660031'}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faPhone}
            size={'2xl'}
          />
          <div
            className="display-label"
            style={{ marginTop: 20 }}
          >
            {langText('support_call')}
          </div>
        </a>
        <a
          style={{
            display: 'flex',
            minWidth: 200,
            flexDirection: 'column',
            backgroundColor: '#546BEA',
            color: '#FFF',
            borderRadius: 10,
            alignItems: 'center',
            padding: 30,
          }}
          href={'mailto:info@rbcn.al'}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            size={'2xl'}
          />
          <div
            className="display-label"
            style={{ marginTop: 20 }}
          >
            {langText('email')}
          </div>
        </a>
      </div>
      <div
        style={{
          backgroundColor: '#F5F5F5',
          padding: 20,
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faClock}
          size={'2xl'}
          color={'#546BEA'}
        />

        <div style={{ margin: '20px 0 0 30px' }}>
          <div className="display-item">
            <div className="display-label">
              {langText('support_working_Mon_Fri')}
            </div>
            <div className="display-value">
              {langText('support_working_hours_Mon_Fri')}
            </div>
          </div>
          <div className="display-item">
            <div className="display-label">
              {langText('support_working_Sut')}
            </div>
            <div className="display-value">
              {langText('support_working_hours_Sut')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportView;
