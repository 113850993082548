import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { logActivity } from "../hooks/functions";
import { GetBookingsByAccounts } from "../constants/endpoints";
import { Booking } from "../redux/user-slice";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { formatMoney } from "../functions";
import { addDays, format } from "date-fns";
import { Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { userActivity } from "../redux/general-slice";
import { FilterComponent, FilterDateComponent } from "./FilterComponents";
import langText from "../lang/i18n";
import LoadingSpinner from "./LoadingIndicator";

export default function BookingDataTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { chosenAcc, UserToken } = useAppSelector((state) => state.user);

  const [filterText, setFilterText] = useState("");
  const [filterDateStart, setFilterDateStart] = useState<Date>();
  const [filterDateEnd, setFilterDateEnd] = useState<Date>();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [turni, setTurni] = useState(-1);
  const filteredBookings = useMemo(() => {
    dispatch(userActivity());
    return bookings.filter(
      (booking) =>
        (turni === 1
          ? new Date(booking.creationDate).getHours() >= 6 &&
            new Date(booking.creationDate).getHours() < 14
          : turni === 2
          ? new Date(booking.creationDate).getHours() >= 14 &&
            new Date(booking.creationDate).getHours() < 22
          : turni === 3
          ? new Date(booking.creationDate).getHours() >= 22 ||
            new Date(booking.creationDate).getHours() < 6
          : true) &&
        (!!filterDateStart
          ? new Date(booking.creationDate).getTime() >=
            filterDateStart.getTime()
          : true) &&
        (!!filterDateEnd
          ? new Date(booking.creationDate).getTime() <=
            addDays(filterDateEnd, 1).getTime()
          : true) &&
        ((booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
          ? booking.senderName
          : booking.recipientName
        )
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
          booking.amount.value
            .toString()
            .includes(
              isNaN(Number(filterText))
                ? filterText
                : Number(filterText).toString()
            ) ||
          format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy").includes(
            filterText.toLowerCase()
          ))
    );
  }, [bookings, filterText, filterDateStart, filterDateEnd, turni]);
  const csv = useMemo(
    () => convertArrayOfObjectsToCSV(filteredBookings),
    [filteredBookings]
  );

  const completeCsv = useMemo(
    () =>
      encodeURI(
        !csv.match(/^data:text\/csv/i)
          ? `data:text/csv;charset=utf-8,${csv}`
          : csv
      ),
    [csv]
  );
  logActivity("completeCsv", completeCsv.length);

  useEffect(() => {
    if (!chosenAcc) return;
    getBookings();
  }, [chosenAcc]);

  const getBookings = async () => {
    setLoading(true);
    logActivity(
      "getting bookings for account",
      chosenAcc?.id,
      GetBookingsByAccounts()
    );
    const response = await fetch(GetBookingsByAccounts(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        account: chosenAcc?.id,
      }),
    });
    logActivity("GetBookingsByAccounts", response.status);
    if (!response.ok) return;
    const bookingsList = await response.json();
    setBookings(bookingsList);
    setLoading(false);
  };

  const Export = ({ onExport }: { onExport: () => void }) => (
    <Button
      onClick={(e) => onExport()}
      style={{
        backgroundColor: "#546BEA",
        border: "none",
        color: "white",
        borderRadius: 8,
        padding: "5px 36px",
        lineHeight: "24px",
      }}
    >
      {langText("export") + " Excel"}
    </Button>
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const handleClearDate = () => {
    if (!!filterDateStart || !!filterDateEnd) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterDateStart(undefined);
      setFilterDateEnd(undefined);
    }
  };

  function convertArrayOfObjectsToCSV(array: Booking[]) {
    let result: string;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let keys = ["Derguesi", "Perfituesi", "Shuma", "Data", "Pershkrimi"];

    if (!!array.find((b) => !!b.balance)) keys.splice(3, 0, "Balanca");

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    return array.reduce((acc, booking) => {
      return (
        acc +
        (keys.reduce((acc, key) => {
          if (acc !== "") acc += columnDelimiter;
          if (key === "Data")
            acc += format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy");
          else if (key === "Shuma")
            acc += formatMoney(booking.amount.value)
              .toString()
              .replace(/[,]/g, "");
          else if (key === "Balanca")
            acc += formatMoney(booking.balance ?? 0)
              .toString()
              .replace(/[,]/g, "");
          else if (key === "Pershkrimi")
            acc += `\"${booking.description.replace(/[#,]/g, " ")}\"`;
          else if (key === "Derguesi") acc += booking.senderName;
          else if (key === "Perfituesi") acc += booking.recipientName;
          //@ts-ignore
          else acc += booking[key];
          return acc;
        }, "") +
          lineDelimiter)
      );
    }, result);

    // array.forEach((booking) => {
    //   let ctr = 0;
    //   keys.forEach((key) => {
    //     if (ctr > 0) result += columnDelimiter;
    //
    //     if (key === "creationDate")
    //       result += format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy");
    //     else if (key === "amount")
    //       result +=
    //         formatMoney(booking.amount.value) + " " + chosenAcc?.currency;
    //     else if (key === "balance")
    //       result += formatMoney(booking.balance!) + " " + chosenAcc?.currency;
    //     //@ts-ignore
    //     else result += booking[key];
    //
    //     ctr++;
    //   });
    //   result += lineDelimiter;
    // });
  }

  async function downloadCSV() {
    const link = document.createElement("a");

    const filename = "Statement.csv";

    logActivity(completeCsv.length);
    link.setAttribute("href", completeCsv);
    logActivity(link.getAttribute("href")?.length);
    link.setAttribute("download", filename);
    link.click();
  }

  const columns: {
    name: string;
    selector: (booking: Booking) => string | JSX.Element;
    sortable?: boolean;
    sortFunction?: (bookingA: Booking, bookingB: Booking) => number;
  }[] = [
    {
      name: langText("image"),
      selector: (booking) => (
        <div>
          <img
            src={booking.avatar}
            alt="Profile"
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
            }}
          />
        </div>
      ),
    },
    {
      name: langText("name"),
      selector: (booking) =>
        booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
          ? booking.senderName
          : booking.recipientName,

      sortable: true,
    },
    {
      name: langText("amount"),
      selector: (booking) => (
        <div
          style={{
            color: (
              typeof booking.creditDebitIndicator === "string"
                ? booking.creditDebitIndicator === "CREDIT"
                : booking.creditDebitIndicator.name === "CREDIT"
            )
              ? "green"
              : "gray",
          }}
        >
          {((
            typeof booking.creditDebitIndicator === "string"
              ? booking.creditDebitIndicator === "CREDIT"
              : booking.creditDebitIndicator.name === "CREDIT"
          )
            ? ""
            : "-") +
            formatMoney(booking.amount.value) +
            " " +
            (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro")}
        </div>
      ),
      sortable: true,
      sortFunction: (bookingA, bookingB) => {
        const a = bookingA.amount.value;
        const b = bookingB.amount.value;

        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      },
    },
    {
      name: langText("date"),
      selector: (booking) =>
        format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy"),
      sortable: true,
      sortFunction: (bookingA, bookingB) => {
        const a = new Date(bookingA.creationDate);
        const b = new Date(bookingB.creationDate);

        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      },
    },
  ];

  const balancaTab: {
    name: string;
    selector: (booking: Booking) => string | JSX.Element;
    sortable?: boolean;
    sortFunction?: (bookingA: Booking, bookingB: Booking) => number;
  } = {
    name: langText("balance"),
    selector: (booking) =>
      formatMoney(booking.balance!) +
      " " +
      (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro"),
    sortable: true,
    sortFunction: (bookingA, bookingB) => {
      const a = bookingA.balance;
      const b = bookingB.balance;

      if (!a && !b) return 0;
      if (!b) return 1;
      if (!a) return -1;
      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    },
  };

  return (
    <div style={{ padding: 20, fontFamily: "Poppins" }}>
      <DataTable
        data={filteredBookings}
        //@ts-ignore
        columns={
          !!filteredBookings.find((b) => !!b.balance)
            ? [...columns, balancaTab]
            : columns
        }
        onRowClicked={(booking) => {
          navigate("/transaction-details", {
            state: {
              booking: booking,
            },
          });
        }}
        onChangePage={() => dispatch(userActivity())}
        onChangeRowsPerPage={() => dispatch(userActivity())}
        pagination={true}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            {/*<Button*/}
            {/*  onClick={(e) => setTurni(turni === 1 ? -1 : 1)}*/}
            {/*  style={{*/}
            {/*    backgroundColor: turni === 1 ? "#546BEA" : "#FFF",*/}
            {/*    color: turni !== 1 ? "#546BEA" : "#FFF",*/}
            {/*    borderRadius: 8,*/}
            {/*    padding: "5px 36px",*/}
            {/*    lineHeight: "24px",*/}
            {/*    border: "1px solid #546BEA",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Turni 1*/}
            {/*</Button>*/}
            {/*<div style={{ width: 10 }} />*/}
            {/*<Button*/}
            {/*  onClick={(e) => setTurni(turni === 2 ? -1 : 2)}*/}
            {/*  style={{*/}
            {/*    backgroundColor: turni === 2 ? "#546BEA" : "#FFF",*/}
            {/*    color: turni !== 2 ? "#546BEA" : "#FFF",*/}
            {/*    borderRadius: 8,*/}
            {/*    padding: "5px 36px",*/}
            {/*    lineHeight: "24px",*/}
            {/*    border: "1px solid #546BEA",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Turni 2*/}
            {/*</Button>*/}
            {/*<div style={{ width: 10 }} />*/}
            {/*<Button*/}
            {/*  onClick={(e) => setTurni(turni === 3 ? -1 : 3)}*/}
            {/*  style={{*/}
            {/*    backgroundColor: turni === 3 ? "#546BEA" : "#FFF",*/}
            {/*    color: turni !== 3 ? "#546BEA" : "#FFF",*/}
            {/*    borderRadius: 8,*/}
            {/*    padding: "5px 36px",*/}
            {/*    lineHeight: "24px",*/}
            {/*    border: "1px solid #546BEA",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Turni 3*/}
            {/*</Button>*/}
            {/*<div style={{ width: 10 }} />*/}

            <FilterDateComponent
              startDate={filterDateStart}
              endDate={filterDateEnd}
              setStartDate={(value) => setFilterDateStart(value)}
              setEndDate={(value) => setFilterDateEnd(value)}
              onClear={handleClearDate}
            />
            <div style={{ width: 10 }} />
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
            <div style={{ width: 10 }} />
            <Export onExport={() => downloadCSV()} />
          </div>
        }
        persistTableHead
        paginationPerPage={10}
        noDataComponent={
          <div style={{ padding: "2em", fontFamily: "Poppins" }}>
            {loading ? <LoadingSpinner /> : langText("no_data_available")}
          </div>
        }
      />
    </div>
  );
}
