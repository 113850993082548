import React, { useState } from "react";
import { format } from "date-fns";
import { sq } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { getUser } from "../localDatabase/mainDB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import langText from "../lang/i18n";
import { ServiceProps } from "../constants/Utilities";
import "./InvoiceItem.css";
import { formatMoney } from "../functions";

import timeImage from "../assets/icons/Vector.svg";
import calendarImage from "../assets/icons/calendar.svg";
import carImage from "../assets/icons/car.svg";
import userImage from "../assets/icons/Group.svg";
import moneyImage from "../assets/icons/moneys.svg";
import gpsImage from "../assets/icons/gps.svg";
import chevron from "../assets/icons/Vector 10.svg";
import chevronup from "../assets/icons/chevronup.svg";
import Collapsible from "./Collapsible";
import moment from "moment/moment";

// src\assets\icons\user.png

export default function InvoiceItem({
  bill,
  service,
  utility,
}: {
  bill: any;
  service: ServiceProps;
  utility: any;
}) {
  const navigate = useNavigate();
  const UserData = getUser();
  // logActivity("bill", bill);

  if (bill.loading) {
    return (
      <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#546BEA" />
      </div>
    );
  }

  if (bill.message) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "#546BEA",
          marginTop: 10,
          marginBottom: 30,
        }}
      >
        <div style={{ marginRight: 10 }}>
          <FontAwesomeIcon icon={bill.error ? faTimes : faCheckCircle} />
        </div>
        <div>{bill.message}</div>
      </div>
    );
  }

  function renderHeaderText(title: string, isLast: boolean) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "grey",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        <div>Numri i Fatures {title + (isLast ? "" : ", ")}</div>
      </div>
      // <ThemeText
      //   key={title}
      //   style={{
      //     fontSize: 20,
      //     color: SelectedColor,
      //   }}
      // >
      //   {}
      // </ThemeText>
    );
  }

  if (
    !bill.SerialNumber &&
    !bill.serial_number &&
    !bill.Seria &&
    !bill.detyrimi &&
    !bill.customer_id &&
    !bill.customerNo
  ) {
    let keys = Object.keys(bill);
    return (
      <div>
        <div
        // style={{
        //   display: "flex",
        //   flexDirection: "row",
        //   alignItems: "center",
        //   padding: "5px 0",
        //   marginLeft: "7rem"
        // }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {keys.map((key, index) => {
              if (key === "onPress") return null;
              if (typeof bill[key] === "object") {
                return Object.keys(bill[key]).map((key2, index2) => {
                  return renderHeaderText(
                    bill[key][key2],
                    index2 === Object.keys(bill[key]).length - 1
                  );
                });
              }
              return renderHeaderText(
                bill[key],
                index === keys.length - 1 || keys[index + 1] === "onPress"
              );
            })}
            {bill.onPress != null && (
              <button
                style={{
                  padding: 10,
                  border: "none",
                  background: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  bill.onPress();
                }}
              >
                <FontAwesomeIcon
                  size={"2x"}
                  icon={faTimesCircle}
                  color={"#546BEA"}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  let date = new Date(
    bill.invoiceMthYear
      ? bill.invoiceMthYear
          .split("/")
          .reverse()
          .map((item: string) => item.padStart(2, "0"))
          .join("-")
      : bill.InvoiceDate ??
        bill.document_date?.replace(" ", "T") ??
        bill.Data ??
        bill.invoice_date ??
        new Date().toString()
  );

  const hasExtraCosts =
    (bill.InvoiceOverDueAmount != undefined &&
      bill.InvoiceOverDueAmount != "") ||
    (bill.total_interest_overdue != undefined &&
      bill.total_interest_overdue != "0");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          // border: "1px solid #e1e1e1",
          borderRadius: "25px",
          backgroundColor: "#ffffff",
          border: "1px solid #546BEA",

          marginBottom: 50,

          // boxShadow: "0px 2px 4px rgba(84, 107, 234, 0.2)",
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <span className="amount-style">
            {formatMoney(
              bill.totalAmt ??
                bill.InvoiceTotalAmount ??
                bill.debt ??
                bill.detyrimi ??
                bill.invoice_value ??
                bill.total_invoice_ammount ??
                (bill.Vlera
                  ? parseFloat(bill.Vlera) + parseFloat(bill.Kamata)
                  : "")
            ) + " Lekë"}
          </span>

          {bill.Seria && (
            <div
              style={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#858585",
                marginLeft: "20px",
                marginBottom: "-20px",
              }}
            >
              Targa&nbsp;
              <span>{bill.contract.vlera}</span>
            </div>
          )}
          <span
            style={{
              marginLeft: "130px",
              textAlign: "center",
            }}
          >
            {" "}
            <img src={chevron} alt="User" />{" "}
          </span>
          <span
            style={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "27px",
              color: "#858585",
              marginLeft: "20px",
            }}
          >
            <span
              style={{
                margin: "0px 0px",
                textAlign: "center",
                marginRight: "10px",
              }}
            >
              {" "}
              <img src={calendarImage} alt="User" />{" "}
            </span>
            {(bill.Seria
              ? date.toTimeString().substring(0, 5) +
                ", " +
                date.getDate() +
                " "
              : "") +
              format(
                bill.gjeneruar
                  ? new Date(
                      parseInt(bill?.gjeneruar?.split("/")[2] ?? 2022),
                      parseInt(bill?.gjeneruar?.split("/")[1] ?? 1) - 1
                    )
                  : date,
                "MM yyyy",
                { locale: sq }
              )}
          </span>

          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <button
              style={{
                backgroundColor: "#81C45E",
                padding: "12px 60px",
                color: "white",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "18px",
                boxShadow: "0px 2px 4px rgba(84, 107, 234, 0.4)",
                marginTop: "-80px",
              }}
              onClick={() => {
                navigate("/confirm-payment", {
                  state: {
                    friendInfo: {
                      name: utility.short_name,
                      recipientCurrency: "ALL",
                      phoneNo: bill.SubscriberMsisdn
                        ? bill.SubscriberMsisdn
                        : UserData.mobileNumber,
                      operatorId: parseInt(
                        service.operatorId ?? utility.operatorId
                      ),
                      description: JSON.stringify({
                        account: bill.SubscriberMsisdn ? 0 : bill.CustomerCode,
                      }),
                      invoiceNo: bill.SerialNumber ?? bill.kodFature ?? "",
                      documentNo:
                        bill.serial_number ??
                        bill.Seria ??
                        bill.kodFature ??
                        bill.invoice_id ??
                        bill.invoiceNo ??
                        "",
                      contractNo:
                        bill?.contract.vlera != undefined
                          ? bill.contract.vlera
                          : bill?.contract[Object?.keys(bill?.contract)[0]],
                      customerNo: bill.customer_id,
                      date: bill.InvoiceDate,
                      serialNumber: bill.serial_number,
                    },
                    amount: parseFloat(
                      bill.Seria
                        ? parseFloat(bill.Vlera) + parseFloat(bill.Kamata)
                        : bill.InvoiceTotalAmount
                        ? bill.InvoiceTotalAmount.toString().replace(/,/g, "")
                        : bill.debt ??
                          bill.detyrimi ??
                          bill.invoice_value ??
                          bill.totalAmt ??
                          bill.total_invoice_ammount
                    ),
                    message: utility.short_name,
                    editable: false,
                    logo: utility?.logo(),
                  },
                });
              }}
            >
              {langText("pay_now")}
            </button>
          </div>

          <hr />

          {/* Username */}
          <span
            className="fine-amount"

            // style={{
            //   fontSize: "18px",
            //   fontWeight: "regular",
            //   marginBottom: "5px",
            // }}
          >
            <span style={{ margin: "0px 20px" }}>
              {" "}
              <img src={userImage} alt="User" />{" "}
            </span>
            {bill.SerialNumber ??
              bill.customer_name ??
              bill.Vendi ??
              bill.kodFature ??
              bill.customer_id ??
              bill.custName?.replace("‡", "ç") ??
              ""}
          </span>

          {/* NUMRI I FATURES  */}

          {/* {bill.serial_number && !bill.contract_number ? (
          <div>
             <span style={{ margin: "0px 20px" }}> <img src={kamataImage} alt="User" /> </span>
            <span className="fine-amount"
            // style={{ fontSize: 18 }}
            >{bill.customer_adress}</span>
            <span style={{ fontSize: 18 }}>
              {langText("invoice") + " " + bill.serial_number}
            </span>
          </div>
        ) : null} */}
          <div>
            {hasExtraCosts && (
              <span style={{ fontSize: 18, margin: "0px 20px" }}>
                <img src={moneyImage} alt="User" />
                {langText("kamata") +
                  " " +
                  ((bill.InvoiceDebt ?? bill.total_with_vat) +
                    " + " +
                    (bill.InvoiceOverDueAmount ?? bill.total_interest_overdue))}
              </span>
            )}
          </div>
          <span
            className="fine-amount"

            // style={{ fontSize: 18 }}
          >
            <span style={{ margin: "0px 20px", textAlign: "center" }}>
              {" "}
              <img src={timeImage} alt="User" />{" "}
            </span>
            {moment(date).format("HH:mm")}
          </span>

          {bill.Seria && (
            <div>
              {bill.Kamata != undefined && bill.Kamata != "0" && (
                <div>
                  <div
                    className="fine-amount"
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    // }}
                  >
                    {/* <span style={{ margin: "0px 20px" }}> <img src={moneyImage} alt="Kamata" /> </span>
                  <span className="fine-amount"
                    // style={{
                    //   flex: 1,
                    //   fontSize: 18,
                    // }}
                  >
                    {langText("fine_amount")}
                  </span>
                  */}
                    {/* <span className="fine-amount"
                    // style={{
                    //   flex: 1,
                    //   fontSize: 18,
                    //   textAlign: "right",
                    // }}

                  >
                    {bill.Vlera + " " + langText("ALL")}
                  </span> */}
                  </div>

                  <div
                    className="fine-amount"
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    // }}
                  >
                    <span style={{ margin: "0px 20px" }}>
                      {" "}
                      <img src={moneyImage} alt="User" />{" "}
                    </span>
                    {/* kamata */}
                    <span
                      className="fine-amount"
                      // style={{
                      //   flex: 1,
                      //   fontSize: 18,
                      // }}
                    >
                      {langText("kamata")}
                    </span>

                    <span
                      className="fine-amount"
                      // style={{
                      //   flex: 1,
                      //   fontSize: 18,
                      //   textAlign: "right",
                      // }}
                    >
                      {bill.Kamata + " " + langText("ALL")}
                    </span>
                  </div>

                  {/* <span
                    className="fine-amount"

                    // style={{ fontSize: 18 }}
                  >
                    <span style={{ margin: "0px 20px", textAlign: "center" }}>
                      {" "}
                      <img src={gpsImage} alt="User" />{" "}
                    </span>
                    Bar Kafe
                  </span> */}

                  <div
                    className="fine-amount"
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    // }}
                  ></div>
                </div>
              )}

              <div
                className="fine-amount"
                // style={{ fontSize: 18 }}
              >
                <span style={{ margin: "0px 20px" }}>
                  {" "}
                  <img src={carImage} alt="Mjeti" />{" "}
                </span>
                {bill.MjetiMarka}
              </div>
              <div
                className="fine-amount"
                // style={{ fontSize: 18 }}
              >
                <span style={{ margin: "0px 20px" }}>
                  {" "}
                  <img src={userImage} alt="User" />{" "}
                </span>
                {bill.MjetiPronari.replace(/ÃƒÂ¿/g, "Ç")
                  .replace(/ÃƒÂ§/g, "Ç")
                  .replace(/Ã‡/g, "Ç")
                  .replace(/ÃƒÂ«/g, "ë")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
