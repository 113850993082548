export function formatMoney(n: string | number) {
  if (!n) return "0.00";
  n = n.toString();
  let hasDecimals = parseFloat(n) % 1 != 0;
  var nr = parseFloat(n);
  if (hasDecimals) {
    nr = Math.floor(nr * 100) / 100;
  }
  return nr.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
