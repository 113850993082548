import { useAppSelector } from '../../hooks/reduxHooks';
import langText from '../../lang/i18n';

function PersonalInfoView() {
  const { UserData } = useAppSelector(
    (state) => state.user
  );
  return (
    <div>
      <div className="display-item">
        <div className="display-label">
          {langText('name') + ': '}
        </div>
        <div className="display-value">
          {UserData?.firstName}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">
          {langText('lastname') + ': '}
        </div>
        <div className="display-value">
          {UserData?.lastName}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">
          {langText('email') + ': '}
        </div>
        <div className="display-value">
          {!!UserData?.email ? UserData.email : '-'}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">
          {langText('phone_number') + ': '}
        </div>
        <div className="display-value">
          {UserData?.mobileNumber}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">
          {langText('address') + ': '}
        </div>
        <div className="display-value">
          {UserData?.address == null
            ? ''
            : (UserData?.address?.line1 ?? '') +
              ', ' +
              (UserData?.address?.line2
                ? UserData?.address.line2 + ', '
                : '') +
              (UserData?.address?.city ?? '') +
              ', ' +
              (UserData?.address?.state ?? '')}
        </div>
      </div>
      <div className="display-item">
        <div className="display-label">
          {langText('place_of_birth') + ': '}
        </div>
        <div className="display-value">
          {UserData?.birthCity}
        </div>
      </div>
    </div>
  );
}

export default PersonalInfoView;
