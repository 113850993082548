import React, { useEffect, useState } from "react";
import {
  GetLastPersonPayments,
  GetUserContacts,
  ProfilePicture,
} from "../constants/endpoints";
import langText from "../lang/i18n";
import { logActivity } from "../hooks/functions";
import { useAppSelector } from "../hooks/reduxHooks";
import { getCurrentServer } from "../localDatabase/mainDB";
import { useNavigate } from "react-router-dom";
import { FilterComponent } from "./FilterComponents";
import "./ContactList.css";

export default function ContactList() {
  const server = getCurrentServer();
  const { UserToken, UserData } = useAppSelector((state) => state.user);
  const { SelectedColor } = useAppSelector((state) => state.theme);
  const navigate = useNavigate();
  const [contacts, setContacts] = useState<any[]>([]);
  const [contactErrorMessage, setContactErrorMessage] = useState("");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getContacts();
  }, []);

  async function getContacts() {
    let storedContacts: any[] = [];
    let recentPayments: any[] = [];

    const response = await fetch(GetUserContacts(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });

    logActivity("getUserContacts", response.status);

    if (response.status === 204) {
      logActivity("No contacts from backend");
      setContactErrorMessage(langText("no_contacts"));
    }
    if (response.status === 200) {
      const responseData = await response.json();
      logActivity("response Data from getting Contacts", responseData);
      if (responseData.length > 0) {
        setContactErrorMessage("");
        storedContacts = responseData;
      } else setContactErrorMessage(langText("no_contacts"));
    }

    const recentPaymentsResponse = await fetch(GetLastPersonPayments(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });

    logActivity(GetLastPersonPayments(), "GET", response.status);
    if (!response.ok) {
      logActivity("No recent payments from backend");
    } else {
      const responseData = await recentPaymentsResponse.json();
      logActivity("response Data from getting recent payments", responseData);
      if (responseData.length > 0) {
        recentPayments = responseData;
      }
    }

    setContacts([
      ...recentPayments,
      ...storedContacts.filter(
        (contact) =>
          !recentPayments.find(
            (recentContact) => recentContact.id === contact.userId
          )
      ),
    ]);
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        borderRadius: "25px",
        border: "1px #cccccc",
        margin: "20px",
        marginTop: "-30px",
        backgroundColor: "#FFF",
        padding: "35px",
      }}
    >
      <h2
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "20px",
          color: "#44494B",
        }}
      >
        Transfero tek një kontakt
      </h2>

      <br />
      <FilterComponent
        placeholder={"Kerko"}
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        {contacts.length === 0 ? (
          <p>{`${contactErrorMessage}`}</p>
        ) : (
          contacts
            .filter(
              (contact) =>
                contact.userId !== null &&
                contact.userId !== "null" &&
                contact.userId !== UserData?.id &&
                (contact.name
                  ?.toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                  contact.mobileNumber
                    ?.toLowerCase()
                    .includes(filterText.toLowerCase()))
            )
            .map((contact, index) => {
              const initials = contact.name
                .split(" ")
                .map((n: string) => n[0]?.toUpperCase() ?? "")
                .join("");

              return (
                <div
                  className="contact"
                  key={contact.id}
                  onClick={() => {
                    if (contact.userId !== null && contact.userId !== "null")
                      navigate("/confirm-payment", {
                        state: {
                          friendInfo: {
                            ...contact,
                            userId: contact.userId ?? contact.id,
                          },
                        },
                      });
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      marginRight: 20,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#546BEA",
                      borderWidth:
                        contact.userId !== null && contact.userId !== "null"
                          ? 0
                          : 1,
                    }}
                  >
                    {contact.userId !== null && contact.userId !== "null" ? (
                      <img
                        src={ProfilePicture(contact.userId ?? contact.id)}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 25,
                        }}
                      />
                    ) : (
                      <div style={{ fontSize: "1.5em", color: "#FFF" }}>
                        {initials}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ flex: 1 }}>{contact.name}</p>
                    {!(
                      contact.userId !== null && contact.userId !== "null"
                    ) && (
                      <span style={{ color: SelectedColor }}>
                        {langText("invite")}
                      </span>
                    )}
                  </div>
                </div>
              );
            })
        )}
      </div>
    </div>
  );
}
