import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { logActivity } from "../hooks/functions";

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  title: string;
  children: JSX.Element;
};
const Modal = ({ isVisible, setIsVisible, title, children }: Props) => {
  const toggleModal = () => {
    logActivity("clicked");
    setIsVisible(!isVisible);
  };

  return (
    <>
      {isVisible && (
        <div
          className="modal-overlay"
          style={{ fontFamily: "Poppins" }}
          onClick={toggleModal}
        >
          <div
            className="modal-content"
            style={{ fontFamily: "Poppins" }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h3 style={{ fontFamily: "Poppins" }}>{title}</h3>
              <button
                onClick={toggleModal}
                style={{
                  background: "none",
                  border: "none",
                  fontFamily: "Poppins",
                }}
              >
                <FontAwesomeIcon icon={faTimes} size="2x" color={"#B1B1B1"} />
              </button>
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
