import React, { useEffect, useState } from "react";
import "./MobileTopUp.css";

import Header from "../../components/Header";
import langText from "../../lang/i18n";
import { useUtilityService } from "../../hooks/useUtilityService";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useAppSelector } from "../../hooks/reduxHooks";
import { logOutClearDB } from "../../localDatabase/mainDB";

export default function MobileTopUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { utility, service } = location.state;
  const { contracts } = useAppSelector((state) => state.general);
  const [mobileNumber, setMobileNumber] = useState("+355");
  const [amount, setAmount] = useState("");
  const [showAmounts, setShowAmounts] = useState(false);
  const suggestedAmounts = [service.minValue, 1000, 1500, 2000];
  const [showAddContract, setShowAddContract] = useState(false);
  const [newContract, setNewContract] = useState<Map<string, string>>();
  const {
    loading: contractsLoading,
    availability,
    deleteContract,
    errorMsg,
    saveContract,
  } = useUtilityService(service.operatorId ?? utility.operatorId, service);

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  const handleMobileNumberChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMobileNumber(e.target.value);
  };

  const handleAmountChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAmount(e.target.value);
  };

  const handleSuggestedAmountClick = (
    suggestedAmount: React.SetStateAction<string>
  ) => {
    setAmount(suggestedAmount);
    setShowAmounts(false);
  };

  function handleTopUpClick() {
    let number = mobileNumber;

    if (number.startsWith("06")) {
      number = "355" + number.substring(1);
    } else if (number.startsWith("6")) {
      number = "355" + number;
    } else if (number.startsWith("+355")) {
      number = number.substring(1);
    }

    navigate("/confirm-payment", {
      state: {
        friendInfo: {
          name: utility.short_name,
          recipientCurrency: "ALL",
          phoneNo: number,
          operatorId: parseInt(service.operatorId ?? utility.operatorId),
        },
        amount: parseFloat(amount),
        message: utility.short_name + " top up",
        editable: false,
        logo: utility.logo(),
      },
    });
  }

  return (
    <div>
      <Sidebar />

      <div style={{ margin: 20 }}>
        <Header title={service.name} />
      </div>
      <div className="container">
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "row",*/}
        {/*    alignItems: "center",*/}
        {/*    justifyContent: "center",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {!showAddContract ? (*/}
        {/*    <button*/}
        {/*      onClick={() => setShowAddContract(true)}*/}
        {/*      style={{*/}
        {/*        width: "20%",*/}
        {/*        backgroundColor: "#546BEA",*/}
        {/*        padding: "1rem",*/}
        {/*        color: "white",*/}
        {/*        border: "none",*/}
        {/*        borderRadius: "5px",*/}
        {/*        cursor: "pointer",*/}
        {/*        fontSize: "18px",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {langText(*/}
        {/*        "add_new_" +*/}
        {/*          (service.name.includes("UK")*/}
        {/*            ? "uk"*/}
        {/*            : service.operatorId ?? utility.operatorId)*/}
        {/*      )}*/}
        {/*    </button>*/}
        {/*  ) : (*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        borderRadius: 10,*/}
        {/*        backgroundColor: `rgba(0, 0, 0, 0.09)`,*/}
        {/*        boxShadow: "0 4px 8px 0 rgba(84, 107, 234, 0.2)",*/}
        {/*        padding: "20px 50px",*/}
        {/*        width: 400,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {service.fields.map((field: any, index: number) => {*/}
        {/*        return (*/}
        {/*          <div>*/}
        {/*            <label*/}
        {/*              style={{*/}
        {/*                textAlign: "center",*/}
        {/*                marginRight: 20,*/}
        {/*                color: "#546BEA",*/}
        {/*              }}*/}
        {/*              htmlFor={field.label}*/}
        {/*            >*/}
        {/*              {langText(field.label)}*/}
        {/*            </label>*/}
        {/*            <input*/}
        {/*              style={{*/}
        {/*                width: "100%",*/}
        {/*                padding: "0.5rem",*/}
        {/*                borderRadius: "5px",*/}
        {/*                border: "1px solid gray",*/}
        {/*                fontSize: "16px",*/}
        {/*              }}*/}
        {/*              type="text"*/}
        {/*              id={field.label}*/}
        {/*              value={newContract?.get(field.label) ?? ""}*/}
        {/*              onChange={(e) => {*/}
        {/*                let newMap = new Map<string, string>();*/}
        {/*                newContract?.forEach((value, key) => {*/}
        {/*                  newMap.set(key, value);*/}
        {/*                });*/}
        {/*                newMap.set(field.label, e.target.value);*/}
        {/*                setNewContract(newMap);*/}
        {/*              }}*/}
        {/*              maxLength={15}*/}
        {/*            />*/}
        {/*          </div>*/}
        {/*        );*/}
        {/*      })}*/}

        {/*      <button*/}
        {/*        style={{*/}
        {/*          marginTop: 20,*/}
        {/*          width: "100%",*/}
        {/*          backgroundColor: "#546BEA",*/}
        {/*          padding: "1rem",*/}
        {/*          color: "white",*/}
        {/*          border: "none",*/}
        {/*          borderRadius: "5px",*/}
        {/*          cursor: "pointer",*/}
        {/*          fontSize: "18px",*/}
        {/*        }}*/}
        {/*        disabled={!newContract}*/}
        {/*        onClick={() => saveContract(newContract!)}*/}
        {/*      >*/}
        {/*        {langText(*/}
        {/*          "add_new_" +*/}
        {/*            (service.name.includes("UK")*/}
        {/*              ? "uk"*/}
        {/*              : service.operatorId ?? utility.operatorId)*/}
        {/*        )}*/}
        {/*      </button>*/}
        {/*      {!!errorMsg && (*/}
        {/*        <div style={{ color: "red", textAlign: "center" }}>*/}
        {/*          {errorMsg}*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}

        <div className="input-group">
          <label htmlFor="mobileNumber" className="label">
            Mobile Number:
          </label>
          <div className="control">
            <input
              type="text"
              id="mobileNumber"
              className="input"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              placeholder="+355 XXX XXX XXX"
              maxLength={15}
            />
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="amount" className="label">
            Amount:
          </label>
          <div className="control has-icons-right">
            <input
              type="text"
              id="amount"
              className="input"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
            />
            <span className="icon is-right" style={{ marginTop: 5 }}>
              {langText("ALL")}
            </span>
          </div>

          <div style={{ marginLeft: 10 }}>
            {suggestedAmounts.map((suggestedAmount) => (
              <button
                key={suggestedAmount}
                className="button is-link is-outlined"
                onClick={() => handleSuggestedAmountClick(suggestedAmount)}
              >
                {suggestedAmount}
              </button>
            ))}
          </div>
        </div>
        <button
          style={{
            marginTop: 20,
            width: "100%",
            backgroundColor: "#546BEA",
            padding: "1rem",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "18px",
          }}
          onClick={handleTopUpClick}
        >
          Top-up
        </button>
      </div>
    </div>
  );
}
