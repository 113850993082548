import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import {
  BankAccounts,
  GetBankName,
  TransferMoney,
} from '../../constants/endpoints';
import {
  handleCardNumber,
  hexWithOpacity,
  logActivity,
} from '../../hooks/functions';
import langText from '../../lang/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal';
import animationData from '../../assets/782-check-mark-success.json';
import useWebhook from '../../hooks/useWebhook';
import { RubiserAPIKEY } from '../../Network';
import Intesa from '../../assets/icons/banks/intesa.svg';
import Abi from '../../assets/icons/banks/abi.svg';
import Alpha from '../../assets/icons/banks/alpha.svg';
import BankOfAlbania from '../../assets/icons/banks/bankOfAlbania.svg';
import Credins from '../../assets/icons/banks/credins.svg';
import Procredit from '../../assets/icons/banks/procredit.svg';
import Raiffeisen from '../../assets/icons/banks/raiffeisen.svg';
import TiranaBank from '../../assets/icons/banks/tiranabank.svg';
import UBA from '../../assets/icons/banks/UBA.svg';
import Union from '../../assets/icons/banks/union.svg';
import BKT from '../../assets/icons/banks/bkt.svg';
import OTP from '../../assets/icons/banks/otp.svg';
import Fibank from '../../assets/icons/banks/fibank.svg';
import { formatMoney } from '../../functions';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';

function CashOutView() {
  const { chosenAcc, PersonId, UserToken } = useAppSelector(
    (state) => state.user
  );
  const [cardHolderName, setCardHolderName] = useState('');
  const [iban, setIban] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] =
    useState<any>(null);
  const [transferAmount, setTransferAmount] = useState('');
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getBankAccountsList();
  }, []);

  useEffect(() => {
    if (!success) return;
    getBankAccountsList();
  }, [success]);

  async function getBankAccountsList() {
    logActivity('getting ', BankAccounts(PersonId));
    fetch(BankAccounts(PersonId), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + UserToken,
      },
    }).then((res) => {
      logActivity('Get Bank Accounts ', res.status);
      if (res.status === 200)
        res.json().then((res) => {
          logActivity(res);
          setAccounts(res);
        });
    });
  }

  async function addBankAccounts() {
    const currency = 'ALL';
    let bankName = await getBankName(iban);
    if (!bankName) {
      return;
    }

    if (cardHolderName === '' || iban === '') {
      setError(langText('fill_details'));
      setLoading(false);
      return;
    }

    setLoading(true);
    let body = JSON.stringify({
      customer: PersonId,
      iban: iban.replace(/ /g, ''),
      accountHolder: cardHolderName,
      bankName: bankName,
      // swift: swift,
      currencyCode: currency,
      account: chosenAcc?.id,
    });
    logActivity('sending : ', body);
    fetch(BankAccounts(), {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + UserToken,
      },
      body: body,
    })
      .then((res) => {
        setLoading(false);
        logActivity('Add Bank Account ' + res.status);
        res.json().then((data) => {
          logActivity('Add Bank Account data ', data);
        });
        if (res.status === 200 || res.status === 201) {
          setSuccess(true);
          setIban('');
          setCardHolderName('');
        } else {
          setError(langText('error'));
        }
      })
      .catch((e) => {
        let err = Error(e);
        if (
          err.message ===
          'TypeError: Network request failed'
        ) {
          setLoading(false);
        }
      });
  }

  async function getBankName(iban: string) {
    setLoading(true);

    if (!iban.startsWith('AL')) {
      setError(langText('no_albanian_bank_found'));
      setLoading(false);
      return;
    }
    logActivity(
      'getBankName',
      GetBankName(iban.replace(/ /g, ''))
    );
    let res = await fetch(
      GetBankName(iban.replace(/ /g, '')),
      {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: RubiserAPIKEY,
        },
      }
    );
    setLoading(false);
    logActivity('getBankName ' + res.status);
    if (res.status === 200) {
      let data = await res.json();
      logActivity('data', data);
      return data.bankName;
    } else if (res.status === 422 || res.status === 404) {
      setError(langText('invalid_iban'));
    } else {
      setError(langText('error_iban'));
    }
  }

  function makeTransfer() {
    setLoading(true);
    let reqBody = JSON.stringify({
      fundingSource: selectedAccount?.id,
      account: chosenAcc?.id,
      transferType: 'WIRE',
      currencyCode: chosenAcc?.currency,
      amount: Number(transferAmount.replace(/,/g, '')),
      transferStatus: 'PENDING',
      transferDirection: 'OUTGOING',
    });
    logActivity(reqBody);
    fetch(TransferMoney(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + UserToken,
      },
      body: reqBody,
    })
      .then((res) => {
        setLoading(false);
        logActivity('makeTransfer', res.status);
        if (res.status === 201) {
          setSuccess(true);
        } else {
          setError(langText('error'));
        }
        res.json().then((resData) => {
          logActivity('makeTransfer', resData);
        });
      })
      .catch((e) => {
        setLoading(false);
        let err = Error(e);
      });
  }

  const handleChange = (event: any) => {
    logActivity(event.target.value);
    const text = (
      event.target.value === '' ? '0' : event.target.value
    ).replace(/,/g, '');
    if (
      isNaN(text) &&
      !(text.endsWith('.') && text.split('.').length === 2)
    )
      return;
    setTransferAmount(text);
  };

  if (!!selectedAccount)
    return (
      <div>
        <div>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => setSelectedAccount(null)}
          />
        </div>
        <div
          className="display-label"
          style={{ fontWeight: 600, marginBottom: 20 }}
        >
          {langText('select_withdraw_amount_desc') +
            (selectedAccount.currencyCode === 'ALL'
              ? '200.00 Lekë'
              : '2.00 €')}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <div
            className="display-label"
            style={{ minWidth: 200 }}
          >
            {langText('amount')}
          </div>
          <input
            className="input"
            type="number"
            value={transferAmount}
            max={15}
            onChange={handleChange}
          />
        </div>

        <div
          style={{
            alignSelf: 'center',
            marginBottom: 10,
            color: hexWithOpacity('#FF0000', 0.7),
          }}
        >
          {error}
        </div>
        <div
          onClick={
            Number(transferAmount.replace(/,/g, '')) < 200
              ? undefined
              : () => setConfirm(true)
          }
          style={{
            display: 'flex',
            minWidth: 200,
            flexDirection: 'column',
            backgroundColor: '#546BEA',
            color: '#FFF',
            borderRadius: 10,
            alignItems: 'center',
            padding: '20px 70px',
            alignSelf: 'center',
            opacity:
              Number(transferAmount.replace(/,/g, '')) < 200
                ? 0.5
                : 1,
          }}
        >
          <div className="display-label">
            {langText('continue')}
          </div>
        </div>

        <Modal
          isVisible={confirm}
          setIsVisible={setConfirm}
          title={langText('bank_transfer')}
        >
          <div>
            <div>{langText('bank_transfer_confirm')}</div>
            <div>
              {formatMoney(
                transferAmount.replace(/,/g, '')
              ) +
                ' (- ' +
                (selectedAccount.currencyCode === 'ALL'
                  ? 200
                  : 2
                ).toFixed(2) +
                ' ' +
                langText(chosenAcc?.currency) +
                ' ' +
                langText('commission') +
                ')'}
            </div>

            <div
              onClick={makeTransfer}
              style={{
                display: 'flex',
                minWidth: 200,
                flexDirection: 'column',
                backgroundColor: '#546BEA',
                color: '#FFF',
                borderRadius: 10,
                alignItems: 'center',
                padding: '20px 70px',
                alignSelf: 'center',
                marginTop: 20,
              }}
            >
              <div className="display-label">
                {langText('continue')}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isVisible={success}
          setIsVisible={setSuccess}
          title={langText('tranfer_to_bank')}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={400}
                width={400}
              />

              <button
                style={{
                  marginTop: 10,
                  width: '100%',
                  padding: '10px',
                  backgroundColor: '#546BEA',
                  color: 'white',
                  borderRadius: '10px',
                  fontSize: 18,
                  border: 'none',
                  fontFamily: 'Poppins',
                }}
                onClick={() => {
                  setSuccess(false);
                  navigate('/');
                }}
                disabled={loading}
              >
                {langText('continue')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          className="display-label"
          style={{ minWidth: 200 }}
        >
          {langText('account_holder_name')}
        </div>
        <input
          className="input"
          type="text"
          value={cardHolderName}
          onChange={(e) =>
            setCardHolderName(e.target.value)
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          className="display-label"
          style={{ minWidth: 200 }}
        >
          {langText('iban')}
        </div>
        <input
          className="input"
          type="text"
          value={iban}
          onChange={(e) => {
            if (e.target.value.length <= iban.length)
              setIban(e.target.value);
            else setIban(handleCardNumber(e.target.value));
          }}
          maxLength={43}
        />
      </div>

      <div
        style={{
          alignSelf: 'center',
          marginBottom: 10,
          color: hexWithOpacity('#FF0000', 0.7),
        }}
      >
        {error}
      </div>
      <div
        onClick={addBankAccounts}
        style={{
          display: 'flex',
          minWidth: 200,
          flexDirection: 'column',
          backgroundColor: '#546BEA',
          color: '#FFF',
          borderRadius: 10,
          alignItems: 'center',
          padding: '20px 70px',
          alignSelf: 'center',
        }}
      >
        <div className="display-label">
          {langText('add')}
        </div>
      </div>

      <div>
        {accounts.map((account) => {
          return (
            <div
              onClick={() => setSelectedAccount(account)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                borderRadius: 10,
                boxShadow:
                  '0 4px 8px 0 rgba(84, 107, 234, 0.2)',
                margin: 10,
              }}
            >
              <div>{getIcon(account.bankName)}</div>
              <div>{account.bankName}</div>
              <div>
                {langText(
                  account.currencyCode === 'EUR'
                    ? 'EUR_long'
                    : account.currencyCode
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const getIcon = (iconName: string) => {
  if (iconName.includes('FIBANK'))
    return (
      <img
        src={Fibank}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('TIRANA'))
    return (
      <img
        src={TiranaBank}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('BKT'))
    return (
      <img
        src={BKT}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('OTP'))
    return (
      <img
        src={OTP}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('CREDINS'))
    return (
      <img
        src={Credins}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('RAIFFEISEN'))
    return (
      <img
        src={Raiffeisen}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('INTESA'))
    return (
      <img
        src={Intesa}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('ALPHA'))
    return (
      <img
        src={Alpha}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('UNION'))
    return (
      <img
        src={Union}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('UNITED'))
    return (
      <img
        src={UBA}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('PROCREDIT'))
    return (
      <img
        src={Procredit}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('AMERICAN'))
    return (
      <img
        src={Abi}
        style={{ width: 100, height: 100 }}
      />
    );
  if (iconName.includes('BANK OF ALBANIA'))
    return (
      <img
        src={BankOfAlbania}
        style={{ width: 100, height: 100 }}
      />
    );
};

export default CashOutView;
