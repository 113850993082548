import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import langText from "../../lang/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  unwantedOperators,
  useUtilityService,
} from "../../hooks/useUtilityService";
import { useAppSelector } from "../../hooks/reduxHooks";
import ReactModal from "react-modal";

const RegisterBillModal = ({
  modalVisible,
  onBackdropPress,
  provider,
  service,
  selected,
  setShouldReload = (bool: any) => {},
  selectContract = (contract: any) => {},
  operatorId,
  filter = () => true,
}: any) => {
  const { Theme, SelectedColor } = useSelector((state: any) => state.theme);
  const { contracts } = useAppSelector((state) => state.general);

  const [autoPay, setAutoPay] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userInputs, setUserInputs] = useState(
    new Array(service.fields.length)
  );

  const modal = useRef(null);
  const { loading, saveContract, deleteContract, errorMsg } = useUtilityService(
    operatorId,
    service,
    modal as any
  );

  useEffect(() => {
    setUserInputs(new Array(service.fields.length));
  }, []);

  useEffect(() => {}, [bottom]);

  useEffect(() => {}, [autoPay]);

  useEffect(() => {}, []);

  return (
    modalVisible && (
      <div
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <ReactModal
          ref={modal}
          isOpen={true}
          // isVisible={modalVisible}
        >
          <div
            style={{
              width: "20em",
              height: "20em",
              position: "absolute",
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
            }}
            onClick={() => {
              //modal.current.close();
              setTimeout(() => onBackdropPress(), 200);
            }}
          />
          <div
            style={{
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              width: "100%",
              backgroundColor: Theme.backgroundColor,
              padding: 20,
              position: "absolute",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div
                style={{ fontFamily: "Roboto-Regular", fontSize: 18, flex: 1 }}
              >
                {!langText("bill_id_desc" + operatorId).startsWith("[missing")
                  ? langText("bill_id_desc" + operatorId)
                  : langText("bill_id_desc")}
              </div>
              <div
                onClick={() => {
                  // modal.current.close();
                  setTimeout(() => onBackdropPress(), 200);
                }}
                style={{
                  marginLeft: 10,
                  padding: 10,
                  backgroundColor: SelectedColor,
                  borderRadius: 50,
                }}
              >
                <FontAwesomeIcon icon="times" color={SelectedColor} />
              </div>
            </div>

            {!selected &&
              (!service.maxContractLength ||
                contracts.length < service.maxContractLength) &&
              service.fields.map((field: any, index: any) => {
                return (
                  <div key={index} style={{ margin: 10 }}>
                    <input
                      type={field.type}
                      onChange={(newValue) => {
                        setHasError(false);
                        let newArr = [...userInputs];
                        newArr[index] = newValue;
                        setUserInputs(newArr);
                      }}
                      value={userInputs[index] ?? ""}
                      placeholder={field.placeholder}
                      pattern={field.pattern}
                      autoCapitalize={field.autoCapitalize}
                    />
                  </div>
                );
              })}

            {contracts?.filter(filter).map((contract: any, index: any) => {
              let thisIsSelected = true;

              let keysArray = Object.keys(selected ?? {});
              if (keysArray.length > 0)
                keysArray.forEach((key) => {
                  if (contract[key] != selected[key]) thisIsSelected = false;
                });
              else thisIsSelected = false;

              return (
                <div
                  key={contract.id}
                  onClick={() => {
                    selectContract(contract);
                    // modal.current.close();
                    setTimeout(() => onBackdropPress(), 200);
                  }}
                  style={{
                    flexDirection: "row",
                    borderBottomColor: Theme.textColor,
                    backgroundColor: thisIsSelected
                      ? SelectedColor
                      : Theme.backgroundColor,
                    borderBottomWidth: 1,
                    alignItems: "center",
                    padding: 5,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {!unwantedOperators.includes(operatorId) ? (
                      typeof contract["vlera"] == "object" ? (
                        Object.keys(contract["vlera"]).map((key2, index2) => {
                          return (
                            <div
                              key={key2}
                              style={{
                                fontSize: 18,
                              }}
                            >
                              {langText(key2) + ": " + contract["vlera"][key2]}
                            </div>
                          );
                        })
                      ) : (
                        <div key={index} style={{ fontSize: 18 }}>
                          {contract["vlera"]}
                        </div>
                      )
                    ) : (
                      service.fields.map((field: any, index: any) => {
                        return (
                          <div key={index} style={{ fontSize: 18 }}>
                            {contract[field.label]}
                          </div>
                        );
                      })
                    )}
                  </div>

                  <div
                    style={{ padding: 10 }}
                    onClick={async () => {
                      await deleteContract(contract);
                      setShouldReload(true);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color={Theme.textColor}
                    />
                  </div>
                </div>
              );
            })}

            {errorMsg != "" && (
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Roboto-Regular",
                  color: "red",
                }}
              >
                {errorMsg}
              </div>
            )}
            {!selected &&
              (!service.maxContractLength ||
                contracts.length < service.maxContractLength) && (
                <button
                  style={{ margin: 10 }}
                  onClick={async () => {
                    const obj = await saveContract(userInputs as any);
                    if (!errorMsg && !!obj) {
                      setShouldReload(true);
                      setUserInputs(new Array(service.fields.length));
                      // modal.current.close();
                      selectContract(obj);
                      setTimeout(() => onBackdropPress(), 200);
                    }
                  }}
                >
                  {langText(contracts?.length > 0 ? "add" : "continue")}
                </button>
              )}
          </div>
        </ReactModal>
      </div>
    )
  );
};
export default RegisterBillModal;
