import Lottie from "react-lottie";
import langText from "../../lang/i18n";
import Modal from "../../components/Modal";
import { hexWithOpacity, logActivity } from "../../hooks/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeUserPassword } from "../../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import {
  faCheckCircle,
  faTimesCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import animationData from "../../assets/782-check-mark-success.json";

let regexHasUpperCase = /^(?=.*[A-Z])(?=\S+$).{1,}$/;
let regexHasLowerCase = /^(?=.*[a-z])(?=\S+$).{1,}$/;
let regexHasNumber = /^(?=.*[0-9])(?=\S+$).{1,}$/;
let regexHasSpecialChar = /^(?=.*[@#$%+=.,!])(?=\S+$).{1,}$/;

function PasswordView() {
  const { UserToken, PersonId } = useAppSelector((state) => state.user);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputsError, setInputsError] = useState("");
  const navigate = useNavigate();

  function checkPassword() {
    if (currentPassword === "" || newPassword === "" || newPassword2 === "") {
      setInputsError(langText("change_pass_empty_fields_error"));
      return;
    }
    if (newPassword !== newPassword2) {
      setInputsError(langText("change_pass_pass_not_match"));
      return;
    }
    changePassword();
  }

  async function changePassword() {
    logActivity("Bearer " + UserToken);
    logActivity(ChangeUserPassword(PersonId!));
    setLoading(true);

    const response = await fetch(ChangeUserPassword(PersonId!), {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        id: PersonId,
        password: currentPassword,
        newPassword: newPassword,
        newPassword2: newPassword2,
      }),
    });

    logActivity("ChangeUserPassword", response.status);
    if (response.status === 202) {
      setLoading(false);
      setSuccess(true);
    } else {
      setInputsError(langText("old_password_error"));
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        className="display-label"
        style={{
          fontSize: 24,
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        {langText("change_password")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div className="display-label" style={{ minWidth: 200 }}>
            {langText("current_password")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="display-label"
            style={{ minWidth: 200, marginBottom: 20 }}
          >
            {langText("new_password")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="display-label"
            style={{ minWidth: 200, marginBottom: 20 }}
          >
            {langText("new_password_again")}
          </div>
          <input
            className="input"
            type="password"
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {newPassword.length >= 8 ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color:
                newPassword.length >= 8
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_8chars")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasUpperCase.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasUpperCase.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_capital_char")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasLowerCase.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasLowerCase.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_lowercase_char")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasNumber.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasNumber.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_number")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {regexHasSpecialChar.test(newPassword) ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color: regexHasSpecialChar.test(newPassword)
                ? "#34C98E"
                : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("password_special_symbol")}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!!newPassword2 && newPassword !== newPassword2 ? (
            <FontAwesomeIcon
              icon={faTimesCircle}
              color={hexWithOpacity("#FF0000", 0.7)}
            />
          ) : !!newPassword2 && newPassword === newPassword2 ? (
            <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
          ) : (
            <FontAwesomeIcon
              icon={faCircle}
              color={hexWithOpacity("#303030", 0.7)}
            />
          )}
          <div
            className="display-label"
            style={{
              color:
                !!newPassword2 && newPassword !== newPassword2
                  ? hexWithOpacity("#FF0000", 0.7)
                  : !!newPassword2 && newPassword === newPassword2
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
              marginLeft: 10,
            }}
          >
            {langText("old_password_not_equal_to_new")}
          </div>
        </div>
      </div>

      <div
        style={{
          alignSelf: "center",
          marginBottom: 10,
          color: hexWithOpacity("#FF0000", 0.7),
        }}
      >
        {inputsError}
      </div>
      <div
        onClick={checkPassword}
        style={{
          display: "flex",
          minWidth: 200,
          flexDirection: "column",
          backgroundColor: "#546BEA",
          color: "#FFF",
          borderRadius: 10,
          alignItems: "center",
          padding: "20px 70px",
          alignSelf: "center",
        }}
      >
        <div className="display-label" style={{}}>
          {langText("change_password")}
        </div>
      </div>
      <Modal
        isVisible={success}
        setIsVisible={setSuccess}
        title={langText("change_password_success")}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={400}
            />

            <button
              style={{
                marginTop: 10,
                width: "100%",
                padding: "10px",
                backgroundColor: "#546BEA",
                color: "white",
                borderRadius: "10px",
                fontSize: 18,
                border: "none",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                setSuccess(false);
                setCurrentPassword("");
                setNewPassword("");
                setNewPassword2("");

                navigate("/");
              }}
              disabled={loading}
            >
              {langText("continue")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PasswordView;
