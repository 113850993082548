import { AccountProps, Booking, UserDataProps } from "../redux/user-slice";
import { Contract } from "../redux/general-slice";

export const MockUser: UserDataProps = {
  fatcaRelevant: false,
  id: "ff80818185ab981301860211440f0004",
  kycVerified: true,
  birthCity: "Cerrik, ALB",
  firstName: "Ronaldo",
  passwordExpired: false,
  accountExpired: false,
  address: {
    id: 72,
    line2: "1314",
    postalCode: "112",
    line1: "Rruga Bilisht",
    city: "Kamëz",
    country: "AL",
    state: "Albania",
  },
  mobileNumber: "+355688109537",
  username: "+355688109537",
  termsConditionsSignedAt: "2023-02-02T10:28:26Z",
  name: "Ronaldo Cala",
  accountLocked: false,
  nationality: "AL",
  birthDate: "2003-08-27T22:00:00Z",
  lastName: "Cala",
  enabled: true,
  kycId: 248,
  personalNr: "123456789",
  email: "",
};

export const MockHomeData: {
  accounts: AccountProps[];
  kyc: number;
  hasPin: boolean;
  personDocuments: {
    documentType: string;
    name: string;
    id: number;
    contentType: string;
  }[];
} = {
  accounts: [
    {
      accountHolder: {
        name: "Business",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 12114.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_BUSINESS",
      currency: "ALL",
      id: 119,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 12614.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 118,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 26114.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 117,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 12611.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 116,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 126114, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 115,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 6114.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 114,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 14.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 113,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 1214.2, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 112,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 1261, id: 2638 },
        id: 1772,
        balanceType: "InterimAvailable",
      },
      iban: "AL53302110190000100000110100",
      accountType: "CHECKING_PERSONAL",
      currency: "ALL",
      id: 111,
    },
    {
      accountHolder: {
        name: "Ronaldo Cala",
        id: "ff80818185ab981301860211440f0004",
        kycVerified: true,
        mobileNumber: "+355688109537",
      },
      balance: {
        amount: { value: 0, id: 2637 },
        id: 1771,
        balanceType: "InterimAvailable",
      },
      iban: "AL87302110190000100000109100",
      accountType: "CHECKING_PERSONAL",
      currency: "EUR",
      id: 109,
    },
  ],
  kyc: 248,
  hasPin: true,
  personDocuments: [
    {
      documentType: "VIDEO_ID",
      name: "userID.mp4",
      id: 314,
      contentType: "video/mp4",
    },
    {
      documentType: "ID",
      name: "frontIDJ80828009A.png",
      id: 315,
      contentType: "image/png",
    },
    {
      documentType: "ID_BACK",
      name: "backIDJ80828009A.png",
      id: 316,
      contentType: "image/png",
    },
    {
      documentType: "SIGNATURE",
      name: "signatureJ80828009A.png",
      id: 317,
      contentType: "image/png",
    },
    {
      documentType: "DOCUMENT_PICTURE",
      name: "profileJ80828009A.png",
      id: 318,
      contentType: "image/png",
    },
    {
      documentType: "PROFILE",
      name: "profil.jpg",
      id: 319,
      contentType: "image/jpeg",
    },
  ],
};

export const MockCards = [
  {
    id: 9,
    expirationMonth: 6,
    lastFourDigits: 9992,
    cardType: "MASTER_CARD",
    customer: { id: "b18c052980f0d93e0180f0f7e002000d" },
    expirationYear: 2028,
    cardHolderName: "Ronaldo Cala",
    creditCardNumberHash: "a49023a97bf9753513b76d045d54e661",
    cvv: "574",
  },
  {
    id: 25,
    expirationMonth: 9,
    lastFourDigits: 5002,
    cardType: "VISA",
    customer: { id: "b18c052980f0d93e0180f0f7e002000d" },
    expirationYear: 2023,
    cardHolderName: "Ronaldo Cala",
    creditCardNumberHash: "8dc78786b2c3a3adf2a4b5654f5068ca",
    cvv: "687",
  },
  {
    id: 307,
    expirationMonth: 12,
    lastFourDigits: 18,
    cardType: "VISA",
    customer: { id: "b18c052980f0d93e0180f0f7e002000d" },
    expirationYear: 2026,
    cardHolderName: "Ronaldo Cala",
    creditCardNumberHash: "76e459ce47f18cd5f87f261c91055762",
    cvv: "914",
  },
];

export const MockBookings: Booking[] = [
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 0,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3131319,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331722,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331588,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 86,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 456,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 4574567,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 34634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 5611,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "",
    id: 45433,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "",
    id: 45735,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 3,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 33232,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3315559,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331733,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 3315888888,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 9758,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 36,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 56857567,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 634634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 756832,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 111897,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 765867,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 141435,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 3,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 2222111,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331755,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 3315089,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 56754,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 346346346346346,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 743563456,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 3112,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 24573,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 232424,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 79079,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 76987,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 3524,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 121243,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331767,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331542356,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 3456364,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 6,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 34634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 456788,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 27374,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 424242,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 35624,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 89,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 2435,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3314449,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 88,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 34253,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 6587989,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 4575686978978,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 6357468,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 789,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 24246,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 35353,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 231,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 475,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 4235,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 14124126666,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331799,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331553767,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 46354232,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 445,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 679,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 879678,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 445,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 46464,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 44,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 5254,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 75,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3319867,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331710,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 3315243564,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 331999991,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 9679,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 678356,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 45642523,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 45687,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 757575,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 53747,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 236,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 768,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3319699,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331712,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331535647586,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 9,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 67,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 24561346457656846789,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 53457,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 826,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 86868,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 687568,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 47677556867,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 789,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 331699,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331714,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331524536475,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 9,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 67,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 7834,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 648,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 28,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 79797,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 4567456,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 245354,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 234,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 346319,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 33171352,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 33151324356475,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 9,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 8,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 3,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 575634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 6458,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 80808,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 18951212,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 678568,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 568475,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 334319,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331734634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 33152453647,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 999897896,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 678678,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 6456765,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 61,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 3586,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 4645242,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 4124,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 45634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 876,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3354219,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 33174263,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331532435463576,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 97,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 67867969678,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 330743543535,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 2,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 867,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 65432,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 235464567,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 5234,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 43,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 366319,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331747464,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331534556,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 867,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 35634,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 35353535,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 4321424,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 8569,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 7654342,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 756857,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 746785684563464357,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 800 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike Me zbritje - Mastercard",
    id: 89,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:14:10Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Debit Card Personale - Fizike Me zbritje - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:14:10Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Fizike - Mastercard",
    id: 3523319,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T17:02:33Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Fizike - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T17:02:33Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 331711,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:57:15Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:57:15Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 0 },
    recipientName: "Komisione nga kontrata",
    description: "Debit Card Personale - Virtuale - Mastercard",
    id: 33158798,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-23T16:53:50Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Debit Card Personale - Virtuale - Mastercard",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:53:50Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
    id: 6578678678678,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:39:09Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1007-507623-1-1, (ref 4200920)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:39:09Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 262.8 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
    id: 3463,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:30:30Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 262.8 ALL, nr. kontrate: 433820-1, nr.fature: 2201-433820-1-1, (ref 4200919)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:30:30Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
    id: 335353634565656456,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:29:31Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1004-507623-1-1, (ref 4200918)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:29:31Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
    id: 534534,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:28:13Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1009-507623-1-1, (ref 4200917)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:28:13Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
    id: 3303,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:25:56Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200916)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:25:56Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 473 },
    recipientName: "Ujësjellës Kanalizime Tiranë",
    description:
      "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
    id: 3301,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291c8ef37000d/PROFILE",
    creationDate: "2023-03-23T16:24:27Z",
    recipientCustomer: {
      name: "Ujësjellës Kanalizime Tiranë",
      id: "f44a522f8291a7ab018291c8ef37000d",
      mobileNumber: "+3551234569B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation:
        "Pagesë utilitare për UKT në vlerën 473.0 ALL, nr. kontrate: 507623-1, nr.fature: 1012-507623-1-1, (ref 4200915)",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-23T16:24:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 1000 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2741,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:32:35Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:32:35Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 2739,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:31:22Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:31:22Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 22 },
    recipientName: "Komisione nga kontrata",
    description: "Product - This is a MasterCard Purchase",
    id: 38651364,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291a7d1f20004/PROFILE",
    creationDate: "2023-03-21T14:28:21Z",
    recipientCustomer: {
      name: "Komisione nga kontrata",
      id: "f44a522f8291a7ab018291a7d1f20004",
      mobileNumber: "+355696660031COMMISSIONB",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Product - This is a MasterCard Purchase",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-03-21T14:28:21Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 7566453,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:27Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 85678,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:16:06Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:16:06Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 10 },
    recipientName: "Julian Xhembulla",
    description: "message",
    id: 3464568744,
    avatar:
      "http://127.0.0.1:8580/thumbnail/f44a522f8291a7ab018291b113940007/PROFILE",
    creationDate: "2023-02-15T15:15:00Z",
    recipientCustomer: {
      name: "Julian Xhembulla",
      id: "f44a522f8291a7ab018291b113940007",
      mobileNumber: "+35569604569411",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "message",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-15T15:15:00Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1717,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T13:14:19Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T13:14:19Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
  {
    senderName: "Ronaldo Cala",
    amount: { value: 200 },
    recipientName: "Mulliri i vjeter",
    description: "Fatura 123",
    id: 1715,
    avatar:
      "http://127.0.0.1:8580/thumbnail/ff8081818442d788018442da82470000/PROFILE",
    creationDate: "2023-02-08T11:14:27Z",
    recipientCustomer: {
      name: "Mulliri i vjeter",
      id: "ff8081818442d788018442da82470000",
      mobileNumber: "+3556655443321B",
    },
    creditDebitIndicator: {
      enumType: "al.on.bank.core.enums.CreditDebitIndicator",
      name: "DEBIT",
    },
    transaction: {
      transactionInformation: "Fatura 123",
      creditDebitIndicator: {
        enumType: "al.on.bank.core.enums.CreditDebitIndicator",
        name: "DEBIT",
      },
      bookingDateTime: "2023-02-08T11:14:27Z",
    },
    senderCustomer: {
      name: "Ronaldo Cala",
      id: "ff80818185ab981301860211440f0004",
      mobileNumber: "+355688109537",
    },
  },
];

export const MockContacts = [
  {
    id: 92292,
    name: "Armand Brahaj",
    mobileNumber: "+355696660069",
    userId: "b18c052980f0d93e0180f0da5a740005",
  },
  {
    id: 13088,
    name: "Julian Xhembulla",
    mobileNumber: "+355696045694",
    userId: "b18c052980f0d93e0180f0db62d90006",
  },
  {
    id: 74093,
    name: "Blerta ",
    mobileNumber: "+355698771696",
    userId: "b18c052980f0d93e0180f0e07abf0007",
  },
  {
    id: 74108,
    name: "Dena Muca",
    mobileNumber: "+355695353124",
    userId: "b18c052980f0d93e0180f0efae310008",
  },
  {
    id: 13065,
    name: "Jurgen Hasbulla ",
    mobileNumber: "+355688030453",
    userId: "b18c052980f0d93e0180f0f0cec7000a",
  },
  {
    id: 13111,
    name: "Sebi ",
    mobileNumber: "+355697238215",
    userId: "b18c052980f0d93e0180f0f137e0000b",
  },
  {
    id: 13094,
    name: "Eno Kotmilo ",
    mobileNumber: "+355695800126",
    userId: "b18c052980f0d93e0180f0f3d6f7000c",
  },
  {
    id: 12968,
    name: "Ronaldo Cala",
    mobileNumber: "+355688109537",
    userId: "b18c052980f0d93e0180f0f7e002000d",
  },
  {
    id: 13114,
    name: "Fabio Qafa",
    mobileNumber: "+355696040999",
    userId: "b18c052980f0d93e0180f0f949f9000e",
  },
  {
    id: 13096,
    name: "Gent Dekovi ",
    mobileNumber: "+355693579284",
    userId: "b18c052980f0d93e0180f1033164000f",
  },
  {
    id: 13090,
    name: "Jonida Lazri",
    mobileNumber: "+355696148476",
    userId: "b18c052980f0d93e0180f539b726001e",
  },
  {
    id: 13118,
    name: "Avis Admin Rubicon ",
    mobileNumber: "+355694054699",
    userId: "b18c052980f0d93e0180f5e4b6f8001f",
  },
  {
    id: 186728,
    name: "Altin Leksani",
    mobileNumber: "+355682035889",
    userId: "b18c052980f0d93e0180f7314cb00027",
  },
  {
    id: 12947,
    name: "Marsi 💍 ",
    mobileNumber: "+355692408192",
    userId: "b18c0529814dba4301815c3a3178000e",
  },
  {
    id: 13074,
    name: "Bora Rubikon ",
    mobileNumber: "+355695827798",
    userId: "b18c0529814dba4301815c54cada000f",
  },
  {
    id: 13057,
    name: "Julia ",
    mobileNumber: "+4915752460093",
    userId: "b18c0529814dba4301816d89671b0022",
  },
  {
    id: 13072,
    name: "Edmir Puna ",
    mobileNumber: "+355682084104",
    userId: "b18c0529814dba43018191c748110041",
  },
  {
    id: 12994,
    name: "Adi 🍆 ",
    mobileNumber: "+355692253427",
    userId: "b18c0529814dba430181a080b44a004b",
  },
  {
    id: 13019,
    name: "Maleni ",
    mobileNumber: "+355694673359",
    userId: "b18c0529814dba430181ae9396240050",
  },
  {
    id: 91400,
    name: "Andi Topciu",
    mobileNumber: "+355683548551",
    userId: "b18c052981b5564c0181c87c5432000c",
  },
  {
    id: 74112,
    name: "Anisa Kapri",
    mobileNumber: "+355693179633",
    userId: "b18c052981b5564c0181ed6e17d70027",
  },
  {
    id: 13102,
    name: "Besi Mandit ",
    mobileNumber: "+355693493678",
    userId: "b18c052981b5564c018249c66a4f00ac",
  },
  {
    id: 13061,
    name: "Arjeta Finance ",
    mobileNumber: "+355694087420",
    userId: "b18c052981b5564c018263dae02700d0",
  },
  {
    id: 103375,
    name: "Iris Tela",
    mobileNumber: "+355682040149",
    userId: "b18c052981b5564c018337a73dfe0188",
  },
  {
    id: 13128,
    name: "Isidora Gentit ",
    mobileNumber: "+355695447104",
    userId: "b18c052981b5564c018388e72e1001dd",
  },
  {
    id: 74117,
    name: "Ernida Kraja",
    mobileNumber: "+355673753231",
    userId: "b18c052983a3a92a0183a8078ae9000a",
  },
  {
    id: 133400,
    name: "Kejsi Kellezi",
    mobileNumber: "+355697779759",
    userId: "b18c0529840a331601843dd315170076",
  },
  {
    id: 12986,
    name: "Renato ",
    mobileNumber: "+355686501186",
    userId: "b18c0529840a331601847ab8ff8100ff",
  },
  {
    id: 13027,
    name: "Ali Procodev ",
    mobileNumber: "+355682728318",
    userId: "b18c0529840a331601847ac1ec9e0102",
  },
  {
    id: 12982,
    name: "Kosta ",
    mobileNumber: "+355672222260",
    userId: "b18c0529840a331601847ad5e4670106",
  },
  {
    id: 13006,
    name: "Erindi ❤️vogel ",
    mobileNumber: "+355696650460",
    userId: "b18c0529840a331601847b16b095010c",
  },
  {
    id: 12942,
    name: "Byrazeri ",
    mobileNumber: "+355682971065",
    userId: "b18c0529840a331601847b7beae40111",
  },
  {
    id: 12998,
    name: "Vangj Puntori ",
    mobileNumber: "+355676379956",
    userId: "b18c0529840a331601847c0b434e0115",
  },
  {
    id: 12966,
    name: "Olii ❤️🦧 ",
    mobileNumber: "+355685203708",
    userId: "b18c0529840a331601847c865fe00118",
  },
  {
    id: 13026,
    name: "Serpente Suprem ",
    mobileNumber: "+355696751914",
    userId: "b18c0529840a33160184ca7718b101eb",
  },
  {
    id: 13059,
    name: "Orkida ",
    mobileNumber: "+355688844612",
    userId: "b18c0529852b37110185393cd6b40017",
  },
  {
    id: 13038,
    name: "Franko ",
    mobileNumber: "+355675878888",
    userId: "b18c0529852b3711018552c5eced003b",
  },
  {
    id: 186723,
    name: "Merlind ",
    mobileNumber: "+355696784012",
    userId: "b18c0529852b37110185dfbbd8560237",
  },
  {
    id: 13032,
    name: "Angelo Fredi ",
    mobileNumber: "+355684699771",
    userId: "b18c0529852b37110185e9f591b1024e",
  },
  {
    id: 13120,
    name: "15 Maj Ksamil ",
    mobileNumber: "+355692073321",
    userId: "null",
  },
  {
    id: 74103,
    name: "3i Solutions ",
    mobileNumber: "+355694016190",
    userId: "null",
  },
  {
    id: 186732,
    name: "A3 Bradashesh ",
    mobileNumber: "+355692382722",
    userId: "null",
  },
  {
    id: 12989,
    name: "Adela ",
    mobileNumber: "+355688830395",
    userId: "null",
  },
  {
    id: 13054,
    name: "Agjensia Sr-Tr ",
    mobileNumber: "+355695873466",
    userId: "null",
  },
  {
    id: 13078,
    name: "Agjensia Tr-Sr ",
    mobileNumber: "+355694042235",
    userId: "null",
  },
  {
    id: 13089,
    name: "Agjensi Imobilare Programim ",
    mobileNumber: "+355682546747",
    userId: "null",
  },
  {
    id: 133397,
    name: "Agjent Shok Kevit ",
    mobileNumber: "+355692092244",
    userId: "null",
  },
  {
    id: 13024,
    name: "Alba ",
    mobileNumber: "+355693717871",
    userId: "null",
  },
  {
    id: 13121,
    name: "Alberti Brunes ",
    mobileNumber: "+355698343750",
    userId: "null",
  },
  {
    id: 13014,
    name: "Alesio ",
    mobileNumber: "+355698644787",
    userId: "null",
  },
  {
    id: 74095,
    name: "Alesio Visit ",
    mobileNumber: "+355695417073",
    userId: "null",
  },
  {
    id: 13296,
    name: "Ali ",
    mobileNumber: "+355683221047",
    userId: "null",
  },
  {
    id: 13028,
    name: "Ali Procodev ",
    mobileNumber: "+355699435063",
    userId: "null",
  },
  {
    id: 13062,
    name: "Alketa TiranaBank",
    mobileNumber: "+355692145650",
    userId: "null",
  },
  {
    id: 13048,
    name: "Altini Berberi 💇🏻‍♂️ ",
    mobileNumber: "+355699712921",
    userId: "null",
  },
  {
    id: 12963,
    name: "Amarildo ",
    mobileNumber: "+355675829263",
    userId: "null",
  },
  {
    id: 103377,
    name: "Amela Fahriu",
    mobileNumber: "+355699863900",
    userId: "null",
  },
  {
    id: 12978,
    name: "Andrea ",
    mobileNumber: "+355697375707",
    userId: "null",
  },
  { id: 12977, name: "Andrea ", mobileNumber: "+447308512289", userId: "null" },
  {
    id: 186724,
    name: "Andrea Beko",
    mobileNumber: "+447861067211",
    userId: "null",
  },
  {
    id: 186727,
    name: "Anduel Faria",
    mobileNumber: "+355682001637",
    userId: "null",
  },
  {
    id: 13009,
    name: "Andueli ",
    mobileNumber: "+355685054544",
    userId: "null",
  },
  { id: 13003, name: "Ani ", mobileNumber: "+355698486040", userId: "null" },
  {
    id: 148453,
    name: "Anna Haro",
    mobileNumber: "+3555555228243",
    userId: "null",
  },
  { id: 12995, name: "Arberi ", mobileNumber: "+355696753054", userId: "null" },
  {
    id: 13085,
    name: "Arjola  Llaha",
    mobileNumber: "+355696740841",
    userId: "null",
  },
  {
    id: 74091,
    name: "Armand Brahaj",
    mobileNumber: "+4917643439006",
    userId: "null",
  },
  {
    id: 13127,
    name: "Armind Landmark ",
    mobileNumber: "+355684041653",
    userId: "null",
  },
  {
    id: 13092,
    name: "Arsen Edes ",
    mobileNumber: "+355692595819",
    userId: "null",
  },
  {
    id: 13035,
    name: "Asion ",
    mobileNumber: "+355692505762",
    userId: "null",
  },
  { id: 12997, name: "Aurel ", mobileNumber: "+355685064746", userId: "null" },
  {
    id: 13010,
    name: "Aurora ",
    mobileNumber: "+355697484483",
    userId: "null",
  },
  {
    id: 12992,
    name: "Babi ❤️ ",
    mobileNumber: "+306986710044",
    userId: "null",
  },
  {
    id: 12991,
    name: "Babi ❤️ ",
    mobileNumber: "+355674409592",
    userId: "null",
  },
  { id: 13049, name: "Bana ", mobileNumber: "+355672387435", userId: "null" },
  {
    id: 133396,
    name: "Basement Pizza ",
    mobileNumber: "+355696666127",
    userId: "null",
  },
  {
    id: 13039,
    name: "Bistro 🍕🌯 ",
    mobileNumber: "+355693378333",
    userId: "null",
  },
  {
    id: 13040,
    name: "Bistro 🍕🌯 ",
    mobileNumber: "+355693378400",
    userId: "null",
  },
  { id: 12971, name: "Bledi ", mobileNumber: "+355699806716", userId: "null" },
  {
    id: 12979,
    name: "Bruno ",
    mobileNumber: "+355675881039",
    userId: "null",
  },
  {
    id: 12981,
    name: "Bujar Taxi ",
    mobileNumber: "+355692301725",
    userId: "null",
  },
  {
    id: 74107,
    name: "Cufo Cerrik ",
    mobileNumber: "+355675856246",
    userId: "null",
  },
  {
    id: 12950,
    name: "Daja Festi ",
    mobileNumber: "+355696624881",
    userId: "null",
  },
  {
    id: 12951,
    name: "Dajana ",
    mobileNumber: "+355684115213",
    userId: "null",
  },
  {
    id: 13036,
    name: "Daja Pllumi ",
    mobileNumber: "+355674335211",
    userId: "null",
  },
  {
    id: 148448,
    name: "Daniel Higgins",
    mobileNumber: "+3555554787672",
    userId: "null",
  },
  {
    id: 148449,
    name: "Daniel Higgins",
    mobileNumber: "+3554085555270",
    userId: "null",
  },
  {
    id: 148450,
    name: "Daniel Higgins",
    mobileNumber: "+3554085553514",
    userId: "null",
  },
  {
    id: 74092,
    name: "Daniel Sino ",
    mobileNumber: "+355692440090",
    userId: "null",
  },
  {
    id: 148456,
    name: "David Taylor",
    mobileNumber: "+3555556106679",
    userId: "null",
  },
  {
    id: 12969,
    name: "Dejvi 🎮 ",
    mobileNumber: "+355685754646",
    userId: "null",
  },
  {
    id: 12984,
    name: "Deni ",
    mobileNumber: "+355692238860",
    userId: "null",
  },
  {
    id: 12964,
    name: "Denis Balla",
    mobileNumber: "+355698918017",
    userId: "null",
  },
  {
    id: 13015,
    name: "Denisi Banes ",
    mobileNumber: "+355682001773",
    userId: "null",
  },
  {
    id: 12941,
    name: "Denisi Luniku ",
    mobileNumber: "+355685944915",
    userId: "null",
  },
  {
    id: 13087,
    name: "Dental Mondi ",
    mobileNumber: "+355692097616",
    userId: "null",
  },
  {
    id: 13004,
    name: "Dioncja ",
    mobileNumber: "+355697589203",
    userId: "null",
  },
  {
    id: 13112,
    name: "division5 Hr ",
    mobileNumber: "+355676857951",
    userId: "null",
  },
  {
    id: 13098,
    name: "Dorian Tole Agjensi ",
    mobileNumber: "+355684549170",
    userId: "null",
  },
  {
    id: 12973,
    name: "Driona ",
    mobileNumber: "+355686067671",
    userId: "null",
  },
  {
    id: 13066,
    name: "Eda Marsit ",
    mobileNumber: "+355696872806",
    userId: "null",
  },
  {
    id: 12996,
    name: "Edi Iljazi ",
    mobileNumber: "+355684039240",
    userId: "null",
  },
  {
    id: 12983,
    name: "Egli 🧔🏻 ",
    mobileNumber: "+355693328204",
    userId: "null",
  },
  {
    id: 13125,
    name: "Elba Landmark ",
    mobileNumber: "+355673756083",
    userId: "null",
  },
  { id: 12999, name: "Elda ", mobileNumber: "+355699262277", userId: "null" },
  {
    id: 12961,
    name: "Eldo ",
    mobileNumber: "+355675353333",
    userId: "null",
  },
  {
    id: 13081,
    name: "Elektroshtepiake Xhamllik ",
    mobileNumber: "+355675550904",
    userId: "null",
  },
  {
    id: 186725,
    name: "Elena Doko",
    mobileNumber: "+355698484191",
    userId: "null",
  },
  {
    id: 12957,
    name: "Elvia ",
    mobileNumber: "+355694531313",
    userId: "null",
  },
  { id: 12960, name: "Elvira ", mobileNumber: "+355697890327", userId: "null" },
  {
    id: 12974,
    name: "Endi ",
    mobileNumber: "+355684428714",
    userId: "null",
  },
  { id: 13064, name: "Endri ", mobileNumber: "+355692568805", userId: "null" },
  {
    id: 13011,
    name: "Enkea ",
    mobileNumber: "+355697716859",
    userId: "null",
  },
  {
    id: 13030,
    name: "Enxhi Xhaja",
    mobileNumber: "+355695250955",
    userId: "null",
  },
  {
    id: 13108,
    name: "Eri Mekanik Kamez ",
    mobileNumber: "+355685747984",
    userId: "null",
  },
  {
    id: 13123,
    name: "Erind Puna ",
    mobileNumber: "+355694043910",
    userId: "null",
  },
  {
    id: 12975,
    name: "Erisa ",
    mobileNumber: "+355686029793",
    userId: "null",
  },
  { id: 12976, name: "Erisa ", mobileNumber: "+355696276527", userId: "null" },
  {
    id: 13052,
    name: "Ermali Kushuriri ",
    mobileNumber: "+355682285982",
    userId: "null",
  },
  {
    id: 12993,
    name: "Ertil Dhembi",
    mobileNumber: "+355684690004",
    userId: "null",
  },
  {
    id: 13107,
    name: "Ertil Dhembi ",
    mobileNumber: "+355685439787",
    userId: "null",
  },
  {
    id: 13008,
    name: "Estrela ",
    mobileNumber: "+355698749220",
    userId: "null",
  },
  {
    id: 12954,
    name: "Eva ",
    mobileNumber: "+4915736556704",
    userId: "null",
  },
  {
    id: 13080,
    name: "Evi Dedja ",
    mobileNumber: "+355697319100",
    userId: "null",
  },
  {
    id: 12953,
    name: "Evina ",
    mobileNumber: "+355697778181",
    userId: "null",
  },
  { id: 13012, name: "Fabio ", mobileNumber: "+393298743473", userId: "null" },
  {
    id: 13116,
    name: "Fiesta Elbasan ",
    mobileNumber: "+355682647590",
    userId: "null",
  },
  {
    id: 13115,
    name: "Fiesta Zez ",
    mobileNumber: "+355695545525",
    userId: "null",
  },
  {
    id: 13126,
    name: "Fisnik KRUJA ",
    mobileNumber: "+355672067270",
    userId: "null",
  },
  {
    id: 12958,
    name: "Flamuri ",
    mobileNumber: "+355697889512",
    userId: "null",
  },
  {
    id: 13063,
    name: "Flori ",
    mobileNumber: "+355693430844",
    userId: "null",
  },
  {
    id: 13110,
    name: "Ford Bardh 2011 Astir ",
    mobileNumber: "+355695779",
    userId: "null",
  },
  {
    id: 12959,
    name: "Fribi ",
    mobileNumber: "+355695862530",
    userId: "null",
  },
  {
    id: 13005,
    name: "Fugoni Sr-Cr ",
    mobileNumber: "+355696322979",
    userId: "null",
  },
  {
    id: 74105,
    name: "Fund Gusht-shtator ",
    mobileNumber: "+355693369366",
    userId: "null",
  },
  {
    id: 103374,
    name: "Gary White",
    mobileNumber: "+355688359370",
    userId: "null",
  },
  {
    id: 13056,
    name: "Gazment Suli",
    mobileNumber: "+355696263319",
    userId: "null",
  },
  {
    id: 13106,
    name: "Genti Cala",
    mobileNumber: "+355693677100",
    userId: "null",
  },
  {
    id: 12965,
    name: "Geri ",
    mobileNumber: "+355684819528",
    userId: "null",
  },
  {
    id: 186720,
    name: "Geri Berberi ",
    mobileNumber: "+35567334636655",
    userId: "null",
  },
  {
    id: 13095,
    name: "Gjeloshi ",
    mobileNumber: "+35555555555555",
    userId: "null",
  },
  {
    id: 13100,
    name: "Gruja Ilirit ",
    mobileNumber: "+355693715574",
    userId: "null",
  },
  {
    id: 13001,
    name: "Gysi Mekaniksi ",
    mobileNumber: "+355686751191",
    userId: "null",
  },
  {
    id: 12988,
    name: "Halla Flutra ",
    mobileNumber: "+355676326839",
    userId: "null",
  },
  {
    id: 13082,
    name: "Halla Shqiponja ",
    mobileNumber: "+355688456277",
    userId: "null",
  },
  {
    id: 148455,
    name: "Hank Zakroff",
    mobileNumber: "+3557075551854",
    userId: "null",
  },
  {
    id: 148454,
    name: "Hank Zakroff",
    mobileNumber: "+3555557664823",
    userId: "null",
  },
  {
    id: 74110,
    name: "Harnold  Mecaj Tattoo",
    mobileNumber: "+355675654734",
    userId: "null",
  },
  {
    id: 12940,
    name: "Hergysi ",
    mobileNumber: "+355697658726",
    userId: "null",
  },
  {
    id: 133398,
    name: "Hotel Qensh 10 Mij ",
    mobileNumber: "+355688396902",
    userId: "null",
  },
  {
    id: 133399,
    name: "Hotel Qensh Shkencat ",
    mobileNumber: "+355694069144",
    userId: "null",
  },
  { id: 13053, name: "Igli ", mobileNumber: "+355672379243", userId: "null" },
  {
    id: 13099,
    name: "Ilir Shpia  ",
    mobileNumber: "+355694116360",
    userId: "null",
  },
  { id: 12955, name: "Indrit ", mobileNumber: "+355676188081", userId: "null" },
  {
    id: 74096,
    name: "Internet Cerrik ",
    mobileNumber: "+355698598988",
    userId: "null",
  },
  {
    id: 13029,
    name: "Irena Banakierja ",
    mobileNumber: "+355695757984",
    userId: "null",
  },
  {
    id: 74118,
    name: "irielle Usa ",
    mobileNumber: "+14084625110",
    userId: "null",
  },
  { id: 13047, name: "Isufi ", mobileNumber: "+355696113280", userId: "null" },
  {
    id: 12970,
    name: "Iza ",
    mobileNumber: "+355688374448",
    userId: "null",
  },
  { id: 186731, name: "Iza ", mobileNumber: "+355696853486", userId: "null" },
  {
    id: 148451,
    name: "John Appleseed",
    mobileNumber: "+3558885555512",
    userId: "null",
  },
  {
    id: 148452,
    name: "John Appleseed",
    mobileNumber: "+3558885551212",
    userId: "null",
  },
  {
    id: 74114,
    name: "Kamera Cerrik ",
    mobileNumber: "+355696118520",
    userId: "null",
  },
  {
    id: 148446,
    name: "Kate Bell",
    mobileNumber: "+3555555648583",
    userId: "null",
  },
  {
    id: 148447,
    name: "Kate Bell",
    mobileNumber: "+3554155553695",
    userId: "null",
  },
  {
    id: 74116,
    name: "Kleanth Baku",
    mobileNumber: "+355688459755",
    userId: "null",
  },
  {
    id: 12980,
    name: "Kleo ",
    mobileNumber: "+355675872753",
    userId: "null",
  },
  {
    id: 13007,
    name: "Klevisi ",
    mobileNumber: "+355699229878",
    userId: "null",
  },
  {
    id: 13117,
    name: "Kostum Qera Elbasan ",
    mobileNumber: "+355677507007",
    userId: "null",
  },
  {
    id: 12944,
    name: "Kristel ",
    mobileNumber: "+355675108577",
    userId: "null",
  },
  {
    id: 13103,
    name: "Kristi Hoxholli ",
    mobileNumber: "+355696671950",
    userId: "null",
  },
  {
    id: 74106,
    name: "Ksamil 10 Gusht ",
    mobileNumber: "+306993887681",
    userId: "null",
  },
  {
    id: 74094,
    name: "Ksamil 1 Korrik ",
    mobileNumber: "+355685610508",
    userId: "null",
  },
  {
    id: 74101,
    name: "Ksamil Cift ",
    mobileNumber: "+355692330200",
    userId: "null",
  },
  {
    id: 74102,
    name: "Kunati Festit Halles ",
    mobileNumber: "+355694564187",
    userId: "null",
  },
  { id: 13034, name: "Laerti ", mobileNumber: "+355695788676", userId: "null" },
  {
    id: 13122,
    name: "Landmark Flutter ",
    mobileNumber: "+355696172809",
    userId: "null",
  },
  {
    id: 13079,
    name: "Landmark Hr ",
    mobileNumber: "+355693509351",
    userId: "null",
  },
  {
    id: 12952,
    name: "Ledja Ing Inf ",
    mobileNumber: "+355674927746",
    userId: "null",
  },
  { id: 13058, name: "Lion ", mobileNumber: "+355688416464", userId: "null" },
  {
    id: 13297,
    name: "Lion  Bega",
    mobileNumber: "+355694603741",
    userId: "null",
  },
  {
    id: 12962,
    name: "Lisander ",
    mobileNumber: "+355675432381",
    userId: "null",
  },
  {
    id: 12949,
    name: "Lola ",
    mobileNumber: "+355672491843",
    userId: "null",
  },
  {
    id: 13101,
    name: "Majlinda Rruga Durrsit ",
    mobileNumber: "+355694101376",
    userId: "null",
  },
  {
    id: 13093,
    name: "Majlinda Shtermen ",
    mobileNumber: "+355693537479",
    userId: "null",
  },
  { id: 13018, name: "Maleni ", mobileNumber: "+355695386648", userId: "null" },
  {
    id: 13044,
    name: "Malvina ",
    mobileNumber: "+355674978709",
    userId: "null",
  },
  {
    id: 12967,
    name: "Mami ❤️ ",
    mobileNumber: "+355674409593",
    userId: "null",
  },
  {
    id: 13022,
    name: "Mandi ",
    mobileNumber: "+355695709752",
    userId: "null",
  },
  {
    id: 13002,
    name: "Mandi Irelia ",
    mobileNumber: "+355683191505",
    userId: "null",
  },
  {
    id: 74100,
    name: "Marangoz Landi ",
    mobileNumber: "+355692912005",
    userId: "null",
  },
  {
    id: 186719,
    name: "Mariela Kacani ",
    mobileNumber: "+355697016022",
    userId: "null",
  },
  {
    id: 13097,
    name: "Mariela Kacani ",
    mobileNumber: "+355696309809",
    userId: "null",
  },
  {
    id: 13033,
    name: "Mario 🥳🕹 ",
    mobileNumber: "+355697998299",
    userId: "null",
  },
  {
    id: 13037,
    name: "Marvin ",
    mobileNumber: "+355693760287",
    userId: "null",
  },
  { id: 13105, name: "Megi ", mobileNumber: "+355676072030", userId: "null" },
  {
    id: 74111,
    name: "Mekanik Yzberisht ",
    mobileNumber: "+355695267401",
    userId: "null",
  },
  {
    id: 13075,
    name: "Mensa Essenza ",
    mobileNumber: "+355698654400",
    userId: "null",
  },
  {
    id: 13076,
    name: "Mensa Essenza ",
    mobileNumber: "+355692044477",
    userId: "null",
  },
  {
    id: 13067,
    name: "Mensa Grand ",
    mobileNumber: "+355695566000",
    userId: "null",
  },
  {
    id: 13113,
    name: "Mini Zez Kuq ",
    mobileNumber: "+355684856186",
    userId: "null",
  },
  { id: 74113, name: "Mirdon ", mobileNumber: "+355684151224", userId: "null" },
  {
    id: 12946,
    name: "Miri Fugoni Cerrikut ",
    mobileNumber: "+355674482724",
    userId: "null",
  },
  {
    id: 74098,
    name: "Nena E Marsit ",
    mobileNumber: "+355694423792",
    userId: "null",
  },
  {
    id: 13050,
    name: "Nena Shirja ",
    mobileNumber: "+355675545735",
    userId: "null",
  },
  { id: 12985, name: "Niqeta ", mobileNumber: "+355682397974", userId: "null" },
  {
    id: 13109,
    name: "Norbert ",
    mobileNumber: "+4915115268590",
    userId: "null",
  },
  {
    id: 13045,
    name: "Nr Shpis ",
    mobileNumber: "+355058123787",
    userId: "null",
  },
  {
    id: 13041,
    name: "Olesa ",
    mobileNumber: "+355676661061",
    userId: "null",
  },
  { id: 13023, name: "Papi ", mobileNumber: "+355695709895", userId: "null" },
  {
    id: 13083,
    name: "Petraq Interneti Cerrik ",
    mobileNumber: "+355672015140",
    userId: "null",
  },
  {
    id: 103373,
    name: "Poliklinika Bllok ",
    mobileNumber: "+355692044796",
    userId: "null",
  },
  {
    id: 13031,
    name: "Qemali ",
    mobileNumber: "+355695606147",
    userId: "null",
  },
  { id: 13043, name: "Rea ", mobileNumber: "+355676115854", userId: "null" },
  {
    id: 13042,
    name: "Rea ",
    mobileNumber: "+355675698748",
    userId: "null",
  },
  { id: 13051, name: "Redi ", mobileNumber: "+355695816404", userId: "null" },
  {
    id: 103376,
    name: "Redion Allaraj",
    mobileNumber: "+355694476466",
    userId: "null",
  },
  {
    id: 13070,
    name: "Resmi Marsit ",
    mobileNumber: "+355698971717",
    userId: "null",
  },
  {
    id: 13060,
    name: "Restorant Etjoni ",
    mobileNumber: "+355676681200",
    userId: "null",
  },
  {
    id: 13124,
    name: "Rexhino Landmark ",
    mobileNumber: "+355684124477",
    userId: "null",
  },
  {
    id: 13000,
    name: "Rezi ",
    mobileNumber: "+355674948958",
    userId: "null",
  },
  { id: 12990, name: "Rina ", mobileNumber: "+355699355059", userId: "null" },
  {
    id: 13021,
    name: "Romeo ",
    mobileNumber: "+355685718747",
    userId: "null",
  },
  {
    id: 13073,
    name: "Sali Qera Karakushi",
    mobileNumber: "+355692263334",
    userId: "null",
  },
  {
    id: 13069,
    name: "Santi ",
    mobileNumber: "+355694812404",
    userId: "null",
  },
  { id: 13068, name: "Santi ", mobileNumber: "+491707417105", userId: "null" },
  {
    id: 186718,
    name: "Santi ",
    mobileNumber: "+355682835770",
    userId: "null",
  },
  {
    id: 12948,
    name: "Sarah 🍥 ",
    mobileNumber: "+355686490936",
    userId: "null",
  },
  {
    id: 13077,
    name: "Senada ",
    mobileNumber: "+355694677853",
    userId: "null",
  },
  {
    id: 12956,
    name: "Sherlin ",
    mobileNumber: "+355698739240",
    userId: "null",
  },
  {
    id: 186722,
    name: "Shije Shpie ",
    mobileNumber: "+355692359579",
    userId: "null",
  },
  {
    id: 12945,
    name: "Sidorela ",
    mobileNumber: "+355695659475",
    userId: "null",
  },
  {
    id: 74097,
    name: "Sisal Elidona ",
    mobileNumber: "+355694011199",
    userId: "null",
  },
  {
    id: 74099,
    name: "Sopoti Lejlaj ",
    mobileNumber: "+355699699834",
    userId: "null",
  },
  {
    id: 13046,
    name: "Spirioni ",
    mobileNumber: "+355697215173",
    userId: "null",
  },
  {
    id: 13020,
    name: "Stela Regjizorja ",
    mobileNumber: "+355692543380",
    userId: "null",
  },
  {
    id: 13084,
    name: "Street Food ",
    mobileNumber: "+355686066612",
    userId: "null",
  },
  { id: 12943, name: "Tau ", mobileNumber: "+355699839576", userId: "null" },
  {
    id: 13071,
    name: "Taxi ",
    mobileNumber: "+35508005555",
    userId: "null",
  },
  {
    id: 74104,
    name: "Teki Kolaneci ",
    mobileNumber: "+355692224104",
    userId: "null",
  },
  {
    id: 186729,
    name: "Tekniku Sopotit ",
    mobileNumber: "+355682923106",
    userId: "null",
  },
  {
    id: 186721,
    name: "Tekstile Tregu Çam ",
    mobileNumber: "+355674854795",
    userId: "null",
  },
  {
    id: 12972,
    name: "Tili ",
    mobileNumber: "+355697119383",
    userId: "null",
  },
  {
    id: 187267,
    name: "Vangjo ",
    mobileNumber: "+355692156997",
    userId: "null",
  },
  {
    id: 133395,
    name: "Veterinaria Liqeni ",
    mobileNumber: "+355672391111",
    userId: "null",
  },
  {
    id: 13091,
    name: "Veteriner ",
    mobileNumber: "+355695273262",
    userId: "null",
  },
  {
    id: 74109,
    name: "Vila Panorama Tjeter ",
    mobileNumber: "+355697495198",
    userId: "null",
  },
  {
    id: 74115,
    name: "Vini Sky Restaurant ",
    mobileNumber: "+355682055098",
    userId: "null",
  },
  {
    id: 13025,
    name: "Visi Baba ",
    mobileNumber: "+355684804112",
    userId: "null",
  },
  {
    id: 13016,
    name: "Xhaxhi Serveti ",
    mobileNumber: "+355682590120",
    userId: "null",
  },
  {
    id: 12987,
    name: "Xhaxhi Shefi ",
    mobileNumber: "+355682757630",
    userId: "null",
  },
  {
    id: 13017,
    name: "Xhaxhi Syri ",
    mobileNumber: "+355697402038",
    userId: "null",
  },
  {
    id: 186726,
    name: "Xhevi Cala",
    mobileNumber: "+355696811032",
    userId: "null",
  },
  {
    id: 13119,
    name: "Xhuliano ",
    mobileNumber: "+355675707246",
    userId: "null",
  },
  {
    id: 186730,
    name: "Ylli Hidrauliku ",
    mobileNumber: "+355682767165",
    userId: "null",
  },
  { id: 13104, name: "Zoi ", mobileNumber: "+355692201906", userId: "null" },
  {
    id: 13013,
    name: "Zysh Mariola ",
    mobileNumber: "+355694004488",
    userId: "null",
  },
];

export const MockContracts: Contract[] = [
  {
    id: 9199,
    contractNo: null,
    vlera: "DG-22-3796-R",
    customerId: "b18c052980f0d93e0180f0f7e002000d",
    doNotify: 1,
    operatorId: 25,
    lastInvoiceDate: "2022-12-21T08:33:45Z",
  },
  {
    id: 9197,
    contractNo: null,
    vlera: "TR2E120034080401",
    customerId: "b18c052980f0d93e0180f0f7e002000d",
    doNotify: 1,
    operatorId: 24,
    lastInvoiceDate: "2022-12-21T08:24:08Z",
  },
  {
    id: 38347,
    contractNo: null,
    vlera: "EL0C140005075404",
    customerId: "b18c052980f0d93e0180f0f7e002000d",
    doNotify: 1,
    operatorId: 24,
    lastInvoiceDate: "2023-06-19T09:49:49Z",
  },
];

export const MockUtilityBills = [
  {
    month: "06",
    month_human: "Qershor 2022",
    serial_number: "273964401",
    customer_name: "Dionis Luta",
    customer_adress: "Rruga Ismail Qemali",
    document_date: "2022-06-28 00:00:00",
    due_date: "2022-07-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    amount_payed: "0",
    debt: "15800.4",
    nivf: "",
    nslf: "",
    nuis: "",
    Seria: "123",
    Kamata: "1230",
    Vlera: "4560",
    MjetiPronari: "Roni",
    MjetiMarka: "Toyota",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "AA 2345 BB",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "05",
    month_human: "Maj 2022",
    serial_number: "273962212",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2022-05-28 00:00:00",
    due_date: "2022-06-28 00:00:00",
    total_no_vat: "386",
    total_vat: "77.2",
    total_with_vat: "463.2",
    amount_payed: "0",
    debt: "509.52",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "04",
    month_human: "Prill 2022",
    serial_number: "273954207",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2022-04-28 00:00:00",
    due_date: "2022-05-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "03",
    month_human: "Mars 2022",
    serial_number: "273948121",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2022-03-28 00:00:00",
    due_date: "2022-04-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "02",
    month_human: "Shkurt 2022",
    serial_number: "273943547",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2022-02-28 00:00:00",
    due_date: "2022-03-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "01",
    month_human: "Janar 2022",
    serial_number: "273937419",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2022-01-28 00:00:00",
    due_date: "2022-02-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "12",
    month_human: "Dhjetor 2021",
    serial_number: "273930527",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-12-28 00:00:00",
    due_date: "2022-01-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "11",
    month_human: "Nentor 2021",
    serial_number: "273921180",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-11-28 00:00:00",
    due_date: "2021-12-28 00:00:00",
    total_no_vat: "234",
    total_vat: "46.8",
    total_with_vat: "280.8",
    total_interest_overdue: "28.08",
    amount_payed: "0",
    debt: "308.88",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "10",
    month_human: "Tetor 2021",
    serial_number: "273913306",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-10-28 00:00:00",
    due_date: "2021-11-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "09",
    month_human: "Shtator 2021",
    serial_number: "273910313",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-09-28 00:00:00",
    due_date: "2021-10-28 00:00:00",
    total_no_vat: "158",
    total_vat: "31.6",
    total_with_vat: "189.6",
    total_interest_overdue: "4.56",
    amount_payed: "144",
    debt: "50.16",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "08",
    month_human: "Gusht 2021",
    serial_number: "273901123",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-08-28 00:00:00",
    due_date: "2021-09-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "07",
    month_human: "Korrik 2021",
    serial_number: "273897044",
    customer_name: "DIONIS LUTA",
    customer_adress: "",
    document_date: "2021-07-28 00:00:00",
    due_date: "2021-08-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "06",
    month_human: "Qershor 2021",
    serial_number: "273890588",
    customer_name: "Dionis Luta",
    customer_adress: "",
    document_date: "2021-06-28 00:00:00",
    due_date: "2021-07-28 00:00:00",
    total_no_vat: "120",
    total_vat: "24",
    total_with_vat: "144",
    total_interest_overdue: "14.4",
    amount_payed: "0",
    debt: "158.4",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
  {
    month: "05",
    month_human: "Maj 2021",
    serial_number: "273883329",
    customer_name: "Dionis Luta",
    customer_adress: "",
    document_date: "2021-05-28 00:00:00",
    due_date: "2021-06-28 00:00:00",
    total_no_vat: "158",
    total_vat: "31.6",
    total_with_vat: "189.6",
    total_interest_overdue: "14.42",
    amount_payed: "45.4",
    debt: "158.62",
    nivf: "",
    nslf: "",
    nuis: "",
    contract: {
      id: 7038,
      contractNo: null,
      vlera: "007598",
      customerId: "f44a522f82a0b0440182c49547760006",
      doNotify: 1,
      operatorId: 32,
      lastInvoiceDate: "2023-03-30T13:18:57Z",
    },
  },
];

export const MockPaidBills = [
  {
    data: "2023-03-30T13:32:57+0000",
    amount: 158.4,
    operator: "UKCERRIK",
    logo: "https://i0.wp.com/pago.al/wp-content/uploads/2022/03/one.webp?resize=98%2C81&ssl=1",
    transactionType: "UKCERRIK PAYMENT",
    utilityId: 515,
    senderBooking: 3603,
  },
  {
    data: "2023-03-30T13:30:03+0000",
    amount: 208.56,
    operator: "UKCERRIK",
    logo: "https://i0.wp.com/pago.al/wp-content/uploads/2022/03/one.webp?resize=98%2C81&ssl=1",
    transactionType: "UKCERRIK PAYMENT",
    utilityId: 512,
    senderBooking: 3593,
  },
  {
    data: "2023-03-30T13:29:31+0000",
    amount: 14.4,
    operator: "UKCERRIK",
    logo: "https://i0.wp.com/pago.al/wp-content/uploads/2022/03/one.webp?resize=98%2C81&ssl=1",
    transactionType: "UKCERRIK PAYMENT",
    utilityId: 510,
    senderBooking: 3585,
  },
  {
    data: "2023-03-30T13:20:22+0000",
    amount: 158.4,
    operator: "UKCERRIK",
    logo: "https://i0.wp.com/pago.al/wp-content/uploads/2022/03/one.webp?resize=98%2C81&ssl=1",
    transactionType: "UKCERRIK PAYMENT",
    utilityId: 506,
    senderBooking: 3573,
  },
  {
    data: "2023-03-30T13:19:02+0000",
    amount: 14.4,
    operator: "UKCERRIK",
    logo: "https://i0.wp.com/pago.al/wp-content/uploads/2022/03/one.webp?resize=98%2C81&ssl=1",
    transactionType: "UKCERRIK PAYMENT",
    utilityId: 505,
    senderBooking: 3571,
  },
];
