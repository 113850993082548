import Lottie from "react-lottie";
import langText from "../../lang/i18n";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/reduxHooks";
import { hexWithOpacity, logActivity } from "../../hooks/functions";
import animationData from "../../assets/782-check-mark-success.json";
import { CheckPinExists, CustomerSettings } from "../../constants/endpoints";
import VerifyDocumentScreen from "../VerifyDocumentScreen";

function PinView() {
  const [newPin, setNewPin] = useState("");
  const [newPin2, setNewPin2] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentPin, setCurrentPin] = useState("");
  const [pinExists, setPinExists] = useState(true);
  const [inputsError, setInputsError] = useState("");
  const [documentsVerified, setDocumentsVerified] = useState(false);
  const { UserToken, PersonId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    checkIfUserHasPin();
  }, []);

  const checkIfUserHasPin = () => {
    fetch(CheckPinExists(PersonId), {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + UserToken,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        logActivity(CheckPinExists(PersonId), res.status);
        res.json().then((data) => logActivity(data));
        if (res.status == 200) {
          setPinExists(true);
        } else {
          setPinExists(false);
        }
      })
      .catch((e) => {
        let err = Error(e);
      });
  };

  const confirmPIN = async () => {
    let body = pinExists
      ? {
          person: PersonId,
          pin: newPin,
          oldPin: currentPin,
        }
      : {
          person: PersonId,
          pin: newPin,
        };
    logActivity(JSON.stringify(body));
    if (newPin.length < 4) {
      let error = langText("pin_length_err");
      setInputsError(error);
    } else if (newPin !== newPin2) {
      let error = langText("pin_doesnt_match");
      setInputsError(error);
    } else {
      setLoading(false);

      try {
        fetch(CustomerSettings(), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          body: JSON.stringify(body),
        })
          .then(async (response) => {
            logActivity(response.status);
            if (response.status === 201) {
              const res = await response.json();
              logActivity(res);
              setSuccess(true);
              setLoading(false);
            } else {
              setInputsError(
                pinExists ? langText("old_pin_incorrect") : langText("error")
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            logActivity(e);
            let err = Error(e);
          });
      } catch (e) {
        logActivity(e);
      }
    }
  };

  return (
    <div>
      {!documentsVerified ? (
        <VerifyDocumentScreen
          setDocumentsVerified={setDocumentsVerified}
          setPinExists={setPinExists}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <div
            className="display-label"
            style={{
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 20,
            }}
          >
            {langText("change_pin")}
          </div>
          <div
            style={{
              fontSize: 16,
              marginBottom: 20,
            }}
          >
            {langText("info_pin")}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {pinExists && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <div className="display-label" style={{ minWidth: 200 }}>
                  {langText("current_pin")}
                </div>
                <input
                  className="input"
                  type="password"
                  value={currentPin}
                  maxLength={4}
                  onChange={(e) => setCurrentPin(e.target.value)}
                />
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="display-label"
                style={{ minWidth: 200, marginBottom: 20 }}
              >
                {langText("new_pin")}
              </div>
              <input
                maxLength={4}
                className="input"
                type="password"
                onChange={(e) => setNewPin(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="display-label"
                style={{ minWidth: 200, marginBottom: 20 }}
              >
                {langText("new_pin_again")}
              </div>
              <input
                maxLength={4}
                className="input"
                type="password"
                onChange={(e) => setNewPin2(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              alignSelf: "center",
              marginBottom: 10,
              color: hexWithOpacity("#FF0000", 0.7),
            }}
          >
            {inputsError}
          </div>
          <div
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              padding: "1em",
            }}
          >
            <a
              onClick={() => {
                setPinExists(false);
                setDocumentsVerified(true);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              {langText("forgot_pin")}
            </a>
          </div>
          <div
            onClick={confirmPIN}
            style={{
              display: "flex",
              minWidth: 200,
              flexDirection: "column",
              backgroundColor: "#546BEA",
              color: "#FFF",
              borderRadius: 10,
              alignItems: "center",
              padding: "20px 70px",
              alignSelf: "center",
            }}
          >
            <div className="display-label" style={{}}>
              {langText("card_screen_reset_pin")}
            </div>
          </div>

          <Modal
            isVisible={success}
            setIsVisible={setSuccess}
            title={langText("change_pin_success")}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={400}
                  width={400}
                />

                <button
                  style={{
                    marginTop: 10,
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#546BEA",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: 18,
                    border: "none",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    setSuccess(false);
                    setCurrentPin("");
                    setNewPin("");
                    setNewPin2("");

                    navigate("/");
                  }}
                  disabled={loading}
                >
                  {langText("continue")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default PinView;
