import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import langText from "../lang/i18n";

type Props = {
  title: string;
  logo?: boolean;
  trailing?: React.ReactNode;
};
export default function Header({ title, logo, trailing }: Props) {
  return (
    <header
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
      }}
    >
      {!logo ? (
        <div
          style={{
            cursor: "pointer",
            color: "#546BEA",
            fontSize: 30,
            marginRight: 20,
          }}
          onClick={() => window.history.back()}
        >
          <FontAwesomeIcon
            icon={solid("chevron-left")}
            size={"1x"}
            color={"#546BEA"}
          />
        </div>
      ) : null}

      <h1 style={{ margin: 0, flex: 1, fontSize: 30, fontWeight: 500 }}>
        {langText(title)}
      </h1>
      {trailing}
    </header>
  );
}
