//webhook funtionalities

import { useEffect, useState } from "react";
import { useAppSelector } from "./reduxHooks";
import { WebHookEnpoint } from "../constants/endpoints";
import { logActivity } from "./functions";
import langText from "../lang/i18n";

export default function useWebhook() {
  const { PersonId, UserToken, chosenAcc } = useAppSelector(
    (state) => state.user
  );
  const [saved, setSaved] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const getINP = async () => {
    setLoading(true);
    const res = await fetch(WebHookEnpoint(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    setLoading(false);
    logActivity(WebHookEnpoint(), "GET", res.status);
    if (!res.ok) {
      logActivity("Some error ocurred");
      setError(langText("error"));
      // return;
    }
    const data = await res.json();
    logActivity(data);
    setSaved(data[0]);
  };

  const saveWebhook = async (webhookUrl: string, secureToken: string) => {
    setLoading(true);
    const res = await fetch(WebHookEnpoint(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        url: webhookUrl,
        secret: secureToken,
        webhookType: "BOOKING_COMPLETED",
        creator: PersonId,
        account: chosenAcc?.id,
      }),
    });
    setLoading(false);
    logActivity(WebHookEnpoint(), "POST", res.status);
    if (!res.ok) {
      logActivity("Some error ocurred");
      setError(langText("error"));
      // return;
    }
    getINP();
    const data = await res.json();
    logActivity(data);
  };

  const deleteWebhook = async () => {
    setLoading(true);
    const res = await fetch(WebHookEnpoint(saved.id), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    setLoading(false);
    logActivity(WebHookEnpoint(saved.id), "DELETE", res.status);
    if (!res.ok) {
      logActivity("Some error ocurred");
      setError(langText("error"));
      return;
    }
    getINP();
  };

  useEffect(() => {
    getINP();
  }, []);

  return { saveWebhook, deleteWebhook, loading, saved, error };
}
