import React from "react";
import langText from "../lang/i18n";

export default function DateText({
  date,
  showTime = false,
}: {
  date: string;
  showTime?: boolean;
}) {
  const months = [
    langText("jan"),
    langText("feb"),
    langText("mar"),
    langText("apr"),
    langText("may"),
    langText("jun"),
    langText("jul"),
    langText("aug"),
    langText("sep"),
    langText("oct"),
    langText("nov"),
    langText("dec"),
  ];

  let newDate = date;
  if (typeof date === "string" && date.includes("+"))
    newDate = date.split("+")[0];
  else if (typeof date === "string" && date.split(" ").length === 6) {
    // make this a valid date format
    //date = "Thu Jun 16 09:31:20 UTC 2022"
    let dateList = date.split(" ");
    let thisMonth = months.indexOf(langText(dateList[1].toLowerCase())) + 1;
    newDate =
      dateList[5] +
      "-" +
      (thisMonth < 10 ? "0" + thisMonth : thisMonth) +
      "-" +
      dateList[2] +
      "T" +
      dateList[3];
  }
  let createdDate = new Date(newDate);
  let month = createdDate.getMonth();
  let year = createdDate.getFullYear();
  let day = createdDate.getDate();
  let currentMonth = months[month] ?? "";
  let bookingDt = currentMonth.substr(0, 3) + ". " + day + " " + year;
  return (
    <span
      style={{
        fontSize: 13,
        fontFamily: "Roboto-Regular",
      }}
    >
      {bookingDt +
        (showTime ? ", " + createdDate.toTimeString().substring(0, 5) : "")}
    </span>
  );
}
