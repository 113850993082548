import React, { useEffect, useState } from "react";
import langText from "../lang/i18n";
import {
  GetTwoFactorAuthEndpoint,
  TwoFactorAuthEndpoint,
} from "../constants/endpoints";
import { useModal } from "../components/modals/ModalService";
import { Base64 } from "js-base64";
import { useAppSelector } from "./reduxHooks";
import { Callback } from "i18next";
import LoadingSpinner from "../components/LoadingIndicator";
import { logActivity } from "./functions";

const service_username = "sms@rbcn.al";
const service_password = "11111111";
export default function use2FAuth() {
  const { UserData } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [finishRequests, setFinishRequests] = useState(false);
  const { openModal, closeModal } = useModal();
  let twofaSuccessAction: Function | undefined;
  let twofaFailAction: Function | undefined;

  async function sendMobileAuthRequest(mobileNumber?: string) {
    setLoading(true);
    const response = await fetch(TwoFactorAuthEndpoint(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization:
          "Basic " + Base64.btoa(service_username + ":" + service_password),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        number: mobileNumber ?? UserData?.mobileNumber,
      }),
    });

    if (!response.ok) {
      setLoading(false);
      const resBody = await response.json();
      logActivity(resBody);
      if (!!twofaFailAction) twofaFailAction();
      closeModal();
      return;
    }
    const resBody = await response.json();
    logActivity(resBody);

    checkAuthStatus(resBody.id);

    setTimeout(() => {
      setFinishRequests(true);
      if (!!twofaFailAction) twofaFailAction();
    }, 1000 * 60 * 5);
  }

  async function checkAuthStatus(id: string) {
    const response = await fetch(GetTwoFactorAuthEndpoint(id), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization:
          "Basic " + Base64.btoa(service_username + ":" + service_password),
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // setError(langText("error"));
      // setLoading(false);
      if (!finishRequests)
        setTimeout(() => {
          checkAuthStatus(id);
        }, 400);
      return;
    }

    const resBody = await response.json();

    if (!resBody.verified && !finishRequests) {
      setTimeout(() => {
        checkAuthStatus(id);
      }, 400);
      return;
    }

    closeModal();
    if (!!twofaSuccessAction) twofaSuccessAction();
    setFinishRequests(true);
    setLoading(false);
  }

  function showAuthModal(
    successAction: Callback,
    failAction: Callback,
    phone?: string
  ) {
    setFinishRequests(false);
    twofaSuccessAction = successAction;
    twofaFailAction = failAction;
    openModal(renderModal());
    sendMobileAuthRequest(phone);
  }

  function renderModal() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: 22, fontFamily: "Poppins" }}>
          {langText("2fa")}
        </div>
        <div>{langText("2fa_desc")}</div>
        <LoadingSpinner />
      </div>
    );
  }

  useEffect(() => {
    return () => {
      setFinishRequests(false);
    };
  }, []);

  return {
    showAuthModal,
  };
}
