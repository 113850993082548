import { useEffect, useState } from "react";
import { ResetPin } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import langText from "../lang/i18n";
import { logOutClearDB } from "../localDatabase/mainDB";

export default function VerifyDocumentScreen(props) {
  const { UserToken, UserData } = useAppSelector((state) => state.user);
  const [personalNr, setPersonalNr] = useState("");
  const [documentNr, setDocumentNr] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  async function validate() {
    setLoading(true);
    const response = await fetch(ResetPin(UserData.id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + UserToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personalNr: personalNr.trim(),
        documentNr: documentNr.trim(),
      }),
    });
    setLoading(false);
    if (response.status === 422) {
      setError(langText("invalid_data"));
      return;
    }
    if (!response.ok) {
      setError(langText("error"));
      return;
    }
    props.setPinExists(false);
    props.setDocumentsVerified(true);
    // navigate("CheckPIN", { reset: true });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        className="display-label"
        style={{
          fontSize: 24,
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        {langText("verify_document")}
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 20,
        }}
      >
        {langText("verify_document_desc")}
      </div>

      <div
        style={{
          flexDirection: "row",
          borderRadius: 10,
          padding: 10,
          marginBottom: 15,
          alignItems: "center",
        }}
      >
        <div>{langText("id_number")}</div>
        <input
          className="input"
          value={personalNr}
          onChange={(e) => setPersonalNr(e.target.value)}
        />
      </div>

      <div
        style={{
          flexDirection: "row",
          borderRadius: 10,
          padding: 10,
          marginBottom: 15,
          alignItems: "center",
        }}
      >
        <div>{langText("document_nr")}</div>
        <input
          className="input"
          value={documentNr}
          onChange={(e) => setDocumentNr(e.target.value)}
        />
      </div>

      <button
        style={{
          marginTop: 10,
          width: "100%",
          padding: "10px",
          backgroundColor: "#546BEA",
          color: "white",
          borderRadius: "10px",
          fontSize: 18,
          border: "none",
          fontFamily: "Poppins",
        }}
        onClick={validate}
        loading={loading}
      >
        {langText("continue")}
      </button>
    </div>
  );
}
