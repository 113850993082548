import { getCurrentServer } from "../localDatabase/mainDB";

export function handleCardNumber(text) {
  let formattedText = text.split(" ").join("");
  if (formattedText.length > 0) {
    formattedText = formattedText.match(new RegExp(".{1,4}", "g")).join(" ");
  }
  //this.setState({ cardNumber: text });
  logActivity(formattedText);
  return formattedText;
}

export function reportError(text = "") {
  console.warn(text);
  // crashlytics().recordError(Error(text));
}

export function logActivity(...params) {
  // if (getCurrentServer() === "dev") return;
  // if (!LoginUrl().startsWith(DEV_BACKEND))
  // else crashlytics().log(JSON.stringify(params));
}

export function getFullNumber(
  phoneNumber,
  //:string
  dialCode
  //:string
) {
  let phone = phoneNumber.replace(/ /g, "");
  if (phone.startsWith(dialCode)) {
    let afterPrefix = phone.substring(
      phone.indexOf(dialCode) + dialCode.length
    );
    if (!afterPrefix.startsWith("0")) return phone;
    return dialCode + afterPrefix.substring(1);
  }

  if (phone.startsWith(dialCode.substring(1))) {
    let afterPrefix = phone.substring(
      phone.indexOf(dialCode.substring(1)) + dialCode.length
    );
    if (!afterPrefix.startsWith("0")) return dialCode + afterPrefix;
    return dialCode + afterPrefix.substring(1);
  }

  if (
    (phone.startsWith("+06") && phone.length === 11) ||
    (phone.startsWith("+6") && phone.length === 10)
  )
    phone = phone.substring(1);

  if (phone.startsWith("0")) return dialCode + phone.substring(1);
  if (phone.startsWith("6")) return dialCode + phone;
  return phone;
}

export const isSorted = (
  arr
  //: number[]
) => {
  if (arr.length <= 1) return 0;
  const direction = arr[1] - arr[0];
  for (let i = 2; i < arr.length; i++) {
    if ((arr[i] - arr[i - 1]) * direction < 0) return 0;
  }
  return Math.sign(direction);
};
//
// export async function showNetworkError() {
//   Toast.show({
//     type: "error",
//     text1: langText("no_connection"),
//     text2: langText("connect_to_continue"),
//     autoHide: true,
//     visibilityTime: 3000,
//   });
//   setTimeout(() => {
//     if (LoginUrl().startsWith(DEV_BACKEND)) {
//       Toast.show({
//         type: "dev",
//         autoHide: false,
//       });
//     }
//   }, 3000);
// }

export function hexWithOpacity(
  hex,
  //: string
  opacity
  //: number
) {
  //:    string
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toString());
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    return `rgba(${r + "," + g + "," + b + "," + opacity})`; //return 23,14,45 -> reformat if needed
  }
  return `rgba(0, 0, 0, 0)`;
}
