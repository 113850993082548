import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useUtilityService } from "../../hooks/useUtilityService";
import {
  GetContractInvoice,
  GetInvoice,
  UtilityInvoices,
} from "../../constants/endpoints";
import InvoiceItem from "../../components/InvoiceItem";
import langText from "../../lang/i18n";
import UtilityPaidBill from "../../components/UtilityPaidBill";
import { useAppSelector } from "../../hooks/reduxHooks";
import { logActivity } from "../../hooks/functions";
import { Contract } from "../../redux/general-slice";
import { logOutClearDB } from "../../localDatabase/mainDB";

export default function InvoicesScreen() {
  const { UserData, UserToken, chosenAcc } = useAppSelector(
    (state) => state.user
  );
  const { contracts } = useAppSelector((state) => state.general);
  const location = useLocation();
  const { utility, service } = location.state;
  const [showAddContract, setShowAddContract] = useState(false);
  const [myBills, setMyBills] = useState<any[]>([]);
  const [paidBills, setPaidBills] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newContract, setNewContract] = useState<Map<string, string>>();
  const {
    loading: contractsLoading,
    availability,
    deleteContract,
    errorMsg,
    saveContract,
  } = useUtilityService(service.operatorId ?? utility.operatorId, service);
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    setMyBills(
      myBills.map((item) => {
        if (
          !item.SerialNumber &&
          !item.serial_number &&
          !item.Seria &&
          !item.detyrimi &&
          !item.customer_id &&
          !item.customerNo
        ) {
          return {
            ...item,
            onPress: () => {
              deleteContract(item);
            },
          };
        }
        return item;
      })
    );
  }, []);

  async function getAllBills(list: any[]) {
    return;
    logActivity(list);
    // setMyBills([]);
    setLoading(true);
    if (list == null || list?.length == 0) {
      setLoading(false);
      setErrorMessage(
        !langText(
          "bill_id_desc" + (service.operatorId ?? utility.operatorId)
        ).startsWith("[missing")
          ? langText(
              "bill_id_desc" + (service.operatorId ?? utility.operatorId)
            )
          : langText("bill_id_desc")
      );
      return;
    }
    let newList: any[] = [];
    await Promise.all(
      list.map(async (obj: any) => {
        // let contract = Object.keys(obj)[0]; // obj["accountNo_phone_number"];
        // let contract = obj[Object.keys(obj)[0]]; // obj["Numri i kontrates"];

        let billList = await getBills(obj);
        if (billList.length > 0) {
          newList.push(...[getBillHeader(obj), ...billList]);
        }
      })
    );

    logActivity("new bills", newList);
    setMyBills(newList);
    setLoading(false);
    // setShouldReload(true);
  }

  function getBillHeader(object: any) {
    return !!object.vlera
      ? {
          value: object.vlera,
          onPress: () => {
            deleteContract(object);
          },
        }
      : object;
  }

  async function getBills(contract: Contract) {
    try {
      let reqBody = requestBody(contract);
      logActivity("reqBody", reqBody);
      const url =
        utility.short_name == "Vodafone" ? GetInvoice() : GetContractInvoice();
      logActivity("url", url);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
        body: reqBody,
      });

      logActivity("GetInvoice", response.status);
      // logActivity(response);

      let res = await response
        .json()
        .catch((e) => logActivity("responce json error", e));
      logActivity("GetInvoice data", res);
      if (response.status === 200) {
        if (utility.short_name == "UK" && res.invoicesByContractNo?.code != 0) {
          return [
            {
              message: langText("Ju nuk keni asnje fature per kete kontrate"),
              error: false,
            },
          ];
        } else if (Array.isArray(res)) {
          let tempList = res.filter(
            (item) => item?.paguar != undefined && !item?.paguar
          );
          res = { Invoices: tempList };
        } else if (!res.Invoices) {
          let osheeStatusCode = Array.isArray(res.invoices)
            ? res.invoices[0]?.statusCode
            : res.invoice?.statusCode;
          if (
            res.invoices == "No data to Display" ||
            osheeStatusCode == "39" ||
            osheeStatusCode == "22" ||
            (typeof res == "string" &&
              res.includes("There is no unpaid debt for subscriber"))
          ) {
            setErrorMessage(langText("no_bills"));
            return [
              {
                message: langText("no_bills"),
                error: false,
              },
            ];
          }
          if (!!osheeStatusCode || res?.invoicesByContractNo?.code == "8") {
            setErrorMessage(langText("wrong_inputs_bills"));
            return [
              {
                message: langText("wrong_inputs_bills"),
                error: true,
              },
            ];
          }
          res.Invoices = res.invoicesByContractNo?.invoices ??
            res.fines ??
            res.invoices ??
            res.Products ?? [res.invoice];
        }

        logActivity("res", res);
        res.Invoices.map((item: any) => {
          item.contract = contract;
        });
        if (res.Invoices.length == 0)
          return [
            {
              message: langText("no_bills"),
              error: false,
            },
          ];
        return res.Invoices;
      } else if (response.status === 400) {
        if (
          typeof res == "string" &&
          res.includes("There is no unpaid debt for subscriber")
        ) {
          setErrorMessage(langText("no_bills"));
          return [
            {
              message: langText("no_bills"),
              error: false,
            },
          ];
        }
        setErrorMessage(langText("wrong_inputs_bills"));
        return [
          {
            message: langText("wrong_inputs_bills"),
            error: true,
          },
        ];
      } else if (response.status === 500) {
        setErrorMessage(langText("connection_error"));
      } else {
        setErrorMessage(langText("connection_error"));
        return [
          {
            message: langText("connection_error"),
            error: true,
          },
        ];
      }
    } catch (e) {
      // let err = Error(e);
      // logActivity("err", err.message);
      setLoading(false);
      return [
        {
          message: langText("connection_error"),
          error: true,
        },
      ];
    }
  }

  function requestBody(contract: Contract | any) {
    logActivity(contract);
    if ((service.operatorId ?? utility.operatorId) == "1") {
      let vodafoneContract = contract?.vlera;
      let accountNo_phone_number =
        vodafoneContract["accountNo_phone_number"]?.trim();
      let phoneNumber =
        accountNo_phone_number.startsWith("+355") ||
        accountNo_phone_number.startsWith("355")
          ? accountNo_phone_number.startsWith("+")
            ? accountNo_phone_number.substring(1)
            : accountNo_phone_number
          : accountNo_phone_number.startsWith("6") &&
            accountNo_phone_number.length == 9
          ? "355" + accountNo_phone_number
          : accountNo_phone_number.startsWith("0") &&
            accountNo_phone_number.length == 10
          ? "355" + accountNo_phone_number.substring(1)
          : null;
      return JSON.stringify({
        operatorId: service.operatorId ?? utility.operatorId,
        phone: phoneNumber ?? "",
        customerName: isNaN(vodafoneContract["customerName"])
          ? vodafoneContract["customerName"]?.trim()
          : "",
        accountNo: !phoneNumber ? accountNo_phone_number : "",
        currency: service.currency,
        customerId: UserData!.id,
        token: UserToken,
      });
    }
    if ((service.operatorId ?? utility.operatorId) == "23")
      return JSON.stringify({
        operatorId: service.operatorId ?? utility.operatorId,
        platesNo: contract?.vlera?.replace(/\ /g, ""),
        customerId: UserData!.id,
      });

    if ((service.operatorId ?? utility.operatorId) == "24")
      return JSON.stringify(
        !isNaN(contract?.vlera?.trim())
          ? {
              operatorId: "24",
              documentNo: contract?.vlera?.trim(),
              agreement: "F",
            }
          : {
              operatorId: "24",
              customerNo: contract?.vlera?.trim(),
              agreement: "F",
            }
      );

    if ((service.operatorId ?? utility.operatorId) == "25")
      return JSON.stringify({
        operatorId: "25",
        customerNo: contract?.vlera?.trim(),
      });

    if ((service.operatorId ?? utility.operatorId) == "26")
      return JSON.stringify({
        nid: contract?.vlera?.trim(),
      });

    return JSON.stringify({
      operatorId: service.operatorId ?? utility.operatorId,
      contractNo: contract?.vlera?.trim(),
      customerId: UserData!.id,
    });
  }

  function getPaidBills() {
    return;
    try {
      setPaidBills([]);

      fetch(
        UtilityInvoices(
          chosenAcc?.id + "/" + (service.operatorId ?? utility.operatorId)
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
        }
      ).then((response) => {
        logActivity(
          UtilityInvoices(
            chosenAcc?.id + "/" + (service.operatorId ?? utility.operatorId)
          ),
          response.status
        );
        if (response.status === 200) {
          response.json().then((res) => {
            logActivity(res);
            if (Array.isArray(res.Invoices)) {
              setPaidBills(res.Invoices);
            }
          });
        }
      });
    } catch (e) {
      logActivity(e);
    }
  }

  useEffect(() => {
    if (loading || contractsLoading) return;
    getAllBills(contracts);
    getPaidBills();

    let newList: any[] = [];
    contracts.map(async (obj, index) => {
      newList.push(...[getBillHeader(obj), { loading: true }]);
    });

    setMyBills(newList);
    setLoading(false);
  }, [contracts]);

  return (
    <div>
      <div style={{ margin: 20 }}>
        <Header title={service.name} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!showAddContract ? (
            <button
              onClick={() => setShowAddContract(true)}
              style={{
                width: "20%",
                backgroundColor: "#546BEA",
                padding: "1rem",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              {langText(
                "add_new_" +
                  (service.name.includes("UK")
                    ? "uk"
                    : service.operatorId ?? utility.operatorId)
              )}
            </button>
          ) : (
            <div
              style={{
                borderRadius: 10,
                backgroundColor: `rgba(0, 0, 0, 0.09)`,
                boxShadow: "0 4px 8px 0 rgba(84, 107, 234, 0.2)",

                padding: "20px 50px",
                width: 400,
              }}
            >
              {service.fields.map((field: any, index: number) => {
                return (
                  <div>
                    <label
                      style={{
                        textAlign: "center",
                        marginRight: 20,
                        color: "#546BEA",
                      }}
                      htmlFor={field.label}
                    >
                      {langText(field.label)}
                    </label>
                    <input
                      style={{
                        width: "100%",
                        padding: "0.5rem",
                        borderRadius: "5px",
                        border: "1px solid gray",
                        fontSize: "16px",
                      }}
                      type="text"
                      id={field.label}
                      value={newContract?.get(field.label) ?? ""}
                      onChange={(e) => {
                        let newMap = new Map<string, string>();
                        newContract?.forEach((value, key) => {
                          newMap.set(key, value);
                        });
                        newMap.set(field.label, e.target.value);
                        setNewContract(newMap);
                      }}
                      maxLength={15}
                    />
                  </div>
                );
              })}

              <button
                style={{
                  marginTop: 20,
                  width: "100%",
                  backgroundColor: "#546BEA",
                  padding: "1rem",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "18px",
                }}
                disabled={!newContract}
                onClick={() => saveContract(newContract!)}
              >
                {langText(
                  "add_new_" +
                    (service.name.includes("UK")
                      ? "uk"
                      : service.operatorId ?? utility.operatorId)
                )}
              </button>
              {!!errorMsg && (
                <div style={{ color: "red", textAlign: "center" }}>
                  {errorMsg}
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flex: 1, backgroundColor: "red" }}>
            {myBills?.length > 0 ? (
              myBills.map((bill: any, index) => (
                <InvoiceItem bill={bill} service={service} utility={utility} />
              ))
            ) : (
              <div
                style={{ textAlign: "center", marginTop: 20, color: "grey" }}
              >
                <h5>{"Regjistroni numrin e kontrates te Ujesjellesit"}</h5>
                <h5>{"per te monitoruar dhe per t'u njoftuar"}</h5>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flex: 1, backgroundColor: "blue" }}>
            {paidBills.map((bill: any, index) => (
              <UtilityPaidBill item={bill} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
