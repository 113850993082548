import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LoginScreen.css";
import logo from "../assets/Pago-Logo.png";
import langText from "../lang/i18n";
import {
  DEV_BACKEND,
  LoginUrl,
  SendRegisterSMSCode,
  VerifyRegisterSMSCode,
} from "../constants/endpoints";
import { useAppSelector } from "../hooks/reduxHooks";
import { Base64 } from "js-base64";
import { logActivity } from "../hooks/functions";
import { logOutClearDB } from "../localDatabase/mainDB";

const service_username = "sms@rbcn.al";
const service_password = "11111111";
type Props = {
  phone?: string;
  process?: string;
};

const SMSVerificationScreen = () => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  const handlecodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value.length === 0 ||
      event.target.value.match(/^[0-9]+$/) != null
    ) {
      setCode(event.target.value);
      setInputError("");
      setErrorMessage("");
    } else {
      setInputError(langText("numbers_only"));
    }
  };

  useEffect(() => {
    if (code.length === 6) verifyCode();
  }, [code]);

  function sendCode() {
    try {
      // let phoneNumber = phone.startsWith("+355") ? phone.substring(1) : phone;
      logActivity(
        "is dev: " + LoginUrl().startsWith(DEV_BACKEND),
        SendRegisterSMSCode(),
        "Sending code to mobileNumber:" + location.state.phone
      );
      fetch(SendRegisterSMSCode(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization:
            "Basic " + Base64.btoa(service_username + ":" + service_password),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number: location.state.phone,
        }),
      })
        .then((res) => {
          logActivity("rubiser/verification: " + res.status);
          if (res.status !== 201 && res.status !== 200) {
            setErrorMessage(langText("error_sending_sms"));
          } else {
            setTimeout(() => {
              setIsLoading(false);
            }, 5000);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          let err = Error(e);
        });
    } catch (error) {
      setErrorMessage(langText("error"));
      setIsLoading(false);
    }
  }

  async function verifyCode() {
    logActivity("mobileNumber sending :" + location.state.phone);
    logActivity("code sending :" + code);
    setInputError("");
    setIsLoading(true);

    // let phoneNumber = phone.startsWith("+355") ? phone.substring(1) : phone;
    fetch(VerifyRegisterSMSCode(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization:
          "Basic " + Base64.btoa(service_username + ":" + service_password),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        number: location.state.phone,
        token: code,
      }),
    })
      .then((response) => {
        logActivity("rubiser/verification/service/verify :" + response.status);
        setIsLoading(false);

        if (response.status !== 200) {
          setInputError(langText("incorrect_code"));
          return;
        }
        setInputError("");

        //reset password
        response
          .json()
          .then((data) => {
            logActivity("data", data);
            // reset password
            navigate("/reset-password", {
              state: {
                phone: location.state.phone,
                code,
                verification: data.id,
              },
            });
          })
          .catch((e) => {
            let err = Error(e);
            logActivity("error", err.message);
            logActivity(e);
          });
      })
      .catch((e) => {
        setIsLoading(false);
        let err = Error(e);
      });
  }

  return (
    <div className="login-screen">
      <div className="form-container">
        <img
          alt="logo"
          src={logo}
          style={{
            maxWidth: "40%",
            height: "auto",
            marginBottom: "50px",
          }}
        />
        <div>
          <div>
            <label htmlFor="code">{langText("code_sent")}</label>
            <input
              type="tel"
              id="code"
              value={code}
              onChange={handlecodeChange}
              maxLength={6}
            />
          </div>
          {errorMessage && <p id="error-message">{errorMessage}</p>}
          {inputError && <p id="error-message">{inputError}</p>}
          <div
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              padding: "1em",
            }}
          >
            <a
              onClick={() => sendCode()}
              style={{
                cursor: "pointer",
              }}
            >
              {langText("send_code_again")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSVerificationScreen;
