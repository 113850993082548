import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useUtilityService } from "../../hooks/useUtilityService";
import langText from "../../lang/i18n";
import {
  GetDigitalbBundles,
  GetOneBundles,
  GetProducts,
  GetTringBundles,
  UtilityInvoices,
} from "../../constants/endpoints";
import UtilityAvailabilityView from "./UtilityAvailabilityView";
import ChooseAccountButton from "../../components/ChooseAccountButton";
import { Contract } from "../../redux/general-slice";
import RegisterBillModal from "./RegisterBillModal";
import BundleItem from "../../components/BundleItem";

export default function SearchScreen(props: any) {
  const { SelectedColor, Theme } = useAppSelector((state) => state.theme);

  const { chosenAcc, UserToken, PersonId, UserData } = useAppSelector(
    (state) => state.user
  );

  const { contracts } = useAppSelector((state) => state.general);
  const { provider, service, phoneNo } = props;
  const [myBundles, setMyBundles] = useState([]);
  const [selectedContract, setSelectedContract] = useState<Contract>();
  const [errorMessage, setErrorMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [firstTime, setfirstTime] = useState(true);
  const [paidBills, setPaidBills] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [userInputs, setUserInputs] = useState(
    new Array(service.fields.length)
  );
  const {
    loading: contractsLoading,
    availability,
    saveContract,
    errorMsg,
  } = useUtilityService(service.operatorId ?? provider.operatorId, service);

  useEffect(() => {
    if (phoneNo && firstTime) {
      setSelectedContract({ phone_number: phoneNo } as any);
      return;
    }
    if (modalVisible || loading || contractsLoading) return;
    setLoading(true);

    if (contracts.length == 0 && firstTime) {
      setLoading(false);
      setfirstTime(false);
      //setModalVisible(true);
      return;
    }

    if (
      !selectedContract ||
      Object.keys(selectedContract as Contract).length == 0
    )
      setSelectedContract(
        contracts.length > 0
          ? contracts.filter((item) => typeof item["vlera"] == "string")[0] ??
              {}
          : ({} as any)
      );
  }, [modalVisible, loading, contracts]);

  useEffect(() => {
    myBundles.map((bundle) => {
      if (bundle["Balanca"] != undefined) {
        setBalance(bundle["Balanca"]);
      }
    });
  }, [myBundles]);

  useEffect(() => {
    if (!selectedContract) return;

    if (Object.keys(selectedContract as Contract).length == 0) {
      setErrorMessage(
        !langText(
          "bill_id_desc" + (service.operatorId ?? provider.operatorId)
        ).startsWith("[missing")
          ? langText(
              "bill_id_desc" + (service.operatorId ?? provider.operatorId)
            )
          : langText("bill_id_desc")
      );
      setLoading(false);
      return;
    }
    setLoading(true);
    getBundles();
    getPaidBills();
  }, [selectedContract]);

  async function getBundles() {
    try {
      setMyBundles([]);
      let reqBody = requestBody();

      if (!reqBody) return;

      fetch(
        provider.short_name == "One"
          ? GetOneBundles()
          : provider.short_name == "Tring Tv"
          ? GetTringBundles()
          : provider.short_name == "Digitalb"
          ? GetDigitalbBundles()
          : GetProducts(),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          body: reqBody,
        }
      )
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            response.json().then((res) => {
              setMyBundles(Array.isArray(res?.Products) ? res?.Products : []);
            });
          } else if (response.status === 400) {
            setErrorMessage(langText("no_products_for_inputs"));
          } else {
            setErrorMessage(langText("no_products"));
          }
        })
        .catch((e) => {
          let err = Error(e);
          if (err.message == "TypeError: Network request failed") {
          }
        });
    } catch (e) {
      console.warn(e);
    }
  }

  function requestBody() {
    if ((service.operatorId ?? provider.operatorId) == "1") {
      let phone = selectedContract?.vlera;
      if (!phone) {
        setLoading(false);
        setErrorMessage(langText("no_products"));
        return;
      }

      return JSON.stringify({
        operatorId: service.operatorId ?? provider.operatorId,
        phoneNo: phone.startsWith("+")
          ? phone.substring(1, phone.length)
          : phone,
        customerId: PersonId,
        token: UserToken,
      });
    }
    // else if ((service.operatorId ?? provider.operatorId) == "43") {
    //   return JSON.stringify({
    //     msisdn: getFullNumber(selectedContract.vlera),
    //   });
    // }
    else if (
      (service.operatorId ?? provider.operatorId) == "18" ||
      (service.operatorId ?? provider.operatorId) == "19"
    ) {
      const selectedContractFirstKey = Object.keys(
        selectedContract as any
      )[0] as any;
      return JSON.stringify({
        phoneNo:
          selectedContract?.vlera != undefined
            ? selectedContract.vlera
            : selectedContract?.[
                selectedContractFirstKey as keyof typeof selectedContract
              ],
        operatorId: service.operatorId ?? provider.operatorId,
        token: UserToken,
        customerId: UserData?.id,
      });
    }
  }

  function getPaidBills() {
    try {
      setPaidBills([]);

      fetch(
        UtilityInvoices(
          chosenAcc?.id + "/" + (service.operatorId ?? provider.operatorId)
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          // body: JSON.stringify({
          //   token: UserToken,
          // }),
        }
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            if (Array.isArray(res.Invoices)) {
              setPaidBills(res.Invoices);
            }
          });
        }
      });
    } catch (e) {}
  }
  return (
    <div style={{ flex: 1 }}>
      <div style={{ margin: 20 }}>
        <UtilityAvailabilityView
          name={langText(provider.short_name)}
          url={availability?.url}
          // navigation={navigation}
        />
        <ChooseAccountButton
          filter={(account) =>
            service.currency ? account.currency == service.currency : true
          }
        />

        {(!service.maxContractLength ||
          contracts.length < service.maxContractLength) &&
          (!addNew ? (
            <div>
              <button
                onClick={async () => {
                  setAddNew(true);
                }}
              >
                {langText(
                  "add_new_" + (service.operatorId ?? provider.operatorId)
                )}
              </button>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: Theme.containerColor,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <div style={{ flexDirection: "row", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  {service.fields.map((field: any, index: any) => {
                    return (
                      <div key={field.label} style={{ margin: 10 }}>
                        <input
                          type={field.type}
                          onChange={(newValue) => {
                            setHasError(false);
                            let newArr = [...userInputs];
                            newArr[index] = newValue;
                            setUserInputs(newArr);
                          }}
                          value={userInputs[index] ?? ""}
                          placeholder={field.placeholder}
                          pattern={field.pattern}
                          autoCapitalize={field.autoCapitalize}
                        />
                      </div>
                    );
                  })}
                </div>
                <div style={{ marginLeft: 10 }}>
                  <button
                    style={{ padding: 10 }}
                    onClick={async () => {
                      const obj = await saveContract(userInputs as any);
                      if (!errorMsg && !!obj) {
                        setUserInputs(new Array(service.fields.length));
                      }
                    }}
                  >
                    text={langText("add")}
                  </button>
                </div>
              </div>

              {errorMsg != "" && (
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: "Roboto-Regular",
                    color: "red",
                  }}
                >
                  {errorMsg}
                </div>
              )}
            </div>
          ))}
      </div>

      {selectedContract &&
        Object.keys(selectedContract as Contract).length != 0 && (
          <div
            style={{ margin: 20, marginTop: 10 }}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <div
              style={{
                borderBottomColor: "#DCDCDC",
                backgroundColor: SelectedColor,
                padding: 10,
                borderRadius: 10,
              }}
            ></div>
          </div>
        )}
      {myBundles.length > 0 || loading ? (
        <div>
          {myBundles.map((bundle: any, index) => {
            if (bundle["Balanca"] != undefined) {
              return;
            }
            if (
              bundle["ActiveProducts"] ||
              bundle["EligiblePromotions"] ||
              (bundle["EligibleProducts"] &&
                bundle["EligibleProducts"]?.length == 0) ||
              (bundle["CampaignProducts"] &&
                bundle["CampaignProducts"]?.length == 0)
            )
              return;
            return (
              <BundleItem
                key={index}
                bundle={bundle}
                //  navigation={navigation}
                contract={selectedContract}
                provider={provider}
                balance={balance}
                operatorId={service.operatorId ?? provider.operatorId}
              />
            );
          })}

          <div style={{ height: 50 }} />
        </div>
      ) : (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
          }}
        >
          <div
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 20,
              alignItems: "center",
              marginTop: 40,
              textAlign: "center",
              color: `${Theme.textColor}`,
            }}
          >
            {errorMessage}
          </div>
        </div>
      )}

      <RegisterBillModal
        modalVisible={modalVisible}
        onBackdropPress={() => {
          setModalVisible(false);
        }}
        service={service}
        provider={provider.short_name}
        selected={selectedContract}
        filter={(item: any) => typeof item["vlera"] == "string"}
        selectContract={(contract: any) => setSelectedContract(contract)}
        operatorId={service.operatorId ?? provider.operatorId}
      />
    </div>
  );

  //     {paidBills?.length > 0 && (
  //       <div style={{ padding: 20 }}>
  //         <div style={{ fontSize: 20, fontFamily: "Roboto-Medium" }}>
  //           {langText("last_transactions")}
  //         </div>
  //         {/* {paidBills
  //           .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
  //           .map((paidBill) => (
  //             <UtilityPaidBill
  //               key={paidBill.id}
  //               item={paidBill}
  //               navigation={navigation}
  //             />
  //           ))} */}
  //       </div>
  //     )}
  //     <div style={{ height: 50 }} />
  //   </div>

  //  </div>
}
