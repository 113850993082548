import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Colors, { userTheme } from "../constants/Colors";

export interface ThemeReducerType {
  Theme: ThemeColorsProps;
  SelectedColor: string;
}

export interface ThemeColorsProps {
  themeName: string;
  backgroundColor: string;
  containerColor: string;
  darkerContainerColor: string;
  secondaryContainerColor: string;
  textColor: string;
  shadow: {
    shadowColor: string;
    shadowOffset: { width: number; height: number };
    shadowOpacity: number;
    shadowRadius: number;
    elevation: number;
  };
}

const initialState: ThemeReducerType = {
  Theme: Colors.light,
  SelectedColor: userTheme.Morocco,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeColor(state, action: PayloadAction<string>) {
      state.Theme.shadow.shadowColor = action.payload;
      state.SelectedColor = action.payload;
    },
    setTheme(state, action: PayloadAction<ThemeColorsProps>) {
      state.Theme = {
        ...action.payload,
        shadow: {
          ...state.Theme.shadow,
          shadowColor: state.SelectedColor,
        },
      };
    },
  },
});

export const { changeColor, setTheme } = themeSlice.actions;
export default themeSlice.reducer;
