export const DEV_BACKEND = `https://rubi-dev.pxou.com/`;
export const PROD_BACKEND = `https://p2.pxou.com/`;
//export const PROD_BACKEND = `http://core-p2.pxou.com:8080/`;
const RUBI_DEV = `https://rubi-dev.pxou.com/`;
export const DEV_BACKEND_RUBISER = `https://rubi-dev.pxou.com/rubiser/`;
export const PROD_BACKEND_RUBISER = `https://rubi.pxou.com/rubiser/`;

let BACKEND = PROD_BACKEND;
let RUBISER = PROD_BACKEND_RUBISER;
// var RUBISER = DEV_BACKEND_RUBISER;

export const switchBackend = () => {
  //BACKEND = BACKEND === DEV_BACKEND ? PROD_BACKEND : DEV_BACKEND;
  return BACKEND;
};

const UTILITIES_BACKEND = () =>
  BACKEND === PROD_BACKEND ? `https://u.pxou.com/` : `https://u-dev.pxou.com/`;

export const LoginUrl = () => `${BACKEND}api/login`;
export const RegisterUrl = () => `${BACKEND}register/`;
export const ResetPasswordWithSMS = () => `${BACKEND}resetPasswordWithSMS`;
// dev core endpoint
export const UpdateUserUrl = (personId: string) =>
  `${BACKEND}api/person/${personId}`;
export const RegisterMobilePhone = (phoneNumber: string) =>
  `${BACKEND}registerMobile/${phoneNumber}`;
export const GetVideoIdAuthToken = () =>
  `https://etrust-live.electronicid.eu/v2/videoid.request`;
export const KycStepApi = () => `${BACKEND}api/kycStep`;
export const GetKycApi = (id: string) => `${BACKEND}api/person/${id}`;
export const SetKycCompleted = () =>
  `${BACKEND}api/customerSettings/kycCompleted`;
export const GetKycCompleted = () =>
  `${BACKEND}api/customerSettings/kyc-completed`;
export const GetUserInfo = (id: string) => `${BACKEND}api/persons/${id}`;
export const GetHomeScreenData = (id: string) =>
  `${BACKEND}api/persons/${id}/details`;
export const MakeTransactionUrl = () => `${BACKEND}api/transaction`;
export const NotificationEndpoint = () =>
  `${BACKEND}api/notificationReference/updateByPerson`;
export const DeleteNotificationEndpoint = (tokenId: string) =>
  `${BACKEND}api/notificationReference/${tokenId}`;
export const GetBookingsByAccounts = () =>
  `${BACKEND}api/booking/search?max=10000`;
export const SearchBookings = () => `${BACKEND}api/booking/search-es`;
export const GetBooking = (id: string) => `${BACKEND}api/booking/${id}`;
export const GetCardsDetails = () => `${BACKEND}api/person/search-cards`;
export const GetOneCard = () => `${BACKEND}api/paymentCard`;
export const GetUtilityCategories = () =>
  `https://utilities.pxou.com/api/categories`;
export const GetUtilities = () => `${BACKEND}api/business/`;

export const GetLastPersonPayments = () =>
  `${BACKEND}api/person/lastPersonPayments`;
export const DeleteContact = (contactId: string) =>
  `https://friends.on.al/api/friends/${contactId}`;
export const GetLatestRates = () => `https://rates.on.al/api/latestRate`;
export const ExchangeUrl = () => `${BACKEND}api/exchange`;
export const InitTransaction = () => `${BACKEND}api/p2p`;
export const InitInnerTransaction = () => `${BACKEND}api/a2a`;
export const PayToBusinessTransaction = () => `${BACKEND}api/c2b`;
export const PayBill = () => `https://utilities.pxou.com/api/payments`;
export const CheckPinExists = (personId: string) =>
  `${BACKEND}api/customerSettings/${personId}/checkPinExistence`;
export const CheckDocuments = () => `${BACKEND}api/personDocuments/`;
export const GetDocuments = () => `${BACKEND}api/person/search-documents/`;
export const CustomerSettings = () => `${BACKEND}api/customerSettings`;
export const ChangeUserPassword = (userId: string) =>
  `${BACKEND}api/person/${userId}`;
export const CheckUserPIN = () =>
  `${BACKEND}api/customerSettings/checkUsersPin`;
export const WebHookEnpoint = (id?: string) =>
  `${BACKEND}api/webhook/${id ?? ""}`;
export const SearchUtilities = (query: string) =>
  `https://utilities.pxou.com/api/search/providers/${query}`;
export const UpdateContract = (id: string) =>
  `https://utilities.pxou.com/api/contracts/${id}`;
export const GetPersonSettings = (id: string) =>
  `${BACKEND}api/customerSettings/${id}/device`;
export const GetAllPersonSettings = (id: string) =>
  `${BACKEND}api/customerSettings/${id}/all`;
export const SaveDeviceId = () => `${BACKEND}api/customerSettings/saveDeviceId`;
export const UploadDocument = () =>
  BACKEND == PROD_BACKEND
    ? `https://docrepo.pxou.com/document/upload`
    : `https://document-repository.on.al/document/upload`;
export const ProfilePicture = (id: string) =>
  BACKEND == PROD_BACKEND
    ? `https://docrepo.pxou.com/document/${id}/PROFILE`
    : `https://document-repository.on.al/document/${id}/PROFILE`;
export const CheckQrCodeData = () => `${BACKEND}api/person/verify-qr-code/`;

export const NiptToPersonId = (nipt: string) =>
  `${BACKEND}api/business/get-business-by-vat-id/${nipt}`;
export const StatementBydate = () => `${RUBISER}listBookingStatement`;

export const GetBookingsWithOffset = (offset: string) =>
  `${BACKEND}api/booking/search?max=20&offset=${offset}`;
export const PaymentCards = (id?: string) =>
  `${BACKEND}api/storedPaymentCard${id ? "/" + id : ""}`;

export const GetBookings = (params: string) =>
  `${BACKEND}api/booking/search${params ?? ""}`;

export const PaymentMCCards = (id?: string) =>
  `${BACKEND}api/storedMCPaymentCard${id ? "/" + id : ""}`;
export const TransferMoney = () => `${BACKEND}api/transfer`;
export const BusinessAccount = () => `${BACKEND}api/business/createcustomer`;
export const DDAUpload = () =>
  `${BACKEND}api/directDebitAuthorization/authorizeRequestDDR`;
export const ShortUrl = () => `${RUBISER}shorturi`;
export const AllowedOperators = () =>
  `https://utilities.pxou.com/api/allowedOperators`;
export const GetOffers = () => `${RUBISER}offers`;
export const GetMessages = (params: string) =>
  `${BACKEND}api/messages/?sort=createdAt&order=desc${params ?? ""}`; //?read=1
export const MarkMessagesRead = (id: string) => `${BACKEND}api/messages/${id}`;
export const ExistsCustomer = () => `${BACKEND}/resetPassExistsCustomer`;
export const EmailBankDetails = () => `${RUBISER}emailBankDetails`;
export const CheckExistsEmail = () => `${BACKEND}/checkExistsEmail`;
export const BankAccounts = (customerId?: string) =>
  `${BACKEND}api/customerBankAccount${customerId ? "/" + customerId : ""}`;

export const EcomerceEndpoint = () =>
  "https://ecom-dev.pxou.com/bkt/3DPayHosting/";
export const MasterCardEcomerceEndpoint = () =>
  BACKEND === DEV_BACKEND ? "" : "https://ecom.pago.al/dynamicFormCref.php/";
export const MerchantTransactions = () => `${BACKEND}api/merchantTransaction`;

export const MerchantTerminalCurrencies = () =>
  `${BACKEND}api/merchantTransaction/terminals`;

export const MerchantTransByDate = () =>
  `${BACKEND}api/merchantTransaction/searchByDates`;
export const MTDailyTotals = () =>
  `${BACKEND}api/merchantTransaction/mtDailyTotals`;
//
// CORE
//
export const OrderPagoCard = () => `${BACKEND}api/cardrequest`;
export const ResetPin = (personId: string) =>
  `${BACKEND}api/customerSettings/${personId}/resetPin`;
export const SearchTrasnfers = () => `${BACKEND}api/transfer/search`;

export const AccessRightsEndpoint = (personId: string, accountId: number) =>
  `${BACKEND}api/persons/${personId}/accounts/${accountId}/access`; // enum AccessRightType { FULL_RIGHTS, READ_RIGHTS, REPORTS }

//
// UTILITIES
//
export const GetTringBundles = () =>
  `${UTILITIES_BACKEND()}getPurchaseProductsTr`;
export const GetDigitalbBundles = () =>
  `${UTILITIES_BACKEND()}getPurchaseProductsDg`;

export const MobileTopUp = () => `${UTILITIES_BACKEND()}utility`;
export const GetInvoice = () => `${UTILITIES_BACKEND()}invoice`;
export const GetUKTInvoice = () => `${UTILITIES_BACKEND()}list`;
export const GetContractInvoice = () =>
  `${UTILITIES_BACKEND()}contract/invoice`;
export const PayInvoice = () => `${UTILITIES_BACKEND()}payInvoice`;
export const InvoicePayment = () => `${UTILITIES_BACKEND()}payment`;
export const UKTPaymentEndpoint = () => `${UTILITIES_BACKEND()}doPayment`;
export const GetProducts = () => `${UTILITIES_BACKEND()}products`;
export const ActivateProduct = () => `${UTILITIES_BACKEND()}activateProduct`;
export const UtilityInvoices = (id: string) =>
  `${UTILITIES_BACKEND()}invoice/${id}`;
export const UnstableUtilityAvailability = () =>
  `${RUBISER}statusService/unstableOperators`;
export const SingleUtilityAvailability = (id: string) =>
  `${RUBISER}statusService/findByOperator/${id}`;
export const UtilityAvailability = () => `${RUBISER}statusService/`;
export const OperatorEndpoint = (id?: string) =>
  `${UTILITIES_BACKEND()}operatorPerdorues/${id ?? ""}`;
export const GetOneBundles = () => `${UTILITIES_BACKEND()}getAddonPlans`;

//
// RUBISER
//
export const SendRegisterSMSCode = () => {
  return `${RUBISER}verification/`;
};
export const VerifyRegisterSMSCode = () =>
  `${RUBISER}verification/service/verify/`;
export const SingleStatement = () => `${RUBISER}bookingStatement`;
export const ProductSearch = (searchType: string, query: string) =>
  `${RUBISER}product/?searchType=${searchType}&name=${query}`;
export const ProductSearchDetails = (id: string) => `${RUBISER}product/${id}`;
export const ProductEntities = () => `${RUBISER}entity`;
export const GetBookingCategories = () => `${RUBISER}category?max=1000`;
export const BookingCategories = () => `${RUBISER}bookingCategory`;
export const GetBankName = (iban: string) => `${RUBISER}iban/${iban}`;

export const PagoVersion = () => `${RUBISER}version`;
export const QuestionnaireEndpoint = (id: string) =>
  `${RUBISER}questionnaire/html?customer=${id}`;
export const StaticQRCodeEndpoint = (pos: string) =>
  `${RUBISER}paymentRequest/search/${pos}`;

export const GetTwoFactorAuthEndpoint = (id: string) =>
  `${RUBISER}verification/${id}`;

export const TwoFactorAuthEndpoint = () =>
  `${RUBISER}verification/service/verify`;

//
// CONTACTS
//
export const GetAccountsUrl = (personId: string | undefined) =>
  `${BACKEND}api/persons/${personId}/accounts`;
export const GetUserContacts = () =>
  BACKEND === PROD_BACKEND
    ? `https://contacts.pxou.com/contact/myContacts`
    : `https://c-dev.pxou.com/contact/myContacts`; //?max=20&offset=${pageIndex}`;
export const SearchUserContacts = (searchQuery: string) =>
  BACKEND == PROD_BACKEND
    ? `https://contacts.pxou.com/contact/search/${searchQuery}`
    : `https://c-dev.pxou.com/contact/search/${searchQuery}`;
export const UpdateUserContacts = () =>
  BACKEND == PROD_BACKEND
    ? `https://contacts.pxou.com/contact/upload`
    : `https://c-dev.pxou.com/contact/upload`;

export const GetAccountAccessUrl = (
  personId: string | undefined,
  accountId: string
) => `${BACKEND}api/persons/${personId}/accounts/${accountId}/access`;
