import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginScreen.css";
import logo from "../assets/Pago-Logo.png";
import langText from "../lang/i18n";
import { logActivity } from "../hooks/functions";
import { SendRegisterSMSCode } from "../constants/endpoints";
import { Base64 } from "js-base64";
import { useAppSelector } from "../hooks/reduxHooks";
import { logOutClearDB } from "../localDatabase/mainDB";

const service_username = "sms@rbcn.al";
const service_password = "11111111";

const ForgotPasswordScreen = () => {
  const [mobileNumber, setMobileNumber] = useState("+355");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMobileNumber(event.target.value);
  };

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  function sendCode() {
    try {
      // let phoneNumber = phone.startsWith("+355") ? phone.substring(1) : phone;
      logActivity(
        SendRegisterSMSCode(),
        "Sending code to mobileNumber:" + mobileNumber
      );
      fetch(SendRegisterSMSCode(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization:
            "Basic " + Base64.btoa(service_username + ":" + service_password),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number: mobileNumber,
        }),
      })
        .then((res) => {
          if (res.status !== 201 && res.status !== 200) {
            setErrorMessage(langText("error_sending_sms"));
          } else {
            navigate("/sms-verification", {
              state: { phone: mobileNumber, process: "resetPassword" },
            });
          }
        })
        .catch((e) => {
          let err = Error(e);
        });
    } catch (error) {
      setErrorMessage(langText("error"));
    }
  }

  return (
    <div className="login-screen">
      <div className="form-container">
        <img
          alt="logo"
          src={logo}
          style={{
            maxWidth: "40%",
            height: "auto",
            marginBottom: "50px",
          }}
        />
        <form>
          <div>
            <label htmlFor="mobileNumber">
              {langText("forgot_password_screen_instruction")}:
            </label>
            <input
              type="tel"
              id="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </div>
          <p id="error-message">{errorMessage}</p>
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              sendCode();
            }}
          >
            {langText("sl")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
