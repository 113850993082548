import { useEffect, useState } from "react";
import langText from "../lang/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { hexWithOpacity, logActivity } from "../hooks/functions";
import { ResetPasswordWithSMS } from "../constants/endpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import animationData from "../assets/782-check-mark-success.json";
import "./ResetPassword.css";

import {
  faCheckCircle,
  faCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "reactstrap";
import Lottie from "react-lottie";
import { useAppSelector } from "../hooks/reduxHooks";
import { logOutClearDB } from "../localDatabase/mainDB";

let regexHasUpperCase = /^(?=.*[A-Z])(?=\S+$).{1,}$/;
let regexHasLowerCase = /^(?=.*[a-z])(?=\S+$).{1,}$/;
let regexHasNumber = /^(?=.*[0-9])(?=\S+$).{1,}$/;
let regexHasSpecialChar = /^(?=.*[@#$%+=.,!])(?=\S+$).{1,}$/;

function ResetPasswordScreen() {
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputsError, setInputsError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  function checkPassword() {
    if (newPassword === "" || newPassword2 === "") {
      setInputsError(langText("change_pass_empty_fields_error"));
      return;
    }
    if (newPassword !== newPassword2) {
      setInputsError(langText("change_pass_pass_not_match"));
      return;
    }
    changePassword();
  }

  async function changePassword() {
    setLoading(true);
    fetch(ResetPasswordWithSMS(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobileNumber: location.state.phone,
        newPassword: newPassword,
        verification: location.state.verification,
        token: location.state.code,
      }),
    })
      .then((response) => {
        logActivity("Reset password " + response.status);

        setLoading(false);
        if (response.status === 200) {
          navigate("/login");
        } else {
          response.json().then(logActivity);
          setInputsError(langText("error"));
        }
      })
      .catch((e) => {
        let err = Error(e);
      });
  }

  return (
    <div className="reset-password-screen">
      <div className="form-container">
        <div
          className="display-label"
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          {langText("change_password")}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="display-label"
              style={{ minWidth: 200, marginBottom: 20 }}
            >
              {langText("new_password")}
            </div>
            <input
              className="input"
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="display-label"
              style={{ minWidth: 200, marginBottom: 20 }}
            >
              {langText("new_password_again")}
            </div>
            <input
              className="input"
              type="password"
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {newPassword.length >= 8 ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color:
                  newPassword.length >= 8
                    ? "#34C98E"
                    : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_8chars")}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {regexHasUpperCase.test(newPassword) ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color: regexHasUpperCase.test(newPassword)
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_capital_char")}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {regexHasLowerCase.test(newPassword) ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color: regexHasLowerCase.test(newPassword)
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_lowercase_char")}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {regexHasNumber.test(newPassword) ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color: regexHasNumber.test(newPassword)
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_number")}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {regexHasSpecialChar.test(newPassword) ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color: regexHasSpecialChar.test(newPassword)
                  ? "#34C98E"
                  : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_special_symbol")}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!!newPassword2 && newPassword !== newPassword2 ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                color={hexWithOpacity("#FF0000", 0.7)}
              />
            ) : !!newPassword2 && newPassword === newPassword2 ? (
              <FontAwesomeIcon icon={faCheckCircle} color={"#34C98E"} />
            ) : (
              <FontAwesomeIcon
                icon={faCircle}
                color={hexWithOpacity("#303030", 0.7)}
              />
            )}
            <div
              className="display-label"
              style={{
                color:
                  !!newPassword2 && newPassword !== newPassword2
                    ? hexWithOpacity("#FF0000", 0.7)
                    : !!newPassword2 && newPassword === newPassword2
                    ? "#34C98E"
                    : hexWithOpacity("#303030", 0.7),
                marginLeft: 10,
              }}
            >
              {langText("password_mismatch")}
            </div>
          </div>
        </div>

        <div
          style={{
            alignSelf: "center",
            marginBottom: 10,
            color: hexWithOpacity("#FF0000", 0.7),
          }}
        >
          {inputsError}
        </div>
        <div
          onClick={checkPassword}
          style={{
            display: "flex",
            minWidth: 200,
            flexDirection: "column",
            backgroundColor: "#546BEA",
            color: "#FFF",
            borderRadius: 10,
            alignItems: "center",
            padding: "20px 70px",
            alignSelf: "center",
          }}
        >
          <div className="display-label" style={{}}>
            {langText("change_password")}
          </div>
        </div>
        <Modal
          isVisible={success}
          setIsVisible={setSuccess}
          title={langText("change_password_success")}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={400}
                width={400}
              />

              <button
                style={{
                  marginTop: 10,
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#546BEA",
                  color: "white",
                  borderRadius: "10px",
                  fontSize: 18,
                  border: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  setSuccess(false);
                  setNewPassword("");
                  setNewPassword2("");

                  navigate("/");
                }}
                disabled={loading}
              >
                {langText("continue")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;
