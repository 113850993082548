import React, { useEffect, useState } from "react";
import {
  FaHome,
  FaExchangeAlt,
  FaMoneyBillAlt,
  FaCreditCard,
  FaSignOutAlt,
  FaBolt,
  FaCog,
  FaStore,
} from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation hooks for programmatic navigation and getting current location
import "./Sidebar.css"; // Import the custom CSS file for styling
import logo from "../assets/icons/Group 1813.svg";
import { logOutClearDB, setIsLoggedIn } from "../localDatabase/mainDB";
import langText from "../lang/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { GetHomeScreenData } from "../constants/endpoints";

const Sidebar = () => {
  const navigate = useNavigate(); // Access navigate function from useNavigate hook
  const location = useLocation(); // Access location object from useLocation hook
  const dispatch = useAppDispatch();
  const [isMerchant, setIsMerchant] = useState(false);

  const { UserToken, UserData, chosenAcc, allAccounts } = useAppSelector(
    (state) => state.user
  );

  const SidebarItem = ({ text, icon, route }: any) => {
    const isActive = location.pathname === route; // Check if current location matches the route prop
    const handleClick = () => {
      if (route === "/logout") {
        setIsLoggedIn(false);
        logOutClearDB();
        navigate("/login");
      } else {
        if (route === "/merchants") {
          navigate(route, { state: Date.now() });
        } else {
          navigate(route);
        }
      }
    };

    return (
      <div
        className={`sidebar-item ${isActive ? "active" : ""}`} // Add "active" class if isActive is true
        onClick={handleClick}
      >
        <div className="sidebar-icon">{icon}</div>
        <span className="text-sm">{text}</span>
      </div>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await fetch(GetHomeScreenData(UserData!.id.toString()), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
        "Web-Client": "Q29taW5nRnJvbVdlYg==",
      },
    });

    if (response.status == 401) {
      logOutClearDB();
      setIsLoggedIn(false);
      navigate("/login");
      return;
    }

    if (!response.ok) return;
    const data = await response.json();
    if (!data || !data.accounts) return;
    setIsMerchant(data.isMerchant);
  };

  return (
    <div
      className="sidebar-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <a href="/">
        <img
          src={logo}
          alt="Pago"
          style={{
            margin: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </a>
      <div className="sidebar-items">
        <SidebarItem text={langText("home")} icon={<FaHome />} route="/" />
        <SidebarItem
          text={langText("transfer")}
          icon={<FaExchangeAlt />}
          route="/transfer"
        />
        <SidebarItem
          text={langText("deposit")}
          icon={<FaMoneyBillAlt />}
          route="/deposit"
        />
        <SidebarItem
          text={langText("utilities")}
          icon={<FaBolt />}
          route="/utilities"
        />
        {isMerchant && (
          <SidebarItem
            text={langText("merchants")}
            icon={<FaStore />}
            route="/merchants"
          />
        )}
        <SidebarItem
          text={langText("pago_card")}
          icon={<FaCreditCard />}
          route="/pago-card"
        />
        <SidebarItem
          text={langText("settings")}
          icon={<FaCog />}
          route="/settings"
        />
        <SidebarItem
          text={langText("log_out")}
          icon={<FontAwesomeIcon icon={faRightFromBracket} />}
          route="/logout"
        />
      </div>
    </div>
  );
};

export default Sidebar;
