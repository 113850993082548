import { useSelector } from "react-redux";
import langText from "../lang/i18n";
import { useEffect, useState } from "react";
import { formatMoney } from "../functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../hooks/reduxHooks";
import { toast } from "react-toastify";

const BundleProductItem = ({
  key,
  product,
  balance,
  navigation,
  provider,
  operatorId,
  contract,
}: any) => {
  const { Theme, SelectedColor } = useAppSelector((state: any) => state.theme);
  const { UserData } = useAppSelector((state) => state.user);
  const [opened, setOpened] = useState(false);
  const enoughBalance = product.Name
    ? undefined
    : balance >=
      (product.discountPrice != 0
        ? parseFloat(product.discountPrice)
        : parseFloat(product.productFee));

  useEffect(() => {}, [opened]);

  function makeTransaction(topUp: any) {
    navigation.navigate("EnterPIN", getScreenParams(topUp));
  }

  function getScreenParams(topUp: any) {
    switch (operatorId) {
      case "1":
        let phone = contract.vlera;
        return {
          friend: {
            name: langText(provider.short_name),
            recipientCurrency: "ALL",
            phoneNo: phone.startsWith("+")
              ? phone.substring(1, phone.length)
              : phone,
            operatorId: operatorId,
            description: JSON.stringify({
              bundleName: product.productName,
            }),
            topUpFirst: topUp == true,
          },
          amount: parseFloat(
            product.discountPrice != 0 &&
              product.discountPrice != product.productFee
              ? product.discountPrice
              : product.productFee
          ),
          message: langText(provider.short_name) + " top up",
          goTo: "Home",
        };
      case "18":
        return {
          friend: {
            name: langText(provider.short_name),
            recipientCurrency: "ALL",
            phoneNo: UserData?.mobileNumber?.substring(
              1,
              UserData.mobileNumber?.length
            ),
            operatorId: operatorId,
            description: JSON.stringify({
              actSubscription: true,
              productId: product.ProductID,
              smartCard: contract.vlera,
            }),
          },
          amount: parseFloat(product.ProductFee),
          message: langText(provider.short_name) + " top up",
          goTo: "Home",
        };
      case "19":
        return {
          friend: {
            name: langText(provider.short_name),
            recipientCurrency: "ALL",
            phoneNo: contract.vlera,
            operatorId: operatorId,
            description: JSON.stringify({
              promCode: product.ProductID,
              bundleName: product.Name,
            }),
          },
          amount: parseFloat(product.ProductFee),
          message: langText(provider.short_name) + " top up",
          goTo: "Home",
        };
      case "42":
        return {
          friend: {
            name: langText(provider.short_name),
            recipientCurrency: "ALL",
            phoneNo: contract.vlera,
            operatorId: operatorId,
            description: JSON.stringify({
              promCode: product.ProductID,
              bundleName: product.Name,
            }),
          },
          amount: parseFloat(product.price),
          message: langText(provider.short_name) + " top up",
          goTo: "Home",
        };
      default:
        break;
    }
  }

  return (
    <div
      key={key}
      onClick={() => {
        setOpened(!opened);
      }}
    >
      <div
        style={{
          padding: 15,
          margin: 10,
          backgroundColor: Theme.containerColor,
          borderRadius: 10,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ flexDirection: "row" }}>
              <div style={{ fontFamily: "Roboto-Regular", fontSize: 18 }}>
                {product.Name ?? product?.productName?.trim()}
              </div>
            </div>
            <div style={{ flexDirection: "row", alignItems: "center" }}>
              <div
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 18,
                }}
              >
                {formatMoney(
                  product.ProductFee ?? product.productFee ?? product.price
                ) +
                  " " +
                  langText("ALL")}
              </div>
              {product.discountPrice != 0 &&
                product.discountPrice != product.productFee && (
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={"arrow-right"}
                      style={{ margin: 5 }}
                      color={Theme.textColor}
                    />
                    <div style={{ fontSize: 18 }}>
                      {product.discountPrice + " " + langText("ALL")}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <FontAwesomeIcon
            icon={opened ? "chevron-up" : "chevron-down"}
            color={SelectedColor}
            style={{ marginLeft: 5 }}
          />
        </div>
        <div>
          {((!!product.productDescription &&
            product.productDescription != product.productName) ||
            !!product.desc) && (
            <div
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 15,
              }}
            >
              {product.productDescription ?? product.desc}
            </div>
          )}
          {enoughBalance != undefined ? (
            <div>
              {!!product.productFee && (
                <div style={{ fontSize: 18 }}>
                  {langText("vodafone_balance") +
                    ": " +
                    balance +
                    " " +
                    langText("ALL")}
                </div>
              )}
              <div style={{ flexDirection: "row", marginTop: 10 }}>
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <button
                    onClick={() => {
                      if (!enoughBalance) {
                        toast.warn(langText("not_enough_balance"), {
                          delay: 3000,
                        });
                        return;
                      }
                      makeTransaction(false);
                    }}
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {langText("activate")}
                  </button>
                </div>
                <div style={{ width: 20 }} />

                <div style={{ flex: 1, height: "100%" }}>
                  <button
                    onClick={() => {
                      makeTransaction(true);
                    }}
                    style={{
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {langText("top_up_activate")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                if (!enoughBalance) {
                  toast.warn(langText("not_enough_balance"), { delay: 5000 });

                  return;
                }
                makeTransaction(false);
              }}
            >
              {langText("activate")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BundleProductItem;
