import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { MerchantTransaction } from "../redux/user-slice";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { formatMoney } from "../functions";
import { addDays, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { userActivity } from "../redux/general-slice";
import { FilterDateComponent } from "./FilterComponents";
import langText from "../lang/i18n";
import {
  MerchantTerminalCurrencies,
  MerchantTransactions,
} from "../constants/endpoints";
import { Card, Dropdown, Space, Statistic, Tabs, Typography } from "antd";
import { Button } from "reactstrap";
import LoadingSpinner from "./LoadingIndicator";
import { FaCoins } from "react-icons/fa";
import { NumberOutlined } from "@ant-design/icons";

export default function MerchantTransactionsDatatable(props: any) {
  const dispatch = useAppDispatch();
  const { UserToken } = useAppSelector((state) => state.user);
  const [filterDateStart, setFilterDateStart] = useState<Date>();
  const [filterDateEnd, setFilterDateEnd] = useState<Date>();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [bookings, setBookings] = useState<MerchantTransaction[]>([]);
  const [selectedTab, setSelectedTab] = useState(langText("pending"));
  const [loading, setLoading] = useState<boolean>(false);
  const [terminalCurrencies, setTerminalCurrencies] = useState();
  const [total, setTotal] = useState(0);
  const [numberOfTransactions, setNumberOfTransactions] = useState(0);
  const [selectedTerminal, setSelectedTerminal] = useState("");

  const filteredBookings = useMemo(() => {
    return bookings.filter((booking) => {
      const bookingDate = new Date(
        parseInt(booking.date.substring(0, 4)),
        parseInt(booking.date.substring(4, 6)) - 1,
        parseInt(booking.date.substring(6, 8)),
        parseInt(booking.time.substring(0, 2)),
        parseInt(booking.time.substring(2, 4)),
        parseInt(booking.time.substring(4, 6))
      );

      return (
        (!!filterDateStart
          ? new Date(bookingDate).getTime() >= filterDateStart.getTime()
          : true) &&
        (!!filterDateEnd
          ? new Date(bookingDate).getTime() <=
            addDays(filterDateEnd, 1).getTime()
          : true)
      );
    });
  }, [bookings, filterDateStart, filterDateEnd, selectedTab]);

  const csv = useMemo(
    () => convertArrayOfObjectsToCSV(filteredBookings),
    [filteredBookings]
  );

  const getTerminalCurrencies = async () => {
    const response = await fetch(MerchantTerminalCurrencies(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    if (!response.ok) return;
    const terminalCurrencies = await response.json();
    const terminalInfo: any = {};
    terminalCurrencies.map((element: any) => {
      terminalInfo[element.terminal as any] = element.currency;
    });
    setTerminalCurrencies(terminalInfo);

    if (!selectedTerminal || selectedTerminal === "") {
      setSelectedTerminal(terminalCurrencies[0].terminal);
    }
  };

  function convertArrayOfObjectsToCSV(array: MerchantTransaction[]) {
    let result: string;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let keys = [
      "Id",
      "Data",
      "Komisioni",
      "C-Referenca",
      "Tipi",
      "Terminali",
      "Shuma",
    ];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    return array.reduce((acc, booking) => {
      return (
        acc +
        (keys.reduce((acc, key) => {
          const formattedDate = format(
            new Date(
              parseInt(booking.date.substring(0, 4)),
              parseInt(booking.date.substring(4, 6)) - 1,
              parseInt(booking.date.substring(6, 8)),
              parseInt(booking.time.substring(0, 2)),
              parseInt(booking.time.substring(2, 4)),
              parseInt(booking.time.substring(4, 6))
            ),
            "dd/MM/yyyy HH:mm:ss"
          );
          if (acc !== "") acc += columnDelimiter;
          if (key === "Id") acc += booking.id.toString();
          else if (key === "Data") acc += formattedDate;
          else if (key === "Komisioni")
            acc += booking.commission ?? (0).toString().replace(/[#,]/g, "");
          else if (key === "C-Referenca")
            acc += booking.casysCardTransRef.toString().replace(/[#,]/g, "");
          else if (key === "Tipi") acc += booking.transAmountType;
          else if (key === "Terminali") acc += booking.terminal;
          else if (key === "Shuma")
            acc += formatMoney(booking.amount).toString().replace(/[,]/g, "");
          //@ts-ignore
          else acc += booking[key];
          return acc;
        }, "") +
          lineDelimiter)
      );
    }, result);
  }

  async function downloadCSV() {
    const link = document.createElement("a");
    const filename = "Statement.csv";
    link.setAttribute("href", completeCsv);
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }: { onExport: () => void }) => (
    <Button
      onClick={(e) => onExport()}
      style={{
        backgroundColor: "#546BEA",
        border: "none",
        color: "white",
        borderRadius: 8,
        height: "43px",
        padding: "10px 26px",
      }}
    >
      {langText("export") + " Excel"}
    </Button>
  );

  const completeCsv = useMemo(
    () =>
      encodeURI(
        !csv.match(/^data:text\/csv/i)
          ? `data:text/csv;charset=utf-8,${csv}`
          : csv
      ),
    [csv]
  );

  useEffect(() => {
    getTerminalCurrencies();
    setBookings([]);

    if (props.data) {
      setBookings(props.data.transactions);
    } else if (selectedTab === langText("pending")) {
      getBookings("pending");
    } else if (selectedTab === langText("already_booked")) {
      getBookings("alreadyBooked");
    } else if (selectedTab === langText("ready_for_booking")) {
      getBookings();
    }
  }, [selectedTab, selectedTerminal]);

  const getBookings = async (params?: any) => {
    setLoading(true);
    const merchantTransactionUrl = params
      ? MerchantTransactions() + "?status=" + params
      : MerchantTransactions();
    const response = await fetch(merchantTransactionUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    if (!response.ok) return;
    const transactionsList = await response.json();

    const transactionsPerTerminal = transactionsList.filter(
      (transaction: any) => {
        return transaction.terminal === selectedTerminal;
      }
    );
    setBookings(transactionsPerTerminal.reverse());
    setNumberOfTransactions(transactionsPerTerminal.length);
    if (transactionsPerTerminal.length !== 0) {
      setTotal(
        transactionsPerTerminal?.reduce((sum: any, current: any) => {
          return sum + current.amount;
        }, 0)
      );
    }
    setLoading(false);
  };

  const onChange = (key: string) => {
    setSelectedTab(key);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleClearDate = () => {
    if (!!filterDateStart || !!filterDateEnd) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterDateStart(undefined);
      setFilterDateEnd(undefined);
    }
  };

  const columns: {
    name: string;
    selector: (booking: MerchantTransaction) => string | JSX.Element;
    sortable?: boolean;
    width?: string;
    sortFunction?: (
      bookingA: MerchantTransaction,
      bookingB: MerchantTransaction
    ) => number;
  }[] = [
    {
      name: langText("Id"),
      selector: (booking) => <div>{booking.id}</div>,
      sortable: true,
      sortFunction: (bookingA, bookingB) => {
        const a = new Date(
          parseInt(bookingA.date.substring(0, 4)),
          parseInt(bookingA.date.substring(4, 6)) - 1,
          parseInt(bookingA.date.substring(6, 8)),
          parseInt(bookingA.time.substring(0, 2)),
          parseInt(bookingA.time.substring(2, 4)),
          parseInt(bookingA.time.substring(4, 6))
        );
        const b = new Date(
          parseInt(bookingB.date.substring(0, 4)),
          parseInt(bookingB.date.substring(4, 6)) - 1,
          parseInt(bookingB.date.substring(6, 8)),
          parseInt(bookingB.time.substring(0, 2)),
          parseInt(bookingB.time.substring(2, 4)),
          parseInt(bookingB.time.substring(4, 6))
        );
        if (a < b) return 1;
        if (b > a) return -1;
        return 0;
      },
    },
    {
      name: langText("date"),
      selector: (booking) => {
        return format(
          new Date(
            parseInt(booking.date.substring(0, 4)),
            parseInt(booking.date.substring(4, 6)) - 1,
            parseInt(booking.date.substring(6, 8)),
            parseInt(booking.time.substring(0, 2)),
            parseInt(booking.time.substring(2, 4)),
            parseInt(booking.time.substring(4, 6))
          ),
          "dd/MM/yyyy HH:mm:ss"
        );
      },
      sortable: true,
      width: "15em",
      sortFunction: (bookingA, bookingB) => {
        const a = new Date(
          parseInt(bookingA.date.substring(0, 4)),
          parseInt(bookingA.date.substring(4, 6)) - 1,
          parseInt(bookingA.date.substring(6, 8)),
          parseInt(bookingA.time.substring(0, 2)),
          parseInt(bookingA.time.substring(2, 4)),
          parseInt(bookingA.time.substring(4, 6))
        );
        const b = new Date(
          parseInt(bookingB.date.substring(0, 4)),
          parseInt(bookingB.date.substring(4, 6)) - 1,
          parseInt(bookingB.date.substring(6, 8)),
          parseInt(bookingB.time.substring(0, 2)),
          parseInt(bookingB.time.substring(2, 4)),
          parseInt(bookingB.time.substring(4, 6))
        );

        if (a > b && a.getTime() > b.getTime()) return 1;
        if (b > a && b.getTime() > a.getTime()) return -1;
        return 0;
      },
    },
    {
      name: langText("commission"),
      selector: (booking) => <div>{booking.commission}</div>,
      sortable: false,
    },
    {
      name: langText("casys_card_trans_ref"),
      selector: (booking) => {
        return <div>{booking.casysCardTransRef}</div>;
      },
      sortable: false,
    },
    {
      name: langText("trans_amount_type"),
      selector: (booking) => <div>{booking.transAmountType}</div>,
      sortable: true,
    },
    {
      name: langText("amount"),
      selector: (booking) => (
        <div style={{ fontWeight: "bold" }}>
          {formatMoney(booking.amount) +
            " " +
            (terminalCurrencies?.[booking.terminal] === "ALL"
              ? "Lekë"
              : "Euro")}
        </div>
      ),
      sortable: true,
      sortFunction: (bookingA, bookingB) => {
        const a = bookingA.amount;
        const b = bookingB.amount;

        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      },
    },
  ];

  return (
    <div
      style={{
        padding: 20,
        fontFamily: "Poppins",
      }}
    >
      <DataTable
        responsive={true}
        data={filteredBookings}
        //@ts-ignore
        columns={columns}
        defaultSortAsc={false}
        defaultSortFieldId={langText("Id")}
        onChangePage={() => dispatch(userActivity())}
        onChangeRowsPerPage={() => dispatch(userActivity())}
        pagination={true}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            {props.data ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    flexDirection: "column",
                    fontWeight: "bold",
                    alignItems: "start",
                  }}
                >
                  <div>{`${props.data.header.split(",")[0]}`}</div>
                  <div>{`${props.data.header.split(",")[1]}`}</div>
                </div>
                <Export onExport={() => downloadCSV()} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Tabs
                    onChange={onChange}
                    type="card"
                    items={[
                      langText("pending"),
                      langText("already_booked"),
                      langText("ready_for_booking"),
                    ].map((i) => {
                      return {
                        label: i.toString(),
                        key: i.toString(),
                        children: ``,
                      };
                    })}
                  />

                  <div style={{ width: 10 }} />
                  <FilterDateComponent
                    startDate={filterDateStart}
                    endDate={filterDateEnd}
                    setStartDate={(value) => setFilterDateStart(value)}
                    setEndDate={(value) => setFilterDateEnd(value)}
                    onClear={handleClearDate}
                  />
                  <div style={{ width: 10 }} />
                  <Export onExport={() => downloadCSV()} />
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: "1em 0em",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "2em",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div style={{ fontSize: 18 }}>
                      Filtroni sipas terminalit:
                    </div>
                    <select
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        padding: "10px 10px",
                        width: "18em",
                        background: "#ffffff",
                        boxShadow: "0px 4px 16px #546bea35",
                        borderRadius: "8px",
                        borderColor: "transparent",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 18,
                      }}
                      onChange={(e) => {
                        setTotal(0);
                        setNumberOfTransactions(0);
                        setSelectedTerminal(e.target.value);
                      }}
                    >
                      {terminalCurrencies &&
                        Object.keys(terminalCurrencies).map((terminal) => {
                          return (
                            <option key={terminal} value={terminal}>
                              {terminal}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {selectedTerminal && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      <Card
                        style={{
                          width: "100%",
                          margin: "0px 10px",
                          backgroundColor: "#546BEA11",
                        }}
                        bordered={true}
                      >
                        <Statistic
                          title="Totali"
                          value={formatMoney(total)}
                          precision={2}
                          valueStyle={{ color: "#3f8600" }}
                          prefix={<FaCoins />}
                          suffix={terminalCurrencies?.[selectedTerminal]}
                        />
                      </Card>
                      <Card
                        style={{
                          width: "100%",
                          margin: "0px 10px",
                          backgroundColor: "#546BEA11",
                        }}
                        bordered={true}
                      >
                        <Statistic
                          title="Numri i transaksioneve"
                          value={numberOfTransactions}
                          precision={0}
                          valueStyle={{ color: "#3f8600" }}
                          prefix={<NumberOutlined rev={undefined} />}
                        />
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        }
        persistTableHead
        paginationPerPage={10}
        noDataComponent={
          <div style={{ padding: "2em", fontFamily: "Poppins" }}>
            {loading ? <LoadingSpinner /> : langText("no_data_available")}
          </div>
        }
      />
    </div>
  );
}
