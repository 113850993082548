import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getDBAccounts,
  getDBChosenAcc,
  getUser,
  getUserToken,
  setDBAccounts,
  setDBChosenAcc,
  setDBUserToken,
  setUser,
} from "../localDatabase/mainDB";
import { logActivity } from "../hooks/functions";

export interface UserSliceType {
  UserData?: UserDataProps;
  UserToken?: string;
  PersonId: string;
  chosenAcc?: AccountProps;
  allAccounts: AccountProps[];
}

export interface UserDataProps {
  id: string;
  kycVerified: boolean;
  birthCity: string;
  firstName: string;
  passwordExpired: boolean;
  accountExpired: boolean;
  address: Address;
  personalNr: string;
  mobileNumber: string;
  username: string;
  termsConditionsSignedAt: string;
  name: string;
  accountLocked: boolean;
  nationality: string;
  birthDate: string;
  lastName: string;
  enabled: boolean;
  email: string;
  kycId?: number;
  fatcaRelevant: boolean;
}

export interface Address {
  id?: number;
  postalCode: string;
  line1: string;
  line2?: string;
  city: string;
  country: string;
  state: string;
}

export interface AccountProps {
  id: number;
  name?: string;
  accountHolder: {
    id: string;
    name: string;
    mobileNumber: string;
    kycVerified: boolean;
  };
  balance: {
    id: number;
    amount: { id: number; value: number };
    balanceType: string;
  };
  iban: string;
  accountType: "CHECKING_PERSONAL" | "CHECKING_BUSINESS" | "SAVINGS_PERSONAL";
  currency: "ALL" | "EUR";
  accountAccesss?: AccountAccess[];
}

export interface AccountAccess {
  id: number;
  customerMobile: string;
  accessRightType: "FULL_RIGHTS" | "READ_RIGHTS" | "REPORTS";
}

export type Booking = {
  id: number;
  amount: {
    value: number;
  };
  senderCustomer: {
    id: string;
    name: string;
    mobileNumber: string;
  };
  recipientCustomer: {
    id: string;
    name?: string;
    mobileNumber?: string;
  };
  description: string;
  senderName: string;
  recipientName: string;
  avatar: string;
  creationDate: string;
  balance?: number;
  creditDebitIndicator:
    | string
    | {
        name: string;
        enumType?: string;
      };
  transaction: {
    id?: number;
    amount?: number;
    transactionInformation?: string;
    bookingDateTime?: Date | string;
    creditDebitIndicator: {
      name: string;
      enumType?: string;
    };
  };
};

export type MerchantTransaction = {
  id: number;
  time: string;
  date: string;
  settlementDate: string;
  transType: string;
  dateValute: number;
  commission: number;
  casysCardTransRef: string;
  transAmountType: string;
  terminal: string;
  msafId: number;
  retrievalRefNo: string;
  differenceDateValue: number;
  amount: number;
  booked: boolean;
  currency: string;
};

const initialState: UserSliceType = {
  PersonId: getUser()?.id,
  UserData: getUser(),
  UserToken: getUserToken(),
  allAccounts: getDBAccounts() ?? [],
  chosenAcc: getDBChosenAcc(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserDataProps>) {
      state.UserData = action.payload;
      setUser(action.payload);
    },
    setUserToken(state, action: PayloadAction<string>) {
      state.UserToken = action.payload;
      setDBUserToken(action.payload);
    },
    setChosenAcc(state, action: PayloadAction<AccountProps>) {
      logActivity("ChosenAccount is set to ", action.payload);
      state.chosenAcc = action.payload;
      setDBChosenAcc(action.payload);
    },
    setPersonId(state, action: PayloadAction<string>) {
      state.PersonId = action.payload;
    },
    setAccounts(state, action: PayloadAction<AccountProps[]>) {
      state.allAccounts = action.payload;
      setDBAccounts(action.payload);
    },
  },
});

export const {
  setUserData,
  setUserToken,
  setPersonId,
  setChosenAcc,
  setAccounts,
} = userSlice.actions;
export default userSlice.reducer;
