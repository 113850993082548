import React, { useEffect, useMemo, useState } from "react";
import { initializeApp } from "firebase/app";
import { getToken, onMessage, getMessaging } from "firebase/messaging";
import {
  getNotificationIsRegistered,
  getUser,
  getUserToken,
  setNotificationsRegistered,
} from "../localDatabase/mainDB";
import {
  DeleteNotificationEndpoint,
  NotificationEndpoint,
} from "../constants/endpoints";

import { onBackgroundMessage } from "firebase/messaging/sw";

import { toast } from "react-toastify";
import { logActivity } from "./functions";

export default function useNotifications() {
  const [hasPermissions, setHasPermissions] = useState(
    getNotificationIsRegistered()
  );
  const [notificationToken, setNotificationToken] = useState("");
  const UserData = getUser();
  const UserToken = getUserToken();

  const firebaseConfig = {
    apiKey: "AIzaSyAbHchg4dvE_O-BGy6FfT4rq_axjEZBOdA",
    authDomain: "blink-42.firebaseapp.com",
    databaseURL: "https://blink-42.firebaseio.com",
    projectId: "blink-42",
    storageBucket: "blink-42.appspot.com",
    messagingSenderId: "162336889002",
    appId: "1:162336889002:web:6455a21bc59f4eb97398d3",
    measurementId: "G-EP8PV43WV2",
  } as const;

  useEffect(() => {
    setNotificationsRegistered(hasPermissions);
  }, [hasPermissions]);

  function askForPermissions() {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    Notification.requestPermission().then((permission) => {
      logActivity(permission);
      if (permission === "granted") {
        setHasPermissions(true);
        getToken(messaging).then((token) => {
          logActivity("token", token);
          setNotificationToken(token);
          saveToken(token);
        });
      }
    });
  }

  function revokePermissions() {
    //You can`t change Notification.permission via browser API but this simply disables sending notifications
    setHasPermissions(false);
    deleteToken(notificationToken);
  }

  async function saveToken(token: string) {
    fetch(NotificationEndpoint(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        customer: UserData.id,
        notificationId: token,
      }),
    }).then((response) => {
      logActivity("NotificationEndpoint", response.status, token);
      // addNotificationListener();
    });
  }

  async function deleteToken(token: string) {
    fetch(DeleteNotificationEndpoint(token), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    }).then((response) => {
      logActivity("NotificationEndpoint", response.status);
      // addNotificationListener();
    });
  }

  // function addNotificationListener() {
  //   onMessage(messaging, (payload) => {
  //     toast(
  //       <div>
  //         <h3>{payload.notification?.title}</h3>
  //         <p>{payload.notification?.body}</p>
  //       </div>
  //     );
  //   });
  // }

  return {
    hasPermissions,
    askForPermissions,
    revokePermissions,
  };
}
