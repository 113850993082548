import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Card = {
  account: { id: number };
  billingAddress: { id: number };
  cardHolderName: string;
  cardIssuer: string;
  cardType: string;
  createdAt: string;
  creditCardNumber: string;
  cvv?: string;
  expirationMonth: number;
  expirationYear: number;
  id: number;
  isCommercial: boolean;
  issuingBank: string;
  sentToCasys: number;
  status: number;
  atm_limit?: number;
  pos_limit?: number;
  ecomm_limit?: number;
  tot_limit?: number;
  redCarpetCustomer: boolean;
};

export interface CardReducerType {
  card?: Card;
}

const initialState: CardReducerType = {
  card: undefined,
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setCard(state, action: PayloadAction<Card>) {
      state.card = action.payload;
    },
  },
});

export const { setCard } = cardSlice.actions;
export default cardSlice.reducer;
