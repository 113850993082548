import { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import "./SettingsScreen.css";
import langText from "../../lang/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faUser,
  faLock,
  faLink,
  faHeadset,
  faFileInvoice,
  faSackDollar,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { logOutClearDB, setIsLoggedIn } from "../../localDatabase/mainDB";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";

import PersonalInfoView from "./PersonalInfo";
import AccessesAndRoles from "./AccessesAndRoles";
import PasswordView from "./PasswordView";
import CashOutView from "./CashOutView";
import INPView from "./INPView";
import SupportView from "./SupportView";
import PinView from "./PinView";
import { useAppSelector } from "../../hooks/reduxHooks";

export default function SettingsScreen() {
  const [active, setActive] = useState("personal_info");
  const navigate = useNavigate();
  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    if (active === "log_out") {
      setIsLoggedIn(false);
      logOutClearDB();

      navigate("/login");
    }
  }, [active]);

  function MenuItem({
    title,
    icon,
    disabled,
    href,
  }: {
    title: string;
    icon: any;
    disabled?: boolean;
    href?: string;
  }) {
    return (
      <a
        className={"menu-item " + (active === title ? "active" : "")}
        style={{ opacity: disabled ? 0.5 : 1 }}
        onClick={() => (disabled || !!href ? null : setActive(title))}
        href={href}
        target="_blank"
      >
        <div className="menu-item-icon">{icon}</div>
        <div className="menu-item-title">{langText(title)}</div>
      </a>
    );
  }

  async function selectLanguage(lang: "al" | "en") {
    localStorage.setItem("lang", lang);
    await i18n.changeLanguage(lang);
    // window.location.reload();
  }

  return (
    <div>
      <Sidebar />
      <div
        style={{
          marginLeft: "7rem",
          backgroundColor: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 60,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            {langText("settings")}
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#FFFFFF",
              marginLeft: "40px",
            }}
          ></p>
        </div>

        <div className="columns-container">
          <div className="column" style={{ maxWidth: 350 }}>
            <MenuItem
              title={"personal_info"}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <MenuItem
              title={"accesses_and_roles"}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <MenuItem
              title={"password"}
              icon={<FontAwesomeIcon icon={faLock} />}
            />
            <MenuItem
              title={"pin"}
              icon={<FontAwesomeIcon icon={faUserLock} />}
            />
            <MenuItem
              title={"withdraw_money"}
              icon={<FontAwesomeIcon icon={faSackDollar} />}
            />
            {/*<MenuItem*/}
            {/*  title={"language"}*/}
            {/*  icon={<FontAwesomeIcon icon={faGlobe} />}*/}
            {/*/>*/}
            <MenuItem title={"inp"} icon={<FontAwesomeIcon icon={faLink} />} />
            <MenuItem
              title={"support"}
              icon={<FontAwesomeIcon icon={faHeadset} />}
            />
            <MenuItem
              title={"terms"}
              icon={<FontAwesomeIcon icon={faFileInvoice} />}
              href={"https://pago.al/termat-dhe-kushtet/"}
            />

            <MenuItem
              title={"log_out"}
              icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            />
          </div>
          <div className="column">
            {active === "personal_info" ? (
              <PersonalInfoView />
            ) : active === "accesses_and_roles" ? (
              <AccessesAndRoles />
            ) : active === "support" ? (
              <SupportView />
            ) : active === "password" ? (
              <PasswordView />
            ) : active === "pin" ? (
              <PinView />
            ) : active === "withdraw_money" ? (
              <CashOutView />
            ) : active === "inp" ? (
              <INPView />
            ) : active === "language" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{}} onClick={() => selectLanguage("al")}>
                  {langText("albanian")}
                </div>
                <div style={{}} onClick={() => selectLanguage("en")}>
                  {langText("english")}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
