import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import {
  getAccountData,
  getDBChosenAcc,
  getUser,
  getUserToken,
} from "../localDatabase/mainDB";
import { GetBooking } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import DateText from "./DateText";
import langText from "../lang/i18n";
import calendarImage from "../assets/icons/calendar.svg";
import { logActivity } from "../hooks/functions";

function UtilityPaidBill({ item }: { item: any }) {
  const navigate = useNavigate();
  const UserData = getUser();
  const UserToken = getUserToken();
  const accountData = getDBChosenAcc();
  const openTransactionDetails = (bookingId: number) => {
    fetch(GetBooking(bookingId.toString()), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    }).then((res) => {
      logActivity(GetBooking(bookingId.toString()), res.status);
      if (!res.ok) return;
      res.json().then((res) => {
        navigate("/transaction-details", {
          state: {
            booking: res,
          },
        });
      });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          padding: "20px",
          borderRadius: "25px",
          backgroundColor: "#ffffff",
          border: "1px solid #546BEA",
          marginTop: 30,
        }}
      >
        <div
          key={item.data}
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid #EFEFEF",
            paddingBottom: "10px",
            paddingTop: "10px",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <NumericFormat
            value={item.amount}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => {
              return (
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "550",
                    fontSize: "36px",
                    lineHeight: "54px",
                    marginLeft: "20px",
                    marginTop: "20px",
                    color: "#546BEA",
                  }}
                >
                  {value + " " + langText(accountData?.currency)}
                </span>
              );
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flex: "1",
            }}
          >
            <div
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "15px",
                justifyContent: "center",
                alignItems: "center",
                margin: "5px",
                marginRight: "15px",
                boxShadow: `2px 2px 5px #546BEA`,
                backgroundColor: "#FFF",
              }}
            >
              <img
                src={item?.logo ?? "https://friends.on.al/offers/host-logo.png"}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "#858585",
                  marginLeft: "20px",
                  marginTop: "-15px",
                }}
              >
                {item.operator_name}
              </div>

              <span
                style={{
                  margin: "0px 20px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#858585",
                  }}
                >
                  <DateText date={item.timestamp} />
                </span>
              </span>
            </div>
            <button
              style={{
                backgroundColor: "#546BEA",
                padding: "12px 60px",
                color: "white",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "18px",
                boxShadow: "0px 2px 4px rgba(84, 107, 234, 0.4)",
                // marginTop: "-900px",
                flexDirection: "row",
                flex: "1",
                // margin: "20px",
                marginLeft: "150px",
              }}
            >
              {langText("download")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UtilityPaidBill;
