const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export const colors = {
  primaryBlueText: "#1c2d57",
  primaryBlack: "#111111",
  primaryWhite: "#FFFFFF",
  primaryGrayText: "#8f92a1",
  primaryGray: "#f6f7f8",
  primaryViolet: "#c883ff",

  secondaryBlueText: "#010f39",
  secondaryBlueText2: "#0e1b42",
  secondaryBlueText3: "#202046",
  secondaryBlue: "#546bea",
  secondaryBlue2: "#6d5ffe",
  secondaryGray: "#f4f4fa",
  secondaryBlack: "#030319",
  secondaryGrayText: "#b0b0b0",
};

export const userTheme = {
  Morocco: "#546BEA",
  Malibu: "#5BC6FF",
  Tokyo: "#AE62FF",
  London: "#7d7580",
  Miami: "#D93D04",
  Vienna: "#4D8C3B",
  Mexico: "#F2B035",
  "New York": "#99CC00",
  // Moon: "#404040",
};

export default {
  light: {
    themeName: "light",
    backgroundColor: "#FFFFFF",
    containerColor: "#FFFFFF",
    secondaryContainerColor: "#F8F8F8",
    darkerContainerColor: "#F0F0F0",
    textColor: "#303030",
    shadow: {
      shadowColor: userTheme.Morocco,
      shadowOffset: { width: 2, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 10,
      elevation: 5,
    },
  },
  dark: {
    themeName: "dark",
    backgroundColor: "#121212",
    containerColor: "#303030",
    secondaryContainerColor: "#303030",
    darkerContainerColor: "rgba(48,48,48,0.9)",
    textColor: "#FFFFFF",
    shadow: {
      shadowColor: userTheme.Morocco,
      shadowOffset: { width: 2, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 10,
      elevation: 5,
    },
  },
};
