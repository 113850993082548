export const UtilityOpratorsAPIKEY = "665f7fd3-9582-4d31-9901-adf6496bea4d";
export const DocumentAPIKEY = "PVqQMg4lATsfVOi0RzYcmgnsOfjFq+4utIkhX/Kyu3A=";
export const EmailExistsAPIKEY = "QUxFQSBJQUNUQSBFU1Q=";
export const SingleStatementAPIKEY = "wf3NzLwFAs650uO-M2Hqujbqq14";
export const RubiserAPIKEY = "7a9544c8-1db4-4bce-8ea6-b5d59c5d2a66";
export const StaticQRCodeAPIKEY = "78d92d61-b524-4f0b-9f5c-a8a1b58843bb";

export const ProductSearchTypes = {
  BY_COMPANY: "byCompany",
  BY_COMPANY_ID: "byCompanyId",
  BY_CATEGORY_NAME: "byCategoryName",
  BY_CATEGORY_ID: "byCategoryId",
  BY_PRODUCT_NAME: "byProductName",
};
