import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrivacyActive, setPrivacyActive } from "../localDatabase/mainDB";

export interface GeneralReducerType {
  privacyActive: boolean;
  activityCounter: number;
  contracts: Contract[];
  availability: AvailabilityProps;
}

export type AvailabilityProps = Map<
  number,
  {
    status: "STABLE" | "DOWN" | "UNSTABLE" | "PAYMENT_PROCESSING";
    url?: string;
  }
>;

export type Contract = {
  id?: number;
  vlera: string;
  customerId?: string;
  doNotify: 0 | 1;
  operatorId: number;
  lastInvoiceDate?: string;
  contractNo: null | string;
};

const initialState: GeneralReducerType = {
  privacyActive: getPrivacyActive(),
  activityCounter: 0,
  contracts: [],
  availability: new Map(),
};

const generalSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setStatePrivacy(state, action: PayloadAction<boolean>) {
      setPrivacyActive(action.payload);
      state.privacyActive = action.payload;
    },
    setContracts(state, action: PayloadAction<any[]>) {
      state.contracts = action.payload;
    },
    setAvailability(state, action: PayloadAction<AvailabilityProps>) {
      state.availability = action.payload;
    },
    processPayment(state, action: PayloadAction<string>) {
      const currentAvailability = state.availability.get(
        parseInt(action.payload)
      );
      state.availability.set(parseInt(action.payload), {
        status:
          currentAvailability?.status === "PAYMENT_PROCESSING"
            ? "STABLE"
            : "PAYMENT_PROCESSING",
      });

      setTimeout(() => {
        this.processPayment(state, action);
      }, 30000);
    },
    userActivity(state) {
      state.activityCounter = state.activityCounter + 1;
    },
  },
});

export const {
  setStatePrivacy,
  userActivity,
  processPayment,
  setAvailability,
  setContracts,
} = generalSlice.actions;
export default generalSlice.reducer;
