import React, { FC } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import langText from "../../lang/i18n";

type UtilityAvailabilityViewProps = {
  name?: string;
  status?: string;
  url?: string;
};

export type UtilityAvailabilityProps = UtilityAvailabilityViewProps & {
  operatorId: string;
};

const UtilityAvailabilityView: FC<UtilityAvailabilityViewProps> = ({
  name,
  status,
  url,
}) => {
  const { SelectedColor, Theme } = useAppSelector((state) => state.theme);

  const unstableText = langText("utility_unstable");
  const offlineText = langText("utility_offline");

  if (!status || status == "STABLE") return null;
  return (
    <div
      style={{
        backgroundColor: Theme.backgroundColor,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        {status == "DOWN" ? name + offlineText : unstableText}
        <div
          style={{ color: SelectedColor }}
          // onClick={() => {
          //   if (!url) return;
          //   Linking.canOpenURL(url).then((supported) => {
          //     if (supported) {
          //       Linking.openURL(url);
          //     }
          //   });
          // }}
        >
          {url ? " " + langText("read_more") : ""}
        </div>
      </div>
    </div>
  );
};

export default UtilityAvailabilityView;
