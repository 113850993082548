import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const en = require("./en");
const sq = require("./sq");
const resources = {
  en,
  sq,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") ?? "sq",
  fallbackLng: "en",
});

export default function langText(text) {
  return i18n.t(text);
}
