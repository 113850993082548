import "./MerchantsScreen.css";
import { useEffect, useState } from "react";
import langText from "../../lang/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useAppSelector } from "../../hooks/reduxHooks";
import { logOutClearDB } from "../../localDatabase/mainDB";
import MerchantTransactionsDatatable from "../../components/MerchantTransactionsDatatable";
import { FaCalendar, FaTable } from "react-icons/fa";
import MerchantTransactionsCalendar from "../../components/MerchantTransactionsCalendar";

const DISPLAY_MODES = {
  CUSTOM: "custom-timeline-table",
  TABLE: "table",
  CALENDAR: "calendar",
};

export default function MerchantsScreen() {
  const [displayMode, setDisplayMode] = useState("calendar");
  const [tableData, setTableData] = useState("calendar");
  const { state } = useLocation();

  const navigate = useNavigate();

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    setDisplayMode(DISPLAY_MODES.CALENDAR);
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, [state]);

  const setTransactions = (data: any) => {
    setTableData(data);
    setDisplayMode(DISPLAY_MODES.CUSTOM);
  };

  return (
    <div>
      <Sidebar />
      <div
        style={{
          height: "100vh",
          marginLeft: "7rem",
          backgroundColor: "#F5F5F5",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 50,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            {langText("merchants")}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
              flexDirection: "column",
              borderRadius: "25px",
              border: "1px #cccccc",
              margin: "20px",
              marginTop: "-30px",
              backgroundColor: "#FFF",
              padding: "35px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: 20,
                paddingLeft: 50,
                paddingRight: 50,
                borderBottom: "1px solid #EDEDED",
              }}
            >
              <h2 className={"transactions-title"}>
                {langText("transactions")}
              </h2>
              <div className={"display-mode"}>
                <FaTable
                  style={{
                    marginLeft: "20px",
                    color:
                      displayMode === DISPLAY_MODES.TABLE
                        ? "#546BEA"
                        : "#414141",
                  }}
                  onClick={() => {
                    setDisplayMode(DISPLAY_MODES.TABLE);
                  }}
                />
                <FaCalendar
                  style={{
                    marginLeft: "20px",
                    color:
                      displayMode === DISPLAY_MODES.CALENDAR ||
                      displayMode === DISPLAY_MODES.CUSTOM
                        ? "#546BEA"
                        : "#414141",
                  }}
                  onClick={() => {
                    setDisplayMode(DISPLAY_MODES.CALENDAR);
                  }}
                />
              </div>
            </div>
            {displayMode === DISPLAY_MODES.TABLE && (
              <MerchantTransactionsDatatable />
            )}
            {displayMode === DISPLAY_MODES.CALENDAR && (
              <MerchantTransactionsCalendar setTransactions={setTransactions} />
            )}
            {displayMode === DISPLAY_MODES.CUSTOM && (
              <MerchantTransactionsDatatable data={tableData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
